/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'

import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FavoriteBorder from '@mui/icons-material/FavoriteBorder'
import Favorite from '@mui/icons-material/Favorite'
import Typography from '@mui/material/Typography'

import { addEleToInterests, removeEleFromInterests } from '../../../services/redux/user-slice'
import { BulletPointDoc, PopupDialog } from '../../components'
import { COLORS } from '../../../assets'

const Container = styled.div``
const FavoriteContainer = styled.div`
  margin-top: 1vw;
`
const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

function SearchDetails({ details }) {
  const { currentUser } = useSelector((state) => state.userAuth)
  const [isUpdating, setIsUpdating] = useState(false)
  // const [isLiked, setIsLiked] = useState(false)
  const [detailUserId, setDetailUserId] = useState(null)

  const [showDialog, setShowDialog] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('Information')
  const [dialogMessage, setDialogMessage] = useState('')
  const dispatch = useDispatch()

  const getMakerUserId = () => {
    if (!details && details.length > 0) return null
    const item = details[details.length - 1]
    return item?.split('#')[1]
  }

  useEffect(() => {
    // dispatch(resetInterests())
    const makerUserId = getMakerUserId()
    if (!makerUserId) return
    setDetailUserId(makerUserId)
  }, [])

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const removeEleFromArray = (array, element) => {
    const index = array.indexOf(element)
    if (index < 0) return
    array.splice(index, 1)
  }

  const addEleToArray = (array, element) => {
    array.push(element)
  }

  const updateUserLike = async () => {
    if (isUpdating) return

    try {
      // throw new Error(400, 'test')
      // update user like data in db (check is add or remove)
      // update user like data in redux
      // set isUpdating to false
      setIsUpdating(true)

      const idsArray = [...currentUser.interests]
      const isIdExist = idsArray.includes(detailUserId)

      if (isIdExist) {
        removeEleFromArray(idsArray, detailUserId)
      } else {
        addEleToArray(idsArray, detailUserId)
      }

      const resCreateInterest = await axios.put('/api/interest/create', {
        interestor: currentUser._id,
        interestee: detailUserId,
      })

      let resCreateUser
      if (resCreateInterest?.status === 200) {
        resCreateUser = await axios.put(`/api/users/${currentUser._id}`, {
          interests: [...idsArray],
        })
      }

      if (resCreateUser?.status === 200) {
        if (isIdExist) {
          dispatch(removeEleFromInterests(detailUserId))
        } else {
          dispatch(addEleToInterests(detailUserId))
        }
      }

      setIsUpdating(false)
    } catch (error) {
      setDialogTitle('Error')
      setDialogMessage('Server Error. Please try again later.')
      setShowDialog(true)
    }
  }

  const handleLikeChange = async () => {
    updateUserLike()
  }

  const isProfileLiked = () => {
    const makerUserId = getMakerUserId()
    if (!makerUserId) return false

    return currentUser?.interests?.includes(makerUserId)
  }

  return (
    <Container>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {details?.map((detail) => {
          if (detail && detail?.includes('userId')) {
            return
          }

          return <BulletPointDoc text={detail} />
        })}
        {currentUser ? (
          <FavoriteContainer>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                  sx={{
                    '&.Mui-checked': {
                      color: COLORS.textHighlightRed,
                    },
                  }}
                  defaultChecked={isProfileLiked()}
                  onChange={handleLikeChange}
                />
              }
              label='I would like to contact the entity/individual for further information
            (Your &#39;liking&#39; will be seen without revealing your identify & personal information)'
            />
          </FavoriteContainer>
        ) : (
          <></>
        )}
        {/* <FavoriteContainer>
          <FormControlLabel
            control={
              <Checkbox
                icon={<FavoriteBorder />}
                checkedIcon={<Favorite />}
                sx={{
                  '&.Mui-checked': {
                    color: COLORS.textHighlightRed,
                  },
                }}
                defaultChecked={isProfileLiked()}
                onChange={handleLikeChange}
              />
            }
            label='I would like to contact the entity/individual for further information
            (Your &#39;liking&#39; will be seen without revealing your
              identify & personal information)'
          />
        </FavoriteContainer> */}
        <PopupDialog
          dialogTitle={dialogTitle}
          handleClose={handleDialogClose}
          isDialogOpen={showDialog}
          actionsName='Close'
          maxWidth='45vw'
        >
          <Typography>{dialogMessage}</Typography>
          {/* <Typography>Test</Typography> */}
        </PopupDialog>
      </Box>
    </Container>
  )
}

export default SearchDetails
