/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate, Link } from 'react-router-dom'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import { deepOrange } from '@mui/material/colors'

import GroupsIcon from '@mui/icons-material/Groups'
import BookmarksIcon from '@mui/icons-material/Bookmarks'
import EditNoteIcon from '@mui/icons-material/EditNote'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'

import { MeetingCalendarActionList as ActionList, MeetingCalendarLayout } from '../my_meeting'

const Divisor = styled.hr`
  color: #757575;
  width: 100%;
`

const StyledButton = styled.a`
  padding: 5px 16px;
  border-radius: 5px;
  border-style: solid;
  border-color: #378fe8;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  /* background-color: #fff; */
  /* background-color: isHovering?#FCC63D : #848484  ; */
  color: #378fe8;
  text-decoration: none;
  min-width: 12rem;
  text-align: center;
`

function ProfileCard() {
  const [isEditProfileHovering, setIsEditProfileHovering] = useState(false)
  const [isViewProfileHovering, setIsViewProfileHovering] = useState(false)
  const [isMyMeetingHovering, setIsMyMeetingHovering] = useState(false)
  const [isMyMakerHovering, setIsMyMakerHovering] = useState(false)
  const [isMyFavoriteHovering, setIsMyFavoriteHovering] = useState(false)
  const [isNeedListHovering, setIsNeedListHovering] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [placement, setPlacement] = useState()
  const navigate = useNavigate()

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget)
    setOpen((prev) => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
  }

  const handleEditProfileEnter = () => {
    setIsEditProfileHovering(true)
  }

  const handleEditProfileLeave = () => {
    setIsEditProfileHovering(false)
  }

  const handleViewProfileEnter = () => {
    setIsViewProfileHovering(true)
  }

  const handleViewProfileLeave = () => {
    setIsViewProfileHovering(false)
  }

  const handleMyMeetingEnter = () => {
    setIsMyMeetingHovering(true)
  }

  const handleMyMeetingLeave = () => {
    setIsMyMeetingHovering(false)
  }

  const handleMyMakerEnter = () => {
    setIsMyMakerHovering(true)
  }

  const handleMyMakerLeave = () => {
    setIsMyMakerHovering(false)
  }

  const handleMyFavoriteEnter = () => {
    setIsMyFavoriteHovering(true)
  }

  const handleMyFavoriteLeave = () => {
    setIsMyFavoriteHovering(false)
  }

  const handleNeedListEnter = () => {
    setIsNeedListHovering(true)
  }

  const handleNeedListLeave = () => {
    setIsNeedListHovering(false)
  }

  const handleMyMeetingClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget)
    setOpen((prev) => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
  }

  const handleUserProfileClick = () => {
    navigate('/personal-info')
  }

  const handleMakerProfileClick = () => {
    navigate('/maker-profile')
  }

  return (
    // width: { sm: 320, md: 400 }
    // sm is for ipad mini
    <Card sx={{ width: { xs: 300, sm: 280, md: 400 } }}>
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                background: 'linear-gradient(45deg, #68a5dd 0%, #c3db3d 100%)',
                // color: '#fff',
                // backgroundColor: '"#37474f !important"',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ okButtonLabel: 'OK' }}>
                <StaticDatePicker
                  orientation='landscape'
                  openTo='day'
                  autoFocus='true'
                  onAccept={() => setOpen(false)}
                  slots={{
                    layout: MeetingCalendarLayout,
                    actionBar: ActionList,
                  }}
                />
              </LocalizationProvider>
            </Paper>
          </Fade>
        )}
      </Popper>
      <CardContent>
        <Grid
          sx={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ bgcolor: deepOrange[500], marginTop: '1.5rem', marginBottom: '1.2rem' }}>
            MA
          </Avatar>
          <Typography
            sx={{ fontSize: 20, color: 'black', fontWeight: 'bold' }}
            component='h2'
            gutterBottom
          >
            MAKERLinked Team
          </Typography>
          <Typography variant='h5' component='div' />
          <Typography sx={{ mb: 1, color: 'grey' }}>TOMAKERS Team</Typography>
          <Typography sx={{ mb: 1, color: 'grey' }}>Brussels, BE/Shanghai, CHN</Typography>
          <StyledButton
            style={{
              backgroundColor: isEditProfileHovering ? '#378fe8' : '#fff',
              color: isEditProfileHovering ? '#fff' : '#378fe8',
            }}
            onMouseEnter={handleEditProfileEnter}
            onMouseLeave={handleEditProfileLeave}
            onClick={handleUserProfileClick}
          >
            My Personal Info
          </StyledButton>
          <StyledButton
            style={{
              backgroundColor: isViewProfileHovering ? '#378fe8' : '#fff',
              color: isViewProfileHovering ? '#fff' : '#378fe8',
            }}
            onMouseEnter={handleViewProfileEnter}
            onMouseLeave={handleViewProfileLeave}
            onClick={handleMakerProfileClick}
          >
            My Maker Profile
          </StyledButton>
          <Divisor />
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '0.6rem',
          }}
        >
          <Typography
            sx={{
              fontSize: 15,
              color: '#378fe8',
              fontWeight: 'bold',
              marginBottom: '0.8rem',
            }}
            component='h2'
            gutterBottom
          >
            Overview
          </Typography>
          <StyledButton
            style={{
              backgroundColor: isMyMeetingHovering ? '#378fe8' : '#fff',
              color: isMyMeetingHovering ? '#fff' : '#378fe8',
              width: '10rem',
              minWidth: '10.2rem',
              display: 'flex',
              flexDirection: 'row',
            }}
            onMouseEnter={handleMyMeetingEnter}
            onMouseLeave={handleMyMeetingLeave}
            onClick={handleMyMeetingClick('right-end')}
          >
            <GroupsIcon fontSize='small' sx={{ marginRight: '0.8rem' }} />
            My meetings
          </StyledButton>
          <StyledButton
            href='makerlinked-acct/home'
            style={{
              backgroundColor: isMyMakerHovering ? '#378fe8' : '#fff',
              color: isMyMakerHovering ? '#fff' : '#378fe8',
              width: '10.2rem',
              minWidth: '3rem',
              display: 'flex',
              flexDirection: 'row',
            }}
            onMouseEnter={handleMyMakerEnter}
            onMouseLeave={handleMyMakerLeave}
          >
            <GroupsIcon fontSize='small' sx={{ marginRight: '0.8rem' }} />
            My MAKERLinked
          </StyledButton>
          <StyledButton
            style={{
              backgroundColor: isMyFavoriteHovering ? '#378fe8' : '#fff',
              color: isMyFavoriteHovering ? '#fff' : '#378fe8',
              width: '10.2rem',
              minWidth: '3rem',
              display: 'flex',
              flexDirection: 'row',
            }}
            onMouseEnter={handleMyFavoriteEnter}
            onMouseLeave={handleMyFavoriteLeave}
          >
            <BookmarksIcon fontSize='small' sx={{ marginRight: '0.8rem' }} />
            My favorite
          </StyledButton>
          <StyledButton
            style={{
              backgroundColor: isNeedListHovering ? '#378fe8' : '#fff',
              color: isNeedListHovering ? '#fff' : '#378fe8',
              width: '10.2rem',
              minWidth: '3rem',
              display: 'flex',
              flexDirection: 'row',
            }}
            onMouseEnter={handleNeedListEnter}
            onMouseLeave={handleNeedListLeave}
          >
            <EditNoteIcon fontSize='small' sx={{ marginRight: '0.8rem' }} />
            What I need
          </StyledButton>
          {/* <Typography
            sx={{ fontSize: 15, color: '#378fe8', marginBottom: '-0.5rem' }}
            component='h2'
            gutterBottom
          >
            <Link to='/' style={{ fontSize: 15 }}>
              Logout
            </Link>
          </Typography> */}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ProfileCard
