/* eslint-disable jsx-quotes */
import React from 'react'
import styled from 'styled-components'

import { Button, LogoBox } from '../features/components'

const Container = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* height: calc(100vh - 5vh); */
  /* color: #fff; */
  font-family: var(--font-family);
  transform: translate(-50%, -50%);
  top: 35%;

  // for iphone SE
  @media only screen and (min-width: 320px) {
    left: 50%;
  }

  // for iphone 12 PEO > 390
  @media only screen and (min-width: 390px) {
    left: 50%;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    left: 50%;
  }

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    left: 50%;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    left: 50%;
  }

  @media only screen and (min-width: 1024px) {
    left: 50%;
  }
`

const CharactersDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10vh;

  @media only screen and (min-width: 320px) {
    flex-direction: column;
    margin-left: 12%;
  }

  @media only screen and (min-width: 728px) {
    flex-direction: row;
    margin-left: -6%;
  }
`

const StyledButton = styled(Button)`
  height: 3.5vh;
  font-size: 1.2rem;
  justify-content: center;

  // for iphone SE
  @media only screen and (min-width: 320px) {
    width: 35svw;
  }

  // for iphone 12 PEO > 390
  /* @media only screen and (min-width: 390px) {
    width: 29vw;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    width: 29vw;
  } */

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    width: 18vw;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    width: 15vw;
  }

  @media only screen and (min-width: 1024px) {
    width: 13vw;
  }
`

function Home() {
  return (
    <Container>
      <LogoBox path='/' />
      <CharactersDiv>
        <div style={{ marginRight: '10vw' }}>
          <StyledButton
            enterColor='#FE481F'
            leaveColor='#F7750A'
            text='Am a maker'
            path='am-maker/search'
          />
        </div>
        <StyledButton
          enterColor='#FE481F'
          leaveColor='#F7750A'
          text='Need a maker'
          path='need-maker/search'
        />
      </CharactersDiv>
    </Container>
  )
}

export default Home
