/* eslint-disable import/prefer-default-export */
// domain/(need-maker/am-maker)/... the index is at 1
const CONSTANTS = {
  MAKER_TYPE_URL_INDEX: 1,

  ONE_TRILLION: 1e12,
  ONE_BILLION: 1e9,
  ONE_MILLION: 1e6,
  ONE_KILO: 1_000,
}

export { CONSTANTS }
