/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'
import CircleIcon from '@mui/icons-material/Circle'

import { BulletPointDoc } from '../../components'
import { COLORS } from '../../../assets'

const Container = styled.div``

function DocumentChecklist() {
  return (
    <Container>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant='h6' gutterBottom>
          Intent to list application documents include:
        </Typography>
        <BulletPointDoc
          text='Application for Release of Technology Supply/Demand (required)'
          color={COLORS.textHighlightRed}
        />
        <BulletPointDoc
          text='Proof of Applican&#x27;s Legal Qualification (required)'
          color={COLORS.textHighlightRed}
        />
        <BulletPointDoc
          text='Entrustment Contract of Transaction Service(required)'
          color={COLORS.textHighlightRed}
        />
        <BulletPointDoc
          text='Proof of ownership of the subject matter or other supporting materials sufficient to prove ownership (required)'
          color={COLORS.textHighlightRed}
        />
        <BulletPointDoc
          text='Decision-making documents related to technology transaction behaviour (required)'
          color={COLORS.textHighlightRed}
        />
        <BulletPointDoc text='If the subject matter involves evaluation or filing, submission of evaluation report and filing conditions (optional)' />
        <BulletPointDoc text='Other materials to be provided (optional)' />
        <Typography variant='h6' gutterBottom sx={{ pt: '0.6rem', pb: '0.3rem' }}>
          Requirements of Intent to List Application Document:
        </Typography>
        <BulletPointDoc
          text='The above materials shall be submitted as originals if there are originals, or copies if there are no
        originals or the originals cannot be submitted, and the copies shall be stamped with the signature of the provider.'
        />
        <BulletPointDoc
          text='If foreigners or foreign enterprises are engaged in technology transactions, the submission of Chinese
        translations of foreign documents can be attached as an annex'
        />
        <Typography variant='h6' gutterBottom sx={{ pt: '0.6rem', pb: '0.3rem' }}>
          Basic requirements:
        </Typography>
        <BulletPointDoc
          text='Scientific and technological achievements include, but are not limited to, patents, copyrights, trademarks,
          new plant varieties, new varieties of biomedicine, exclusive rights to layout designs of integrated circuits and technical secrets;'
        />
        <BulletPointDoc
          text='The ownership of scientific and technological achievements should be clear, and the trading activities should follow the principles
           of equal price and compensation and openness, fairness and justice, and shall not infringe upon the legitimate rights and interests of others
           or harm the public interests of the society;'
        />
        <BulletPointDoc
          text='The transferor shall submit application materials for information dissemination to the technology transaction service organisation
          and shall be responsible for the authenticity, legality, completeness and accuracy of the application materials.'
        />
      </Box>
    </Container>
  )
}

export default DocumentChecklist
