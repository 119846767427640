/* eslint-disable max-len */
/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { MyMeetingsImage } from '../../static/images'
import { COLORS } from '../../assets'

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center; // vertically center
  justify-content: center; // horizontally center
  // to make it center, need to adjust the following two params
  /* min-width: 100vw;
  min-height: 100vh; */

  min-height: 100vh;
  position: absolute;
  flex-direction: column;

  // to adjust the horizontal pos to the mid
  @media only screen and (min-width: 768px) {
    padding-left: 28vw;
  }

  @media only screen and (min-width: 820px) {
    padding-left: 25vw;
  }

  @media only screen and (min-width: 1024px) {
    padding-top: 5vh;
    padding-left: 28vw;
  }
`

const OptionsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
`
const LeftSubContainer = styled.div`
  margin-right: 2rem;
`

const FavoriteDiv = styled.div``

const ProfileDiv = styled.div``

const InvestAssistDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const InvestTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const InvestAssistLDiv = styled.div`
  margin-right: 1rem;
`

const InvestAssistRDiv = styled.div``

const RightSubContainer = styled.div``

const OngoingProjectDiv = styled.div``

const MyMeetingsDiv = styled.div``

const StyledButton = styled.a`
  padding: 5px 16px;
  border-radius: 5px;
  border-style: solid;
  border-color: #378fe8;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  /* background-color: #fff; */
  /* background-color: isHovering?#FCC63D : #848484  ; */
  color: #378fe8;
  text-decoration: none;
  width: '12vw';
  text-align: center;
`

const StyledButtonGrey = styled.a`
  padding: 5px 16px;
  border-radius: 5px;
  border-style: solid;
  border-color: #e3e6e8;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  background-color: #e3e6e8;
  /* background-color: isHovering?#FCC63D : #848484  ; */
  color: #378fe8;
  text-decoration: none;
  width: '3vw';
  text-align: center;
`
function MkLinkedHome() {
  const location = useLocation()
  const navigate = useNavigate()

  // const handleGridClick = (e) => {
  //   // This is to get the value of the list, so to handle click accordingly
  //   // console.log(e.currentTarget.getAttribute('value'))
  //   const menuItemValue = e.currentTarget.innerText.split(' ')[0].split('\n')[0]
  //   switch (menuItemValue) {
  //     case 'Profile':
  //       navigate(`/${location.pathname.split('/')[1]}/profile`)
  //       break
  //     case 'Favorite':
  //       navigate('favorites', { state: { name: 'Favorites' } })
  //       break
  //     case 'Investment assistant':
  //       navigate('investment-assistant', { state: { name: 'Investment Assistant' } })
  //       break
  //     case 'My meetings':
  //       navigate('my-meetings', { state: { name: 'My Meetings' } })
  //       break
  //     case 'Ongoing projects':
  //       navigate('ongoing-projects', { state: { name: 'Ongoing Collaborative Projects' } })
  //       break
  //     case 'TOMAKERS service':
  //       navigate('tomakers-service', { state: { name: 'TOMAKERS Services' } })
  //       break
  //     default:
  //   }
  // }

  const handleFavClick = () => {
    navigate(`/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}/favorites`)
  }

  const handleProfileClick = () => {
    navigate(`/${location.pathname.split('/')[1]}/profile`)
  }

  const handleOngoingProjClick = () => {
    navigate(
      `/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}/ongoing-projects`,
    )
  }

  const handleMyMeetingsClick = () => {
    navigate(`/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}/my-meetings`)
  }

  const handleInvestAssistClick = () => {
    navigate(
      `/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}/investment-assistant`,
    )
  }

  return (
    <Container>
      <OptionsContainer>
        <LeftSubContainer>
          <FavoriteDiv>
            <Card
              sx={{
                width: '38vw',
                height: '16vh',
                marginBottom: '5%',
                '&:hover': { cursor: 'pointer' },
              }}
              onClick={() => handleFavClick()}
            >
              <CardContent>
                <Typography sx={{ color: COLORS.lightBlue, fontSize: '0.9rem' }}>
                  Favorites
                </Typography>
                <Typography
                  variant='body1'
                  sx={{
                    fontSize: '0.8rem',
                    // marginLeft: '-0.5rem',
                    // marginRight: '-0.5rem',
                  }}
                >
                  {location.pathname.split('/')[1] === 'need-maker'
                    ? 'Your favorites techniques from startups, indivisuals (designers and engineers) here at MAKERLinked'
                    : 'Your favorites investors, companies here at MAKERLinked'}
                </Typography>
              </CardContent>
            </Card>
          </FavoriteDiv>
          <ProfileDiv>
            <Card
              sx={{
                width: '38vw', // for ipad air
                height: '10vh',
                marginBottom: '5%',
                '&:hover': { cursor: 'pointer' },
              }}
              onClick={() => handleProfileClick()}
            >
              <CardContent>
                <Typography sx={{ color: COLORS.lightBlue, fontSize: '0.9rem' }}>
                  Profile
                </Typography>
                <Typography
                  variant='body1'
                  sx={{
                    fontSize: '0.8rem',
                    // marginLeft: '-0.5rem',
                    // marginRight: '-0.5rem',
                  }}
                >
                  Your profile at MAKERLinked
                </Typography>
              </CardContent>
            </Card>
          </ProfileDiv>
          <InvestTitleDiv>
            <StyledButton onClick={() => handleInvestAssistClick()}>
              Investment assistant
            </StyledButton>
            <StyledButtonGrey style={{ width: '5rem' }}>View all</StyledButtonGrey>
          </InvestTitleDiv>
          <InvestAssistDiv>
            <InvestAssistLDiv>
              <Card
                sx={{
                  width: '18vw', // for ipad air
                  height: '13vh',
                  marginBottom: '10%',
                  '&:hover': { cursor: 'pointer' },
                }}
              >
                <CardContent>
                  <Typography sx={{ color: COLORS.lightBlue, fontSize: '0.9rem' }}>
                    Chinese Innovation Map
                  </Typography>
                  <Typography
                    variant='body1'
                    sx={{
                      fontSize: '0.8rem',
                      // marginLeft: '-0.5rem',
                      // marginRight: '-0.5rem',
                    }}
                  >
                    Learn about China&apos;s Innovation market and become TOMAKERS-Certified with
                    role-based courses
                  </Typography>
                </CardContent>
              </Card>
              <Card
                sx={{
                  width: '18vw', // for ipad air
                  height: '13vh',
                  marginBottom: '10%',
                  '&:hover': { cursor: 'pointer' },
                }}
              >
                <CardContent>
                  <Typography sx={{ color: COLORS.lightBlue, fontSize: '0.9rem' }}>
                    Contract Assist(multi-country)
                  </Typography>
                  <Typography
                    variant='body1'
                    sx={{
                      fontSize: '0.8rem',
                      // marginLeft: '-0.5rem',
                      // marginRight: '-0.5rem',
                    }}
                  >
                    Reference step-by-step instructions for every actions in contract signature
                  </Typography>
                </CardContent>
              </Card>
            </InvestAssistLDiv>
            <InvestAssistRDiv>
              <Card
                sx={{
                  width: '18vw', // for ipad air
                  height: '13vh',
                  marginBottom: '10%',
                  '&:hover': { cursor: 'pointer' },
                }}
              >
                <CardContent>
                  <Typography sx={{ color: COLORS.lightBlue, fontSize: '0.9rem' }}>
                    Legal Consulting & Market Access
                  </Typography>
                  <Typography
                    variant='body1'
                    sx={{
                      fontSize: '0.8rem',
                      // marginLeft: '-0.5rem',
                      // marginRight: '-0.5rem',
                    }}
                  >
                    Dive Deeper into Chinese IPR policy with our advanced training.
                  </Typography>
                </CardContent>
              </Card>
              <Card
                sx={{
                  width: '18vw', // for ipad air
                  height: '13vh',
                  marginBottom: '10%',
                  '&:hover': { cursor: 'pointer' },
                }}
              >
                <CardContent>
                  <Typography sx={{ color: COLORS.lightBlue, fontSize: '0.9rem' }}>
                    Event
                  </Typography>
                  <Typography
                    variant='body1'
                    sx={{
                      fontSize: '0.8rem',
                      // marginLeft: '-0.5rem',
                      // marginRight: '-0.5rem',
                    }}
                  >
                    Proactively know webniars and events of innovation Europte/China/Asia
                  </Typography>
                </CardContent>
              </Card>
            </InvestAssistRDiv>
          </InvestAssistDiv>
        </LeftSubContainer>
        <RightSubContainer>
          <OngoingProjectDiv>
            <Card
              sx={{
                width: '20vw',
                height: '23vh',
                marginBottom: '10%',
                '&:hover': { cursor: 'pointer' },
              }}
              onClick={() => handleOngoingProjClick()}
            >
              <CardContent>
                <Typography sx={{ color: COLORS.lightBlue, fontSize: '0.9rem' }}>
                  Ongoing Collaborative Projects
                </Typography>
                <Typography
                  variant='body1'
                  sx={{
                    fontSize: '0.8rem',
                    // marginLeft: '-0.5rem',
                    // marginRight: '-0.5rem',
                  }}
                >
                  Your oning projects with others. You can check details include: projects status,
                  related meetings (completed and reserved), key stake holders information etc.
                </Typography>
              </CardContent>
            </Card>
          </OngoingProjectDiv>
          <MyMeetingsDiv>
            <Card
              sx={{
                width: '20vw',
                height: { sm: '36vh', md: '36vh', lg: '42vh' },
                marginBottom: '10%',
                '&:hover': { cursor: 'pointer' },
              }}
              onClick={() => handleMyMeetingsClick()}
            >
              <CardContent>
                <Box
                  component='img'
                  sx={{
                    marginTop: { sm: '-13%', md: '-7%' }, // need to change according to diff size
                    marginLeft: { sm: '-12%', md: '-7%' }, // this is to let the image fill the card in width and height
                    height: { sm: '15vh', md: '22vh' },
                    width: { sm: '20vw', md: '21vw' },
                  }}
                  alt='My meetings image'
                  src={MyMeetingsImage}
                />
                <Grid sx={{ color: COLORS.lightBlue }}>My Meetings</Grid>
                <Grid>tags</Grid>
                <Grid>tags</Grid>
              </CardContent>
            </Card>
          </MyMeetingsDiv>
        </RightSubContainer>
      </OptionsContainer>
    </Container>
  )
}

export default MkLinkedHome
