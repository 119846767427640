/* eslint-disable operator-linebreak */
/* eslint-disable no-lonely-if */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-quotes */
import React, { useState } from 'react'
import { ScrollView } from 'react-native'
import styled from 'styled-components'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import PasswordChecklist from 'react-password-checklist'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { isEmail } from 'validator'

import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

import { authFailure, authStart, authComplete } from '../services/redux/user-slice'
import { Button, PopupDialog, LogoBox } from '../features/components'
import { COLORS } from '../assets'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 10vh);
  color: #fff;
  font-family: var(--font-family);
  position: absolute;
  top: 50%;
  /* left: 50%; */
  transform: translate(-50%, -50%);

  // for iphone SE
  @media only screen and (min-width: 320px) {
    left: 50%;
  }

  // for iphone 12 PEO > 390
  @media only screen and (min-width: 390px) {
    left: 50%;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    left: 50%;
  }

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    left: 50%;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    left: 50%;
  }

  @media only screen and (min-width: 1024px) {
    left: 50%;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2xp solid;
  padding: 20px 50px;
  gap: 10px;
`

const Title = styled.h1`
  font-size: 1.5rem;
  text-align: center;
`

const Input = styled.input`
  width: 100%;
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  border: 1px solid;
  /* height: 3rem; */
`
const StyledButton = styled(Button)`
  width: 12rem;
  font-size: 1.2rem;
`

const More = styled.div`
  display: flex;
  margin-top: 10px;
  font-size: 0.8rem;
  align-items: center;
  margin-bottom: 2rem;
`

const Links = styled.div`
  margin-right: 20px;
  align-items: center;
`

const WarningSpan = styled.span`
  color: #ed7474;
  font-weight: bold;
`

const Label = styled.label`
  color: #fff;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 1.5em;
`

const LoginDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SignUpInforDiv = styled.div`
  align-content: flex-start;
`

const RedirContainer = styled.div`
  display: flex;
  flex-direction: row;

  // for iphone SE
  @media only screen and (min-width: 320px) {
    flex-direction: column;
  }

  // for iphone 12 PEO > 390
  @media only screen and (min-width: 390px) {
    flex-direction: column;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    flex-direction: column;
  }

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    flex-direction: column;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    flex-direction: column;
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

function SignUp() {
  const { currentUser, loading } = useSelector((state) => state.userAuth)
  const [signupInput, setSignupInput] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  })
  const [signupError, setSignupError] = useState({
    username: '',
    email: '',
  })
  const [showPassword, setShowPassword] = useState(false)
  const [isSignedUp, setIsSignedUp] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [isPwdValid, setIsPwdValid] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('Information')
  const [dialogMessage, setDialogMessage] = useState('')
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const [isRegisterHovering, setIsRegisterHovering] = useState(false)
  // const navigate = useNavigate()

  // const handleRegisterMouseEnter = () => {
  //   setIsRegisterHovering(true)
  // }

  // const handleRegisterMouseLeave = () => {
  //   setIsRegisterHovering(false)
  // }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const checkDuplicate = async (name, value) => {
    if (name !== 'username' && name !== 'email') return false
    try {
      const res = await axios.post('/api/auth/hasDup', { [name]: value })
      return res?.data?.hasDup
    } catch (error) {
      setDialogTitle('Error')
      setDialogMessage('Server is busy. Please try again later.')
      setShowDialog(true)
      dispatch(authFailure())
    }

    return false
  }

  const validateSignUpInput = async (e) => {
    const { name, value } = e.target
    const hasDup =
      (name === 'username' || name === 'email') && value !== ''
        ? await checkDuplicate(name, value)
        : false

    setSignupError((prev) => {
      const statObj = { ...prev, [name]: '' }

      switch (name) {
        case 'username':
          if (value.length > 18) {
            statObj[name] = 'The username should have no more than 18 characters'
          } else if (hasDup) {
            statObj[name] = 'The username has already been used by other users'
          }
          break
        case 'email':
          if (!value) {
            statObj[name] = 'Please input your email address'
          } else if (hasDup) {
            statObj[name] = 'The email address has already been registered'
          } else if (!isEmail(value)) {
            statObj[name] = 'Please input an valid email address'
          }
          break
        default:
          break
      }
      return statObj
    })
  }

  const onSignupChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target

    // reset signup error
    setSignupError((prev) => {
      const statObj = { ...prev, [name]: '' }
      return statObj
    })

    setSignupInput((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const validatePassword = () => {
    if (signupError.username !== '' || signupError.email !== '' || isPwdValid === false) {
      setDialogTitle('Error')
      setDialogMessage('Your registration information is not valid, please check again')
      setShowDialog(true)
      return false
    }

    return true
  }

  const handleSignUpClick = async (e) => {
    e.preventDefault()
    if (
      !signupInput.email ||
      !signupInput.password ||
      !signupInput.confirmPassword ||
      !validatePassword()
    ) {
      return
    }

    dispatch(authStart())
    try {
      await axios.post('/api/auth/signup', {
        username: signupInput.username === '' ? signupInput.email : signupInput.username,
        email: signupInput.email,
        password: signupInput.password,
      })

      dispatch(authComplete())
      setIsSignedUp(true)
      setTimeout(() => navigate('/login'), 5000)
    } catch (error) {
      setDialogTitle('Error')
      setDialogMessage('Server Error. Please try again later.')
      setShowDialog(true)
      dispatch(authFailure())
    }
  }

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  return (
    <Container className='signin__container'>
      {isSignedUp ? (
        <SignUpInforDiv>
          <Typography>
            Thank you for signing up for MAKERLinked. An verification email has been sent to your
            email address, please verify your email address before using MAKERLinked.
          </Typography>
          <Typography>
            You will be redirect to the login page in 5 seconds, or you can manully click the
            following link and go
          </Typography>
          <RedirContainer>
            <Typography> to the login page now</Typography>
            <Link
              to='/login'
              style={{ color: COLORS.buttonLeaveOrange, marginLeft: '0.5rem', marginTop: 3 }}
            >
              Login
            </Link>
          </RedirContainer>
        </SignUpInforDiv>
      ) : (
        <ScrollView style={{ flex: 1 }}>
          <LogoBox path='/' />
          <Wrapper className='signin__container__wrapper'>
            <Title>Create an Account</Title>
            <Input
              placeholder='email (required)'
              name='email'
              onChange={onSignupChange}
              onBlur={async (e) => validateSignUpInput(e)}
            />
            {signupError.email && <WarningSpan>{signupError.email}</WarningSpan>}
            <Input
              placeholder='username (not required), please do not exist 18 characters'
              name='username'
              onChange={onSignupChange}
              onBlur={async (e) => validateSignUpInput(e)}
            />
            {signupError.username && (
              <WarningSpan className='err'>{signupError.username}</WarningSpan>
            )}
            <Input
              type={showPassword ? 'text' : 'password'}
              name='password'
              placeholder='password (required)'
              onChange={onSignupChange}
            />
            <Input
              type={showPassword ? 'text' : 'password'}
              name='confirmPassword'
              placeholder='please enter password again'
              onChange={onSignupChange}
            />
            <PasswordChecklist
              style={{ fontSize: '12px', fontFamily: 'var(--font-family)' }}
              rules={['minLength', 'number', 'specialChar', 'capital', 'match']}
              minLength={8}
              maxLength={18}
              value={signupInput.password}
              valueAgain={signupInput.confirmPassword}
              messages={{
                minLength: 'Password should have 8 to 18 characters',
                number: 'Password should have at least a number',
                capital: 'Password should have at least a capital letter',
                specialChar: 'Password should have at least a special character',
                match: 'passwords must match',
              }}
              onChange={(isValid) => {
                setIsPwdValid(isValid)
              }}
            />
            <Label>
              <Input
                type='checkbox'
                value='show-pwd'
                checked={showPassword}
                onChange={toggleShowPassword}
              />
              Show Password
            </Label>
            {loading ? (
              <CircularProgress sx={{ color: COLORS.buttonLeaveOrange }} />
            ) : (
              <StyledButton
                enterColor={COLORS.buttonEnterOrange}
                leaveColor={COLORS.buttonLeaveOrange}
                path=''
                text='Register'
                handleClick={handleSignUpClick}
              />
            )}

            <LoginDiv>
              <Typography>Already have an account?</Typography>
              <Link to='/login' style={{ color: COLORS.buttonLeaveOrange, marginLeft: '0.5rem' }}>
                Login
              </Link>
            </LoginDiv>
          </Wrapper>
          <More>
            <Links>
              <Link to='/contact' style={{ color: '#F7750A', marginRight: '0.5rem' }}>
                Help
              </Link>
              By Clicking &#34;Register&#34; Button, you defaultly agree with
              <Link to='/privacy' style={{ color: '#F7750A' }}>
                &nbsp;Privacy
              </Link>
              &nbsp;and
              <Link to='/terms' style={{ color: '#F7750A' }}>
                &nbsp;Terms
              </Link>
            </Links>
          </More>
          <PopupDialog
            dialogTitle={dialogTitle}
            handleClose={handleDialogClose}
            isDialogOpen={showDialog}
            actionsName='Close'
            maxWidth='45vw'
          >
            <Typography>{dialogMessage}</Typography>
            {/* <Typography>Test</Typography> */}
          </PopupDialog>
        </ScrollView>
      )}
    </Container>
  )
}

export default SignUp
