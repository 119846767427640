/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable jsx-quotes */
import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import VideocamIcon from '@mui/icons-material/Videocam'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'

import AttenderProfileCard from './AttenderProfileCard'
import MeetinDetailsEle from './MeetinDetailsEle'
import MeetingSummary from './MeetingSummary'

function MeetingDetailsCard({ meetingData }) {
  const [isSummaryOpen, setIsSummaryOpen] = useState(false)

  const handleClickOpen = () => {
    setIsSummaryOpen(true)
  }
  const handleClose = () => {
    setIsSummaryOpen(false)
  }

  return (
    <Card
      sx={{
        width: '100%',
        marginTop: '1.5%',
      }}
    >
      <CardContent>
        <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <AttenderProfileCard
            attender={{ name: 'Jie XIANG', title: 'CEO' }}
            avatar='JX'
            attenderId={0}
          />
          <AttenderProfileCard attender={meetingData.attender} avatar='MA' attenderId={1} />
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: '2%',
            mb: '2%',
          }}
        >
          {/* left column */}
          <Grid sx={{ mr: '25%' }}>
            <MeetinDetailsEle
              eleIcon={AccessTimeIcon}
              info={`${meetingData.meetingTime.start}-${meetingData.meetingTime.end}`}
              clickable={false}
            />
            <MeetinDetailsEle
              eleIcon={CalendarTodayIcon}
              info={meetingData.meetingDate}
              clickable={false}
            />
          </Grid>
          {/* right column */}
          <Grid>
            <MeetinDetailsEle
              eleIcon={VideocamIcon}
              info={meetingData.meetingChannel}
              clickable={false}
            />
            {meetingData.meetingSummary ? (
              <MeetinDetailsEle
                eleIcon={TextSnippetIcon}
                info='Meeting Summary'
                clickable
                handleClick={handleClickOpen}
              />
            ) : (
              <Grid />
            )}
          </Grid>
        </Grid>
        <Divider variant='fullWidth' />
        <Grid container direction='row' sx={{ mt: '1%' }}>
          <Grid sx={{ mr: '2%' }}>
            <HighlightOffIcon />
          </Grid>
          <Divider orientation='vertical' variant='fullWidth' flexItem />
          <Grid sx={{ ml: '2%' }}>
            <StarIcon sx={{ color: '#0099CB' }} />
            <StarIcon sx={{ color: '#0099CB' }} />
            <StarIcon sx={{ color: '#0099CB' }} />
            <StarIcon sx={{ color: '#0099CB' }} />
            <StarBorderIcon sx={{ color: '#0099CB' }} />
          </Grid>
        </Grid>
      </CardContent>
      <MeetingSummary handleClose={handleClose} isSummaryOpen={isSummaryOpen} />
    </Card>
  )
}

export default MeetingDetailsCard
