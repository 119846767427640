/* eslint-disable operator-linebreak */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentUser: null,
  loading: false,
  error: false,
  // interets: [],
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    authStart: (state) => {
      state.loading = true
    },
    loginSuccess: (state, action) => {
      state.loading = false
      state.currentUser = action.payload
    },
    authFailure: (state) => {
      state.loading = false
      state.error = true
    },
    authComplete: (state) => {
      state.loading = false
    },
    logout: (state) => {
      state.currentUser = null
      state.loading = false
      state.error = false
    },
    updateUser: (state, action) => {
      state.currentUser = action.payload
    },
    addEleToInterests: (state, action) => {
      if (!action.payload || action.payload === '') return
      state.currentUser.interests?.push(action.payload)
    },
    removeEleFromInterests: (state, action) => {
      const index = state.currentUser.interests?.indexOf(action.payload)
      if (index < 0) return
      state.currentUser.interests?.splice(index, 1)
    },
    resetInterests: (state) => {
      state.currentUser.interests = []
    },
  },
})

export const {
  authStart,
  authComplete,
  loginSuccess,
  authFailure,
  logout,
  updateUser,
  addEleToInterests,
  removeEleFromInterests,
  resetInterests,
} = userSlice.actions

export default userSlice.reducer
