/* eslint-disable no-unused-vars */
/* eslint-disable jsx-quotes */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react'

import { styled, alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import InputBase from '@mui/material/InputBase'
import Button from '@mui/material/Button'
import SearchIcon from '@mui/icons-material/Search'

import { blue } from '@mui/material/colors'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

import projectLists from '../../static/data_for_test/projectList.json'

const categories = [
  {
    id: 1,
    value: 'Startups',
  },
  {
    id: 2,
    value: 'Designers',
  },
  {
    id: 3,
    value: 'Engineers',
  },
  {
    id: 3,
    value: 'IP Holders',
  },
]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#151515',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '26ch',
      '&:focus': {
        width: '26ch',
      },
    },
  },
}))

const ColorButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontSize: 10,
  color: theme.palette.getContrastText(blue[500]),
  backgroundColor: blue[500],
  '&:hover': {
    backgroundColor: blue[700],
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}))

const Container = styled('div')`
  position: fixed;
  display: flex;
  align-items: center; // vertically center
  background-color: white; // to fix the issue of top combobox cannot show all: ;
  position: absolute;

  // to adjust the horizontal pos to the mid
  @media only screen and (min-width: 768px) {
    width: 70vw;
    top: 15%;
    left: 20%;
  }

  @media only screen and (min-width: 820px) {
    width: 70vw;
    top: 15%;
    left: 20%;
  }

  @media only screen and (min-width: 1024px) {
    width: 70vw;
    top: 15%;
    left: 23%;
  }

  @media only screen and (min-width: 1280px) {
    width: 70vw;
    top: 15%;
    left: 23%;
  }
`

function Favorites() {
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(8)

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === 'asc'
  //   setOrder(isAsc ? 'desc' : 'asc')
  //   setOrderBy(property)
  // }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = projectLists.map((proj) => proj.name)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - projectLists.length) : 0

  const visibleRows = React.useMemo(
    () =>
      stableSort(projectLists, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  )

  return (
    <Container>
      <Box sx={{ width: '100%', mt: '2%' }}>
        <Paper sx={{ width: '100%' }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Checkbox
              color='primary'
              indeterminate={selected.length > 0 && selected.length < projectLists.length}
              checked={projectLists.length > 0 && selected.length === projectLists.length}
              onChange={handleSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
              sx={{ marginLeft: '0.4%' }}
            />
            <TextField
              id='outlined-select-currency'
              select
              label='Category'
              defaultValue='EUR'
              size='small'
              sx={{
                width: '18vw',
                marginRight: { sm: '8%', md: '35%' },
              }}
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.value}>
                  {category.value}
                </MenuItem>
              ))}
            </TextField>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase placeholder='search item' inputProps={{ 'aria-label': 'search' }} />
            </Search>
          </div>

          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.name)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox
                          color='primary'
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell component='th' id={labelId} scope='row' padding='none'>
                        {row.name}
                      </TableCell>
                      <TableCell align='right'>
                        <ColorButton variant='contained'>Request Meeting</ColorButton>
                      </TableCell>
                      <TableCell align='right'>
                        <ColorButton variant='contained'>Due diligence</ColorButton>
                      </TableCell>
                      <TableCell align='right'>
                        <ColorButton variant='contained'>Appraisal report</ColorButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={projectLists.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Container>
  )
}

export default Favorites
