/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-quotes */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-unused-vars */
import React from 'react'
import { styled, alpha } from '@mui/material/styles'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import { visuallyHidden } from '@mui/utils'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import InputBase from '@mui/material/InputBase'

import SearchIcon from '@mui/icons-material/Search'
import AssessmentIcon from '@mui/icons-material/Assessment'

import { COLORS } from '../../../assets'
import ProjectsTable from './ProjectsTable'

const createData = (projectName, info, reqComp, dueDate, totalSales, projMgr, status) => ({
  projectName,
  info,
  reqComp,
  dueDate,
  totalSales,
  projMgr,
  status,
})

const projStatus = ['Due_diligence', 'Estimated', 'Completed', 'Estimating']

const searchList = ['Project', 'Request Company', 'Estimator']

const rowsData = [
  createData(
    'Wind turbine construction optimazation',
    '-',
    'HWS AirCRANE',
    'TBD',
    'N/A',
    'CHAO XU',
    'Estimated',
  ),
  createData(
    'The external curtain wall and steel structure to work together for BIPV',
    '-',
    'CEIEC',
    'TBD',
    'N/A',
    'Jie XIANG',
    'Estimating',
  ),
]
const headCells = [
  {
    id: 'projectName',
    numeric: false,
    disablePadding: true,
    label: 'Project',
  },
  {
    id: 'info',
    numeric: false,
    disablePadding: false,
    label: 'Info',
  },
  {
    id: 'reqComp',
    numeric: false,
    disablePadding: false,
    label: 'Request Company',
  },
  {
    id: 'dueDate',
    numeric: false,
    disablePadding: false,
    label: 'Due Date',
  },
  {
    id: 'totalSales',
    numeric: true,
    disablePadding: false,
    label: 'Total Sales',
  },
  {
    id: 'projMgr',
    numeric: false,
    disablePadding: false,
    label: 'Project Manager',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
]

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'grey',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const SearchInput = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'white',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '26ch',
      '&:focus': {
        width: '26ch',
      },
    },
  },
}))

const SearchDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: '-0.6vw',
  marginBottom: '2vh',
})

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? '1%' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
}

function Invitations() {
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('name')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsData.length) : 0

  const visibleRows = React.useMemo(
    () =>
      stableSort(rowsData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  )

  return (
    <Box sx={{ width: '100%' }}>
      <SearchDiv>
        <SearchInput>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase placeholder='search projects' inputProps={{ 'aria-label': 'search' }} />
        </SearchInput>
        <Autocomplete
          value='Project'
          disablePortal
          disableClearable
          id='combo-box-demo'
          options={searchList}
          size='small'
          componentsProps={{ popper: { style: { width: 'fit-content' } } }}
          sx={{
            '& .MuiInputBase-root': {
              // borderRadius: '10px !important',
              height: '5vh',
              backgroundColor: COLORS.yellowForDark,
              width: '11vw',
              marginLeft: '1vw',
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              // must use ...params.inputProp, otherwise it will report error of lost focus
              inputProps={{ ...params.inputProps, readOnly: true }}
            />
          )}
        />
      </SearchDiv>
      {/* <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`
                return (
                  <TableRow
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={row.projectName}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell
                      component='th'
                      id={labelId}
                      scope='row'
                      padding='1%'
                      sx={{ width: '8vw' }}
                    >
                      {row.projectName}
                    </TableCell>
                    <TableCell align='left' sx={{ width: '1vw' }}>
                      <AssessmentIcon />
                    </TableCell>
                    <TableCell align='left'>{row.reqComp}</TableCell>
                    <TableCell align='left'>{row.dueDate}</TableCell>
                    <TableCell align='center'>{row.totalSales}</TableCell>
                    <TableCell align='left'>{row.projMgr}</TableCell>
                    <TableCell align='left'>
                      <Autocomplete
                        value={row.status}
                        disablePortal
                        disableClearable
                        id='combo-box-demo'
                        options={projStatus}
                        size='small'
                        sx={{
                          '& .MuiInputBase-root': {
                            borderRadius: '15px !important',
                            height: '5vh',
                            backgroundColor: COLORS.yellowForDark,
                            width: '11vw',
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // must use ...params.inputProp, otherwise it will report error of lost focus
                            inputProps={{ ...params.inputProps, readOnly: true }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={rowsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper> */}
      <ProjectsTable />
    </Box>
  )
}

export default Invitations
