/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from 'react'
import styled from 'styled-components'

import { BulletPointDoc } from '../components'
import { COLORS } from '../../assets'
import useRegisterContext from '../../hooks/useRegisterContext'
import SingleOptionList from './SingleOptionList'

const makerCategories = [
  {
    id: 1,
    category: 'maker',
    alias: 'I am a maker',
  },
  {
    id: 2,
    category: 'makerRequester',
    alias: 'I need a maker',
  },
]

const makerRequesterTypes = [
  {
    id: 1,
    makerType: 'investor',
    alias: 'Investor',
  },
  {
    id: 2,
    makerType: 'industrial park',
    alias: 'Industrial Park',
  },
  {
    id: 3,
    makerType: 'enterprise',
    alias: 'Enterprise',
  },
  {
    id: 4,
    makerType: 'social organisation',
    alias: 'Social Organisation',
  },
  {
    id: 5,
    makerType: 'others',
    alias: 'Others',
  },
]

const makerTypes = [
  {
    id: 1,
    makerType: 'startup',
    alias: 'Startup',
  },
  {
    id: 2,
    makerType: 'designer',
    alias: 'Designer',
  },
  {
    id: 3,
    makerType: 'engineer',
    alias: 'Engineer',
  },
  { id: 4, makerType: 'ip holder', alias: 'IP Holder' },
  { id: 5, makerType: 'architect', alias: 'Architect' },
]

const OptionList = styled.ul`
  /* gap: 10px; */
  margin-top: -0.3rem;
  margin-right: 1.2rem;
  margin-left: -1rem;
  list-style-type: none;
`

const OptionItem = styled.li`
  /* gap: 10px; */
  padding-top: 1rem;
`

const Input = styled.input`
  border: none;
  background-color: transparent;
  outline: none;
  color: #aaaaaa;
`

const Label = styled.span`
  font-size: 1rem;
  display: inline-block;
`

const QuestionsContainer = styled.div`
  margin-left: 1rem;
`

// function TypeOptionList(props) {
//   const { dataList, name, checker, handleCheckChange } = props
//   return (
//     <>
//       <QuestionsContainer>
//         <BulletPointDoc text='My Group (required)' color={COLORS.textHighlightRed} />
//       </QuestionsContainer>
//       <OptionList>
//         {dataList.map((item) => (
//           <OptionItem key={item.id}>
//             <Label>
//               <Input
//                 type='radio'
//                 name={name}
//                 value={item.type}
//                 checked={checker === item.type}
//                 onChange={handleCheckChange}
//               />
//               {item.alias}
//             </Label>
//           </OptionItem>
//         ))}
//       </OptionList>
//     </>
//   )
// }

function MakerInquiry() {
  const { data, setData, handleChange } = useRegisterContext()
  const initMakerCategory = data.category === '' ? 'maker' : data.category
  const [makerCategory, setMakerCategory] = useState(initMakerCategory)
  const [makerType, setMakerType] = useState(data.makerType)
  const [showDialog, setShowDialog] = useState(false)

  // init category in data
  useEffect(() => {
    setData((prev) => ({ ...prev, category: 'maker' }))
  }, [])

  // const [data, setData] = useState({
  //   category: '',
  //   type: '',
  //   industry: [],
  //   businessModel: [],
  //   professional: [],
  //   targetCustomer: [],
  //   coreTechnology: [],
  //   numOfPatents: 0,
  //   website: '',
  //   email: '',
  // })

  const handleMakerCatChange = (e) => {
    const newCategory = e.target.value
    setMakerCategory(newCategory)
    // setData((prev) => ({ ...prev, category: newCategory }))
    handleChange(e.target.name, newCategory)
    // clear prev maker type data
    setMakerType('')
    handleChange('type', '')
  }

  const handleMakerTypeChange = (e) => {
    const newType = e.target.value
    setMakerType(newType)
    // setData((prev) => ({ ...prev, type: newType }))
    handleChange(e.target.name, newType)
  }

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  return (
    <div>
      <QuestionsContainer>
        <BulletPointDoc text='You are? (required)' color={COLORS.textHighlightRed} />
      </QuestionsContainer>
      <OptionList>
        {makerCategories.map((maker) => (
          <OptionItem key={maker.id}>
            <Label>
              <Input
                type='radio'
                value={maker.category}
                name='category'
                checked={makerCategory === maker.category}
                onChange={handleMakerCatChange}
              />
              {maker.alias}
            </Label>
          </OptionItem>
        ))}
      </OptionList>
      {/* <QuestionsContainer>
        <BulletPointDoc text='My Group (required)' color={COLORS.textHighlightRed} />
      </QuestionsContainer>
      <OptionList>
        {makerTypes.map((typeItem) => (
          <OptionItem key={typeItem.id}>
            <Label>
              <Input
                type='radio'
                value={typeItem.type}
                checked={makerType === typeItem.type}
                onChange={handleMakerTypeChange}
              />
              {typeItem.type}
            </Label>
          </OptionItem>
        ))}
      </OptionList> */}
      {/* <SingleOptionList
        dataList={makerCategories}
        title='You are? (required)'
        name='category'
        checker={makerCategory}
        setCheckerChange={setMakerCategory}
        // handleCxtDataChange={handleMakerCatChange}
        handleCxtDataChange={handleChange}
      /> */}
      <SingleOptionList
        dataList={makerCategory === 'maker' ? makerTypes : makerRequesterTypes}
        title='My Group (required)'
        name='makerType'
        checker={makerType}
        setCheckerChange={setMakerType}
        // handleCheckChange={handleMakerTypeChange}
        handleCxtDataChange={handleChange}
      />
    </div>
  )
}

export default MakerInquiry
