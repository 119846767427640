/* eslint-disable object-curly-newline */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-quotes */
import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { COLORS } from '../../../assets'

function MeetinDetailsEle({ eleIcon, info, clickable, handleClick = () => {} }) {
  const EleIcon = eleIcon

  return (
    <Grid
      sx={{
        width: '15vw',
        height: '3vh',
        mb: '3%',
        '&:hover': clickable
          ? { cursor: 'pointer', backgroundColor: COLORS.whiteHoverBgColor, borderRadius: 5 }
          : { cursor: 'default' },
      }}
      onClick={clickable ? () => handleClick() : () => {}}
    >
      <Typography
        sx={{
          fontSize: 16,
          ml: '2%',
          display: 'flex',
          alignItems: 'center',
        }}
        color='black'
        gutterBottom
      >
        <EleIcon sx={{ height: '2.5vh', width: '2.5vw' }} />
        {info}
      </Typography>
    </Grid>
  )
}

export default MeetinDetailsEle
