/* eslint-disable operator-linebreak */
/* eslint-disable jsx-quotes */
import React, { useState } from 'react'
import { ScrollView } from 'react-native'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { isEmail } from 'validator'

import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import { Button, PopupDialog, LogoBox } from '../features/components'
import { COLORS } from '../assets'
import { authStart, loginSuccess, authFailure } from '../services/redux/user-slice'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 10vh);
  color: #fff;
  font-family: var(--font-family);
  position: absolute;
  top: 50%;
  /* left: 50%; */
  transform: translate(-50%, -50%);

  // for iphone SE
  @media only screen and (min-width: 320px) {
    left: 50%;
  }

  // for iphone 12 PEO > 390
  @media only screen and (min-width: 390px) {
    left: 50%;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    left: 50%;
  }

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    left: 50%;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    left: 50%;
  }

  @media only screen and (min-width: 1024px) {
    left: 50%;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2xp solid;
  padding: 20px 50px;
  gap: 10px;
`

const Title = styled.h1`
  font-size: 1.5rem;
  text-align: center;
`

const Input = styled.input`
  width: 100%;
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  border: 1px solid;
  /* height: 3rem; */
`
const StyledButton = styled(Button)`
  width: 12rem;
  font-size: 1.2rem;
`

const More = styled.div`
  display: flex;
  margin-top: 10px;
  font-size: 0.8rem;
  align-items: center;
  margin-bottom: 2rem;
`

const Links = styled.div`
  margin-right: 20px;
  align-items: center;
`

const WarningSpan = styled.span`
  color: #ed7474;
  font-weight: bold;
`

const Label = styled.label`
  color: #fff;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 1em;
`

const LoginDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const PwdOptionsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
`

function Login() {
  const { loading, currentUser } = useSelector((state) => state.userAuth)
  const [signinInput, setSigninInput] = useState({
    email: '',
    password: '',
  })
  const [signinError, setSigninError] = useState({
    email: '',
    password: '',
  })
  const [showDialog, setShowDialog] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('Information')
  const [dialogMessage, setDialogMessage] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const [isRegisterHovering, setIsRegisterHovering] = useState(false)
  // const navigate = useNavigate()

  // const handleRegisterMouseEnter = () => {
  //   setIsRegisterHovering(true)
  // }

  // const handleRegisterMouseLeave = () => {
  //   setIsRegisterHovering(false)
  // }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const validateSigninInput = (e) => {
    const { name, value } = e.target
    setSigninError((prev) => {
      const statObj = { ...prev, [name]: '' }
      let msg = ''
      switch (name) {
        case 'email':
          if (!value) {
            msg = 'Please input your email addresss'
          } else if (!isEmail(value)) {
            msg = 'Please input an valid email address'
          }
          statObj[name] = msg
          break
        case 'password':
          statObj[name] = !value ? 'Please input your password' : ''
          break
        default:
          break
      }
      return statObj
    })
  }

  const onSigninChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target

    // reset signin error
    setSigninError((prev) => {
      const statObj = { ...prev, [name]: '' }
      return statObj
    })

    setSigninInput((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const handleSigninClick = async (e) => {
    e.preventDefault()

    if (signinInput.email === '' || signinInput.password === '') {
      return
    }

    dispatch(authStart())
    try {
      const res = await axios.post('/api/auth/signin', {
        email: signinInput.email,
        password: signinInput.password,
      })
      dispatch(loginSuccess(res.data))
      navigate('/')
    } catch (error) {
      const { response } = error
      const msg =
        response?.status >= 400 && response?.status <= 404
          ? response?.data?.message
          : 'Server Error. Please try again later.'
      setDialogTitle('Error')
      setDialogMessage(msg)
      setShowDialog(true)
      dispatch(authFailure())
    }
  }

  const isEmailDisabled = () => !!currentUser

  return (
    <Container className='signin__container'>
      <ScrollView style={{ flex: 1 }}>
        <LogoBox path='/' />
        <Wrapper className='signin__container__wrapper'>
          <Title>Login Your Account</Title>
          <Input
            placeholder={currentUser?.email || 'email'}
            name='email'
            disabled={isEmailDisabled()}
            onChange={onSigninChange}
            onBlur={async (e) => validateSigninInput(e)}
          />
          {signinError.email && <WarningSpan>{signinError.email}</WarningSpan>}
          <Input
            type={showPassword ? 'text' : 'password'}
            name='password'
            placeholder='password'
            onChange={onSigninChange}
            onBlur={async (e) => validateSigninInput(e)}
          />
          {signinError.password && <WarningSpan>{signinError.password}</WarningSpan>}
          <PwdOptionsDiv>
            <Link to='/reset-pwd-request' style={{ color: '#F7750A', marginRight: '2vw' }}>
              Forget your password?
            </Link>
            <Label>
              <Input
                type='checkbox'
                value='show-pwd'
                checked={showPassword}
                onChange={toggleShowPassword}
              />
              Show Password
            </Label>
          </PwdOptionsDiv>

          {loading ? (
            <CircularProgress sx={{ color: COLORS.buttonLeaveOrange }} />
          ) : (
            <StyledButton
              enterColor='#FE481F'
              leaveColor='#F7750A'
              path=''
              text='Login'
              handleClick={handleSigninClick}
            />
          )}
          <LoginDiv>
            <Typography>Need an account?</Typography>
            <Link to='/signup' style={{ color: '#F7750A', marginLeft: '0.5rem' }}>
              Sign up
            </Link>
          </LoginDiv>
        </Wrapper>
        <More>
          <Links>
            <Link to='/contact' style={{ color: '#F7750A', marginRight: '0.5rem' }}>
              Help
            </Link>
            By Clicking &#34;Login&#34; Button, you defaultly agree with
            <Link to='/privacy' style={{ color: '#F7750A' }}>
              &nbsp;Privacy
            </Link>
            &nbsp;and
            <Link to='/terms' style={{ color: '#F7750A' }}>
              &nbsp;Terms
            </Link>
          </Links>
        </More>
        <PopupDialog
          dialogTitle={dialogTitle}
          handleClose={handleDialogClose}
          isDialogOpen={showDialog}
          actionsName='Close'
          maxWidth='45vw'
        >
          <Typography>{dialogMessage}</Typography>
          {/* <Typography>Test</Typography> */}
        </PopupDialog>
      </ScrollView>
    </Container>
  )
}

export default Login
