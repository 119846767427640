/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-quotes */
import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Button from '@mui/material/Button'

// const drawerWidth = 240

function MkLinkedAppBar({ drawerWidth, collapseWidth, title }) {
  const [auth, setAuth] = React.useState(true)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleChange = (event) => {
    setAuth(event.target.checked)
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <AppBar
        position='fixed'
        sx={{
          width: {
            sm: `calc(100% - ${drawerWidth}px)`,
            md: `calc(100% - ${drawerWidth}px - 17%)`,
            lg: `calc(100% - ${drawerWidth}px)`,
          },
          // width: '84vw',
          marginLeft: {
            sm: `calc(100% -${collapseWidth}px)`,
            md: `calc(100% - ${drawerWidth}px)`,
            lg: `calc(100% - ${drawerWidth}px)`,
          },
          // backgroundColor: '#4B4C4D',
        }}
      >
        <Toolbar>
          <Typography
            variant='h6'
            component='div'
            sx={{
              flexGrow: 1,
              paddingLeft: { sm: '10%', md: '10%', lg: '12rem' },
            }}
          >
            {title}
          </Typography>

          {/* <FormGroup sx={{ background: '#fff' }}>
            <FormControlLabel
              control={<Switch checked={auth} onChange={handleChange} aria-label='login switch' />}
              label={auth ? 'Logout' : 'Login'}
              sx={{ color: '#000' }}
            />
          </FormGroup> */}

          {auth && (
            <div>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleMenu}
                color='inherit'
              >
                <AccountCircle />
              </IconButton>
              <Button color='inherit'>Logout</Button>
              <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default MkLinkedAppBar
