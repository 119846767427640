/* eslint-disable jsx-quotes */
import React, { useState } from 'react'
import styled from 'styled-components'
import { styled as style } from '@mui/material/styles'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 5vh);
  /* color: #fff; */
  /* font-family: var(--font-family); */
  position: absolute;
  transform: translate(-50%, -50%);

  // for iphone SE
  @media only screen and (min-width: 320px) {
    flex-direction: column;
    top: 55%;
    left: 55%;
  }

  // for iphone 12 PEO > 390
  /* @media only screen and (min-width: 390px) {
    flex-direction: column;
    top: 50%;
    left: 55%;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    flex-direction: column;
    top: 50%;
    left: 55%;
  } */

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    flex-direction: row;
    top: 55%;
    left: 55%;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    flex-direction: row;
    top: 55%;
    left: 55%;
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    top: 55%;
    left: 60%;
  }
`

const TabsDiv = style('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

function TomakersService() {
  const [value, setValue] = useState('one')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Container>
      <TabsDiv>
        <Box
          sx={{
            p: 3,
            width: '60vw',
            borderColor: 'divider',
            color: 'white',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            textColor='inherit'
            indicatorColor='secondary'
            aria-label='secondary tabs'
            tabItemContainerStyle={{ position: 'fixed', bottom: '0', width: '100%' }}
          >
            <Tab
              sx={{ fontSize: '1rem', textTransform: 'none' }}
              value='one'
              label='Due Diligence'
            />
            <Tab
              sx={{ fontSize: '1rem', textTransform: 'none' }}
              value='two'
              label='Appraisal report'
            />
            <Tab
              sx={{ fontSize: '1rem', textTransform: 'none' }}
              value='three'
              label='Market survey'
            />
            <Tab
              sx={{ fontSize: '1rem', textTransform: 'none', mr: 'auto' }}
              value='four'
              label='Match making'
            />
            <Tab sx={{ fontSize: '1rem' }} value='five' label='XXX' />
          </Tabs>
          {value === 'one' && <Box sx={{ p: 3 }}>{/* <AllMeetings /> */}</Box>}

          {value === 'two' && (
            <Box sx={{ p: 3 }}>
              <Typography variant='h5'>Tab 2 Content</Typography>
              <Typography variant='p'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
              </Typography>
            </Box>
          )}

          {value === 'three' && (
            <Box sx={{ p: 3 }}>
              <Typography variant='h5'>Tab 2 Content</Typography>
              <Typography variant='p'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
              </Typography>
            </Box>
          )}
        </Box>
      </TabsDiv>
    </Container>
  )
}

export default TomakersService
