/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState } from 'react'

const RegisterContext = createContext({})

export function RegisterProvider({ children }) {
  const title = {
    0: 'Maker or Need a Maker?',
    1: 'Further Information',
    2: 'Further Information',
    3: 'Further Information',
    4: 'Website and Email',
  }

  const [curPage, setCurPage] = useState(0)

  const [data, setData] = useState({
    category: '',
    makerType: '',
    industry: [],
    businessModel: [],
    professional: [],
    targetCustomer: [],
    coreTechnology: [],
    numOfPatents: '',
    website: '',
    email: '',
    name: '',
  })

  const handleChange = (name, value) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSelectChange = (e) => {}

  const handlePrevPage = () => setCurPage((prev) => prev - 1)
  const handleNextPage = () => setCurPage((prev) => prev + 1)

  const disableNextOnPage0 = curPage === 0 && data?.makerType === ''

  // page2 next button checker
  const isMakerReqEmptyPage1 =
    data.category === 'makerRequester' &&
    (data.makerType === 'enterprise' || data.makerType === 'social organisation') &&
    data?.businessModel.length === 0

  const isMakersEmptyPage1 =
    data.category === 'maker' && data.makerType === 'startup' && data?.businessModel.length === 0

  const disableNextOnPage1 =
    curPage === 1 && (data?.industry.length === 0 || isMakerReqEmptyPage1 || isMakersEmptyPage1)

  const isMakerReqEmptyPage2 =
    data.category === 'makerRequester' &&
    data.makerType === 'others' &&
    data?.professional.length === 0

  const isMakerEmptyPage2 =
    data.category === 'maker' &&
    (data.makerType === 'designer' || data.makerType === 'architect') &&
    data?.professional.length === 0

  const disableNextOnPage2 =
    curPage === 2 &&
    (data?.targetCustomer.length === 0 || isMakerEmptyPage2 || isMakerReqEmptyPage2)

  const isMakerCoreTechEmptyPage3 = data.category === 'maker' && data?.coreTechnology.length === 0

  const isMakerPatentEmptyPage3 =
    data.category === 'maker' &&
    (data.makerType === 'engineer' || data.makerType === 'ip holder') &&
    data?.numOfPatents === ''

  const isMakerReqCoreTechEmptyPage3 =
    data.category === 'makerRequester' &&
    data.makerType !== 'social organisation' &&
    data?.coreTechnology.length === 0

  const disableNextOnPage3 =
    curPage === 3 &&
    (isMakerCoreTechEmptyPage3 || isMakerPatentEmptyPage3 || isMakerReqCoreTechEmptyPage3)

  const disableNext =
    curPage === Object.keys(title).length - 1 ||
    disableNextOnPage0 ||
    disableNextOnPage1 ||
    disableNextOnPage2 ||
    disableNextOnPage3

  const disablePrev = curPage === 0

  // always have one default selected, so no need to check next page
  const canSubmit = data?.email !== '' && data?.website !== '' && data?.name !== ''

  return (
    <RegisterContext.Provider
      value={{
        title,
        curPage,
        setCurPage,
        data,
        setData,
        handleChange,
        canSubmit,
        handlePrevPage,
        disableNext,
        disablePrev,
      }}
    >
      {children}
    </RegisterContext.Provider>
  )
}

export default RegisterContext
