const hasChildren = (listItem) => {
  const { subList } = listItem

  if (!subList || subList.length === 0) {
    return false
  }

  return true
}

export default hasChildren
