/* eslint-disable operator-linebreak */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-quotes */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'

import { styled, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import SearchTab from './SearchTab'
import SearchTable from './SearchTable'
import SearchCards from './SearchCards'

import { normalizeBigNumber } from '../../../utils/number-transformer'

const amMakerColumns = [
  { id: 'name', label: 'Name', minWidth: 135 },
  { id: 'category', label: 'Category', minWidth: 30 },
  {
    id: 'investRange',
    label: 'Investment Range',
    minWidth: 160,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'investStage',
    label: 'Investment Stage',
    minWidth: 35,
    align: 'right',
  },
  {
    id: 'managedFund',
    label: 'Managed Fund',
    minWidth: 80,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'tags',
    label: 'Tags',
    minWidth: 170,
    align: 'right',
  },
]

const needMakerColumns = [
  { id: 'name', label: 'Name', minWidth: 135 },
  { id: 'description', label: 'Description', minWidth: 115 },
  {
    id: 'founded',
    label: 'Founded',
    minWidth: 30,
    align: 'right',
  },
  {
    id: 'businessModel',
    label: 'Business Model',
    minWidth: 25,
    align: 'right',
  },
  {
    id: 'employees',
    label: 'Employees',
    minWidth: 20,
    align: 'right',
  },
  {
    id: 'fundingStage',
    label: 'Funding Stage',
    minWidth: 20,
    align: 'right',
  },
  {
    id: 'tags',
    label: 'Tags',
    minWidth: 50,
    align: 'right',
  },
]

function createNeedMakerData(
  name,
  description,
  founded,
  businessModel,
  employees,
  fundingStage,
  tags,
) {
  return {
    name,
    description,
    founded: founded === 0 ? 'N/A' : founded,
    businessModel,
    employees: employees?.length === 0 ? 'N/A' : `${employees[0]} - ${employees[1]}`,
    fundingStage: fundingStage === '' ? 'N/A' : fundingStage,
    tags,
  }
}

const needMakerData = [
  createNeedMakerData(
    'Industrialization of new air-cooled hydrogen fuel cell reactor',
    'The company has core technologies in the fields of metal plates, electric stack flow field and membrane electrodes, and has declared 5 patents. The fuel cell metal pole plate products developed by the team members have been exported to overseas markets in bulk and successfully applied in commercialised fuel cell vehicles, which have been verified by large-scale commercialisation. The surface modification technology of metal bipolar plates can be benchmarked against Toyota of Japan, reaching the international advanced level, with long life and low cost characteristics.\n\nAt present, the development of air-cooled reactor products has been completed, featuring high power density, long life, high productivity and low cost. They are mainly used in a variety of high-value application scenarios, such as small vehicles, cogeneration, backup power, and small ships.',
    2019,
    'B2B, B2C',
    [10, 30],
    'Pre-A',
    'fuel cell, transportation\n',
  ),
  createNeedMakerData(
    'High value utilization of recycled plastic products',
    'Focus on the technological innovation of recycled plastics, constantly break through, and provide sustainable recycled plastic products and services for socially responsible enterprises around the world. Over the past 6 years, more than 200,000 tons of recycled plastics have been supplied, and the products have been used in more than 30 medium and high-end applications.',
    2013,
    'B2B',
    [],
    '',
    'Recycled plastic',
  ),
  createNeedMakerData(
    'eVTOL',
    'This project aims to alleviate urban traffic congestion, perform various special tasks and other major needs, and create a demonstrable intelligent low-altitude manned aircraft prototype. Create urban air traffic application pilot, provide future urban point-to-point air traffic travel solutions, and promote the industry development and commercial application of urban air traffic',
    0,
    'B2B',
    [10, 30],
    'Pre-A',
    'eVTOL, transporation',
  ),
  createNeedMakerData(
    'Energy Consumption Thermal Control and Storage Solution',
    'We are a renewable energy technology company that focuses on renewable energy technology. We adheres to the road of scientific and technological exploration, using the combination of advanced thermal energy storage temperature control materials and artificial intelligence to provide the most cost-effective thermal control and storage system for customers. The company serves clients including CapitaLand, Swire Properties, Colgate and other world-renowned companies.',
    2020,
    'B2B, B2C',
    [10, 30],
    'A',
    'Sustainable energy',
  ),
  createNeedMakerData(
    'Continuous pumping filter type oil-water separator',
    'Continuous in situ pumping of oil from the water surface to solve the problem of river oil pollution, can be applied to high water cut oil fields, shale oil, clean up food oil, clean up iron oil.',
    2020,
    'B2B',
    [5, 10],
    'Seed',
    'Water treatement',
  ),
  createNeedMakerData(
    'AR Digital pet + interaction platform',
    'Fusing the aesthetics of nature and technology, it creates a new form of pet care for urbanites that blends reality and reality. With the help of an AI engine, artists and enthusiasts can cultivate digital creatures on the "Island of All Creatures", and bring meta-universal beings into the "Aquarium of the Future" by using holographic projections and transparent display technology.',
    2021,
    'B2C',
    [5, 10],
    'Seed',
    'Consumer entertainment',
  ),
  createNeedMakerData(
    'Intelligent water quality sensor monitoring system',
    'Sensors can be quickly on-site, in-situ detection, and through some new technologies (such as laser self-control system, carbon film electrode preparation/processing process of intelligent robotic systems, etc.) to ensure that the sensor probe stability, high sensitivity detection at the same time, but also significantly reduce the cost of manufacturing, effective detection of various types of organic and inorganic indicators of the water environment, with a wide range of coverage, high detection accuracy, the use of the threshold is low, and basically solves the main pain point of water quality monitoring market problems',
    '',
    'B2B',
    [0, 5],
    'Angel',
    'Water treatement',
  ),
  createNeedMakerData(
    'Self-developed ABIoT iot platform',
    'Focus on production line digital systems, energy digital systems and AI+ scenarios and other solutions, make good use of data, calculate value, and help enterprises digital transformation',
    2020,
    'B2C, C2C, B2B, B2B2C',
    [10, 30],
    'Pre-A',
    'Energy digitization, production line digitization and AI scenariography',
  ),
]

const BgDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '1rem',
})

const TitleAndSearchDiv = styled('div')(({ theme }) => ({
  marginTop: '-0.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginRight: '1rem',
  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const Title = styled('h1')({
  // marginRight: '26rem',
  fontSize: '36px',
})

const RouteHeader = styled('h5')({
  marginTop: '0.8rem',
})
/* margin-bottom: 40px; */

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '26ch',
      '&:focus': {
        width: '26ch',
      },
    },
  },
}))

const TabsDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: '5%',
  flex: 1,
  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

function SearchContents({ routes }) {
  const location = useLocation()
  const [value, setValue] = useState('one')
  const [allDataCount, setAllDataCount] = useState(0)

  const pageIdentity = location.pathname.split('/')[1]
  // am-maker: check cat of markRequester data, need-maker: check cat of maker data
  const category = pageIdentity === 'am-maker' ? 'makerRequester' : 'maker'
  const columns = pageIdentity === 'am-maker' ? amMakerColumns : needMakerColumns
  // TODO: the following is for mock data
  // const data = pageIdentity === 'am-maker' ? amMakerData : needMakerDataV1

  const capitalFstLetter = (str) => {
    if (!str) return null
    return str?.charAt(0)?.toUpperCase() + str?.slice(1)
  }

  const getPathname = () => {
    const pathnameEle = location?.pathname?.split('/')
    // xx-maker/search
    if (pathnameEle?.length < 4) {
      return capitalFstLetter(routes)
    }
    return capitalFstLetter(pathnameEle[3])
  }

  useEffect(() => {
    getPathname()
    // getMakerProfilesByCategory()
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <BgDiv>
      <RouteHeader>Home &gt; {capitalFstLetter(routes)}</RouteHeader>
      <TitleAndSearchDiv>
        <Title>{capitalFstLetter(routes)}</Title>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder='search by name & description'
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search>
      </TitleAndSearchDiv>
      <SearchCards />
      <TabsDiv>
        <Box sx={{ width: '100%', borderColor: 'divider', color: 'white' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor='inherit'
            indicatorColor='secondary'
            aria-label='secondary tabs'
          >
            <Tab
              sx={{ textTransform: 'none', fontSize: '1rem' }}
              value='one'
              // label={`${data?.length} ${getPathname()}`}
              label={`${allDataCount} ${getPathname()}`}
            />
            <Tab
              sx={{ textTransform: 'none', fontSize: '1rem' }}
              value='two'
              label='1 In the News'
            />
            <Tab
              sx={{ textTransform: 'none', fontSize: '1rem' }}
              value='three'
              label='2 Recently Updated'
            />
          </Tabs>
          {value === 'one' && (
            <Box sx={{ p: 3 }}>
              <SearchTable
                columns={columns}
                // data={data}
                category={category}
                setAllDataCount={setAllDataCount}
              />
            </Box>
          )}

          {value === 'two' && (
            <Box sx={{ p: 3 }}>
              <Typography variant='h5'>Tab 2 Content</Typography>
              <Typography variant='p'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
              </Typography>
            </Box>
          )}

          {value === 'three' && (
            <Box sx={{ p: 3 }}>
              <Typography variant='h5'>Tab 2 Content</Typography>
              <Typography variant='p'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
              </Typography>
            </Box>
          )}
        </Box>
      </TabsDiv>
    </BgDiv>
  )
}

export default SearchContents
