/* eslint-disable react/jsx-indent */
/* eslint-disable consistent-return */
/* eslint-disable object-curly-newline */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-quotes */
import './App.css'
import styled from 'styled-components'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import {
  Persona,
  NeedMakerSearch,
  AmMakerSearch,
  SignUp,
  NeedMakerProfile,
  AmMakerProfile,
  NotFound,
  MKLinkedAcct,
  Home,
  Login,
  ConfirmEmail,
  ResetPwdRequest,
  ResetPwd,
  PersonalInfo,
  MakerProfile,
  Terms,
  Privacy,
} from './routes'
import {
  Favorites,
  OngoingProjects,
  MkLinkedHome,
  InvestAssist,
  MyMeetings,
  TomakersService,
  TechExchangeFlow,
  ProjMgtBoard,
} from './routes/mklinked_acct'
import { RecentUpdate, Startups, News } from './routes/search_parts'

const BgDiv = styled.div`
  // temp
  height: 100%;
  display: flex;
  color: white;
`

const Wrapper = styled.div``

function App() {
  return (
    <BgDiv>
      <BrowserRouter>
        <Wrapper>
          <Routes>
            {/* only for test that use SideBar for home */}
            <Route index element={<Home />} />
            <Route path='signup' element={<SignUp />} />
            <Route path='login' element={<Login />} />
            <Route path='confirm-email' element={<ConfirmEmail />} />
            <Route path='reset-pwd-request' element={<ResetPwdRequest />} />
            <Route path='pwd-reset' element={<ResetPwd />} />
            <Route path='persona' element={<Persona />} />
            <Route path='personal-info' element={<PersonalInfo />} />
            <Route path='maker-profile' element={<MakerProfile />} />
            <Route path='terms' element={<Terms />} />
            <Route path='privacy' element={<Privacy />} />
            <Route path='need-maker'>
              <Route path='search' element={<NeedMakerSearch />}>
                <Route path='startups' element={<Startups />}>
                  <Route path='news' element={<News />} />
                  <Route path='recent-update' element={<RecentUpdate />} />
                </Route>
              </Route>
              {/* <Route path='signup' element={<SignUp />} /> */}
              {/* <Route path='login' element={<Login />} /> */}
              {/* <Route path='persona' element={<Persona />} /> */}
              <Route path='profile' element={<NeedMakerProfile />} />
              <Route path='makerlinked-acct' element={<MKLinkedAcct />}>
                <Route path='home' element={<MkLinkedHome />} />
                <Route path='favorites' element={<Favorites />} />
                <Route path='ongoing-projects' element={<OngoingProjects />}>
                  <Route path='project-board' element={<ProjMgtBoard />} />
                </Route>
                <Route path='investment-assistant' element={<InvestAssist />} />
                <Route path='my-meetings' element={<MyMeetings />} />
                <Route path='tomakers-service' element={<TomakersService />} />
              </Route>
            </Route>
            <Route path='am-maker'>
              <Route path='search' element={<AmMakerSearch />}>
                <Route path='investors' element={<Startups />}>
                  <Route path='news' element={<News />} />
                  <Route path='recent-update' element={<RecentUpdate />} />
                </Route>
              </Route>
              {/* <Route path='signup' element={<SignUp />} /> */}
              {/* <Route path='login' element={<Login />} /> */}
              {/* <Route path='persona' element={<Persona />} /> */}
              <Route path='profile' element={<AmMakerProfile />} />
              <Route path='makerlinked-acct' element={<MKLinkedAcct />}>
                <Route path='home' element={<MkLinkedHome />} />
                <Route path='favorites' element={<Favorites />} />
                {/* <Route path='ongoing-projects' element={<OngoingProjects />} /> */}
                <Route path='ongoing-projects' element={<OngoingProjects />} />
                <Route path='project-board/:projectId' element={<ProjMgtBoard />} />
                <Route path='investment-assistant' element={<InvestAssist />}>
                  <Route path='exchange-flow' element={<TechExchangeFlow />} />
                </Route>
                <Route path='my-meetings' element={<MyMeetings />} />
                <Route path='tomakers-service' element={<TomakersService />} />
              </Route>
            </Route>
            {/* <Route path='search' element={<Search />}>
              <Route path='startups' element={<Startups />}>
                <Route path='news' element={<News />} />
                <Route path='recent-update' element={<RecentUpdate />} />
              </Route>
            </Route>
            <Route path='signup' element={<SignUp />} />
            <Route path='persona' element={<Persona />} />
            <Route path='profile' element={<Profile />} />
            <Route path='makerlinked-acct' element={<MKLinkedAcct />}>
              <Route path='home' element={<MkLinkedHome />} />
              <Route path='favorites' element={<Favorites />} />
              <Route path='ongoing-projects' element={<OngoingProjects />} />
              <Route path='investment-assistant' element={<InvestAssist />} />
              <Route path='my-meetings' element={<MyMeetings />} />
              <Route path='tomakers-service' element={<TomakersService />} />
            </Route> */}
            <Route path='*' element={<NotFound />} />
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </BgDiv>
  )
}

export default App
