/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable jsx-quotes */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { styled, useTheme } from '@mui/material/styles'

import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

import { MakerLinkedLogo } from '../../static/images'
import { COLORS, icons } from '../../assets'

const listItem2 = [
  {
    id: 0,
    name: 'Profile',
    icon: <icons.ProfileIcon />,
  },
  {
    id: 1,
    name: 'Favorite',
    icon: <icons.FavoriteIcon />,
  },
  {
    id: 2,
    name: 'Ongoing projects',
    icon: <icons.OngoingProjectIcon />,
  },
  {
    id: 3,
    name: 'Investment assistant',
    icon: <icons.InvestmentIcon />,
  },
  {
    id: 4,
    name: 'My meetings',
    icon: <icons.MyMeetingIcon />,
  },
  {
    id: 5,
    name: 'TOMAKERS service',
    icon: <icons.ToMakersServiceIcon />,
  },
  {
    id: 6,
    name: 'Cart & Payment',
    icon: <icons.PaymentIcon />,
  },
  {
    id: 7,
    name: 'Log out',
    icon: <icons.LogoutIcon />,
  },
]

const openedMenu = (theme, drawerWidth, collapseWidth) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const collapsedMenu = (theme, collapseWidth) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '70px',
  [theme.breakpoints.up('md')]: {
    width: `${collapseWidth}px`,
  },
})

function MkLinkedAcctMenu({ drawerWidth, collapseWidth }) {
  // test only
  const theme = useTheme()

  const [menuOpen, setMenuOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(() => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(menuOpen && {
      ...openedMenu(theme),
      '& .MuiDrawer-paper': openedMenu(theme),
    }),
    ...(!menuOpen && {
      ...collapsedMenu(theme, collapseWidth),
      '& .MuiDrawer-paper': collapsedMenu(theme, collapseWidth),
    }),
  }))

  const getWindowSize = () => {
    setWindowWidth(window.innerWidth)
  }

  const handleMenuItemClick = (e) => {
    // This is to get the value of the list, so to handle click accordingly
    // console.log(e.currentTarget.getAttribute('value'))
    const menuItemValue = e.currentTarget.getAttribute('value')
    switch (menuItemValue) {
      case 'Profile':
        navigate(`/${location.pathname.split('/')[1]}/profile`)
        break
      case 'Favorite':
        navigate('favorites', { state: { name: 'Favorites' } })
        break
      case 'Investment assistant':
        navigate('investment-assistant', { state: { name: 'Investment Assistant' } })
        break
      case 'My meetings':
        navigate('my-meetings', { state: { name: 'My Meetings' } })
        break
      case 'Ongoing projects':
        navigate('ongoing-projects', {
          state: { name: 'Ongoing Collaborative Projects' },
        })
        break
      case 'TOMAKERS service':
        navigate('tomakers-service', { state: { name: 'TOMAKERS Services' } })
        break
      default:
    }
  }

  useEffect(() => {
    window.addEventListener('resize', getWindowSize)

    if (windowWidth < 992 && windowWidth > 375) {
      setMenuOpen(false)
    } else {
      setMenuOpen(true)
    }

    return () => {
      window.removeEventListener('resize', getWindowSize)
    }
  }, [window.innerWidth])

  return (
    <div>
      <Box
        sx={{ display: { xs: 'none', sm: 'flex', md: 'flex' } }}
        borderRight={10}
        borderColor='red'
      >
        {/* <CssBaseline  /> */}
        {/* <AppBar
          position='absolute'
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            marginRight: '-235px',
          }}
        >
          <Toolbar>
            <Typography variant='h6' noWrap component='div'>
              Permanent drawer
            </Typography>
          </Toolbar>
        </AppBar> */}
        <Drawer
          sx={{
            marginLeft: '-1rem',
            // width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              // width: drawerWidth,
              boxSizing: 'border-box',
              // transition: 'none !important',
            },
          }}
          open={false}
          variant='permanent'
          anchor='left'
        >
          <Toolbar sx={{ display: 'flex' }}>
            {/* <Typography variant='h5' noWrap component='div' sx={{ fontWeight: 'bold' }}>
              MAKERLinked
            </Typography> */}
            <Box
              component='img'
              sx={{
                // marginTop: { xs: '1%', sm: '1%', md: '-10%' },
                // marginLeft: { xs: '25%', sm: '16%', md: '7%' },
                // marginBottom: { xs: '10%', sm: '7%', md: '7%' },
                height: { xs: '0vh', sm: '0vh', md: '4.5vh' },
                width: { xs: '0vw', sm: '0vw', md: '12vw' },
              }}
              alt='MAKERLINKED Logo'
              src={MakerLinkedLogo}
            />
          </Toolbar>
          {/* <Divider /> */}
          <List sx={{ marginBottom: '-1rem' }}>
            <ListItem
              key='home_key'
              disablePadding
              sx={{
                // hover states
                '& .MuiListItemButton-root:hover': {
                  bgcolor: COLORS.whiteHoverBgColor,
                  '&, & .MuiListItemIcon-root': {
                    color: 'white',
                  },
                },
              }}
            >
              <ListItemButton onClick={() => navigate('/')}>
                <icons.HomeIcon sx={{ marginRight: '1.8rem' }} />
                <ListItemText primary='Home' />
              </ListItemButton>
            </ListItem>
          </List>

          {/* <Divider /> */}
          <List>
            {listItem2.map((listItem) => (
              <ListItem
                key={listItem.id}
                disablePadding
                sx={{
                  // hover states
                  '& .MuiListItemButton-root:hover': {
                    bgcolor: COLORS.whiteHoverBgColor,
                    '&, & .MuiListItemIcon-root': {
                      color: 'white',
                    },
                  },
                }}
              >
                <ListItemButton value={listItem.name} onClick={(e) => handleMenuItemClick(e)}>
                  {listItem.icon}
                  <ListItemText primary={listItem.name} sx={{ marginLeft: '1.8rem' }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Box>
    </div>
  )
}

export default MkLinkedAcctMenu
