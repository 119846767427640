/* eslint-disable jsx-quotes */
import React from 'react'
import styled from 'styled-components'

import Typography from '@mui/material/Typography'
import { LogoBox } from '../../features/components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  font-family: var(--font-family);
  position: absolute;
  /* left: 50%; */
  top: 10%;

  // for iphone SE
  @media only screen and (min-width: 320px) {
    left: 10%;
    right: 10%;
  }

  // for iphone 12 PEO > 390
  @media only screen and (min-width: 390px) {
    left: 10%;
    right: 10%;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    left: 10%;
    right: 10%;
  }

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    left: 10%;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    left: 10%;
    right: 10%;
  }

  @media only screen and (min-width: 1024px) {
    left: 10%;
    right: 10%;
  }
`

const LogoContainer = styled.div`
  margin-top: 5vh;
  margin-bottom: 6vh;
`

const SubParaphs = styled.div`
  width: auto;
  margin-top: 1.5rem;
`

function Privacy() {
  return (
    <Container>
      <LogoContainer>
        <LogoBox path='/' />
      </LogoContainer>
      <Typography variant='h4' sx={{ marginBottom: '5vh' }}>
        Privacy Policy
      </Typography>
      <Typography>
        Your visit to this Website is subject to this Privacy Policy and our Terms of Service . This
        site is provided and maintained by ToMakers (who shall be referred in this Privacy Policy as
        &quot;we,&quot; &quot;us,&quot; or &quot;our&quot; shall be construed accordingly).
      </Typography>
      <Typography sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
        We may also use any non-personal or anonymised data about you for internal business
        purposes, such as generating statistics and developing marketing plans.
      </Typography>
      <Typography sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
        Once you have registered with us, we will use your data to communicate with you by post,
        email or telephone.
      </Typography>
      <Typography sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
        We may disclose any data we deem necessary, in our sole discretion, to comply with any
        applicable law or on the request of any appropriate authority. Any personal data or
        sensitive personal data you provide to us will be processed in accordance with Data
        Protection Laws. However, the electronic transmission and processing of data is inherently
        insecure and we cannot absolutely guarantee the security of any data you provide to us.
      </Typography>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Cookies
        </Typography>
        <Typography>
          In order for us to recognize that you are a return user, we may place a “cookie” onto the
          hard drive of your computer. This will be stored until you choose to remove it. You can
          set up your browser so that it notifies you of cookies and/or can reject this for you.
          However, do be aware that you can still use the website but may be limited in the use of
          some features if you choose to reject the request from us to put the cookie on your
          computers’ hard drive.
        </Typography>
        <Typography sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
          Some cookies are essential to make our site work properly and may already have been set.
          Other cookies help us to improve by giving us insight into how the site is being used. We
          also use cookies to track the efficiency of our marketing channels.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Third Parties
        </Typography>
        <Typography>
          We may sometimes use third parties to assist us with functions in relation to the data
          collected via the website e.g. data analysis or web hosting. These companies may be given
          the data needed in order to complete this function but may not use or hold the information
          for any other purposes than the specific task they were asked to do.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Links
        </Typography>
        <Typography>
          The website contains links to and from other websites. Please note that we are not
          responsible for the Privacy Policies of those websites and suggest you read their Privacy
          Policies as they may differ from ours. We are only responsible for the information that we
          collect on our site.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Security
        </Typography>
        <Typography>
          To ensure that your information is kept safe, we apply certain measures both on and
          offline in order to protect the security of your personal information. We have implemented
          appropriate technical and organisational measures, such as encryption and access controls,
          to protect your personal data from unauthorized access, disclosure, alteration, or
          destruction.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Notification of Changes
        </Typography>
        <Typography>
          Once in a while then we may review the Privacy Policy. If we do so then the amended
          version will be posted here. We will not send out information to say that we have reviewed
          the policy and therefore suggest that you periodically read the Privacy Policy to ensure
          that you are up-to-date with the current version of the policy. The revised Privacy Policy
          will come into effect from the time that it has been posted and therefore means your
          personal data will be used accordingly.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Updating your information
        </Typography>
        <Typography>
          You have the right to access, rectify, or erase any personal data we hold about you. You
          are able to review, modify, and/or change the data contained on our database in relation
          to yourself at any point. As well as being able to change the information that we have on
          you, you can also request that we remove all of your personal information at any time.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Opting-out
        </Typography>
        <Typography>
          If you do not agree with the way in which we retain and use data, as expressed within this
          document, then please do not submit any of your personal information to us. Furthermore,
          if at any point you choose that you would no longer want us to have your personal or
          sensitive information, then you can request that we remove this data from our database by
          submitting your account-deletion request from our website or email to us:
          info@to-makers.com.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          International Data Transfers
        </Typography>
        <Typography sx={{ marginBottom: '3rem' }}>
          As a Belgium domiciled organisation with users from all over the world, we may transfer
          your personal data to countries outside of the European Economic Area (EEA) for the
          purposes of providing our services. We will ensure that any transfer of your personal data
          will be subject to appropriate safeguards, as required by GDPR or other relevant data
          protection laws.
        </Typography>
      </SubParaphs>
    </Container>
  )
}

export default Privacy
