/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
import React, { Fragment, useContext } from 'react'
import MakerInquiry from './MakerInquiry'
import DetailsOne from './DetailsOne'
import DetailsTwo from './DetailsTwo'
import DetailsThree from './DetailsThree'
import WebsiteAndEmail from './WebsiteAndEmail'

import useRegisterContext from '../../hooks/useRegisterContext'

const INDUSTRY_LIMIT = 3

function RegisterSelections() {
  const { curPage, data } = useRegisterContext()
  const display = {
    0: <MakerInquiry />,
    1: <DetailsOne industryLimit={INDUSTRY_LIMIT} />,
    2: <DetailsTwo />,
    3: <DetailsThree />,
    4: <WebsiteAndEmail />,
  }

  return <>{display[curPage]}</>
}

export default RegisterSelections
