import React from 'react'
import Box from '@mui/material/Box'

import ProjectsTable from './ProjectsTable'

function InProgress() {
  return (
    <Box sx={{ width: '100%' }}>
      <ProjectsTable isInProgress />
    </Box>
  )
}

export default InProgress
