/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-quotes */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import CircleIcon from '@mui/icons-material/Circle'

import { COLORS } from '../../../assets'
import { PopupDialog } from '../../../features/components'
import { DocumentChecklist } from '../../../features/my_makerlinked/invest_assistant'

const steps = [
  {
    label: 'Identification of Technology with TOMAKERS (CN Export and CN Import)',
    description: [
      'The first step is to determines whether the technology belongs to the free-trading category, restricted category or ' +
        'prohibited category, and check wether you are eligible to apply for it. ',
      'You’ll also need to know the documents that you’ll have to submit along with your application and fees you’ll have to pay.',
      'If your documents are not in English , you may need to get translations prepared before applying.',
    ],
    isPopup: true,
  },
  {
    label: 'Begin Your Applicaiton',
    description: [
      'Once you are ready to apply, please fill the online application form and arrange your documents as per the checklist. ' +
        'and upload your documents.',
      'According to the Personal Information Protection Law, TOMAKERS must obtain your consent to process your personal data before ' +
        ' proceeding with your application. Please download, print, complete, sign and upload.',
    ],
    links: [
      { path: 'https://www.google.com', title: 'Online Application Form', isExternal: true },
      { path: '/', title: 'Upload Your Documents', isExternal: false },
      { path: '/', title: 'Download the Consent Form', isExternal: false },
    ],
  },
  {
    label: 'Acceptance, Examination, Compliance',
    description: [
      'Acceptance of transaction materials for STEX and examination of completeness and compliance of materials (10 working days)',
    ],
  },
  {
    label: 'Multi-Channel Information Bulletin',
    description: [],
  },
  {
    label: 'Transfer Solicitation',
    description: [
      'Whether interested transferees have been solicited before the expiry of the information dissemination period',
    ],
  },
  {
    label: 'International Transaction',
    description: ['SITC International Summary Transaction'],
  },
]

const CustomizedConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: COLORS.stepCompleteGreen,
      borderLeftWidth: '0.2vw',
      marginLeft: '-0.3rem',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: COLORS.stepCompleteGreen,
      borderLeftWidth: '0.2vw',
      marginLeft: '-0.3rem',
    },
  },
}))

const Container = styled.div`
  width: 70vw;
  position: absolute;
`

function MoreInfor({ children }) {
  return (
    <Box
      sx={{
        mt: '0.5rem',
        mb: '0.5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Typography sx={{ paddingRight: '0.5rem' }}>More Information: </Typography>
      {children}
    </Box>
  )
}

function TechExchangeFlow() {
  const [activeStep, setActiveStep] = useState(0)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false)
  }

  const handleDocCheckList = () => {
    setIsDialogOpen(true)
  }

  return (
    <Container>
      <Box sx={{ paddingTop: '10vh' }}>
        <Stepper
          activeStep={activeStep}
          orientation='vertical'
          connector={<CustomizedConnector />}
          sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: COLORS.stepCompleteGreen, // circle color (COMPLETED)
              fontSize: '1.15rem',
            },
            '& .MuiStepLabel-root .Mui-active': {
              color: COLORS.yellowForDark, // circle color (ACTIVE)
              fontSize: '1.15rem',
            },
            '& .MuiStepLabel-root .Mui-disabled': {
              color: COLORS.inactiveGrey, // circle color (INACTIVE)
              fontSize: '1rem',
            },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
              fill: 'black', // circle's number (ACTIVE)
            },
            '& .MuiStepIcon-root': {
              color: COLORS.inactiveGrey,
            },
          }}
        >
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === steps.length - 1 ? (
                    <Typography variant='caption' sx={{ color: COLORS.inactiveGrey }}>
                      Last step
                    </Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                {step.description.map((sentence) => (
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    <CircleIcon sx={{ mr: '0.5rem', fontSize: '0.6rem', pt: '0.3rem' }} />
                    <Typography>{sentence}</Typography>
                  </Box>
                ))}

                {step.isPopup && (
                  <MoreInfor>
                    <Typography
                      variant='h7'
                      noWrap
                      // component={Link}
                      // to='/'
                      sx={{ textDecoration: 'underline', color: 'white' }}
                      onClick={() => handleDocCheckList()}
                    >
                      Documments
                    </Typography>
                  </MoreInfor>
                )}
                {step.links && (
                  <MoreInfor>
                    {step.links.map((link) => (
                      <Typography
                        variant='h7'
                        noWrap
                        component={Link}
                        to={link.path}
                        sx={{ textDecoration: 'underline', color: 'white', pr: '1rem' }}
                      >
                        {link.title}
                      </Typography>
                    ))}
                  </MoreInfor>
                )}
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button variant='contained' onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                      {index === steps.length - 1 ? 'Finish' : 'Continue'}
                    </Button>
                    <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </Box>
      <PopupDialog
        dialogTitle='Document Checklist'
        handleClose={handleDialogClose}
        isDialogOpen={isDialogOpen}
        actionsName='Close'
        maxWidth='60vw'
      >
        <DocumentChecklist />
      </PopupDialog>
    </Container>
  )
}

export default TechExchangeFlow
