/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { useLocation, Outlet } from 'react-router-dom'
import styled from 'styled-components'

// import { SearchContents } from '../containers'
import { SearchMenu, AmMakerSideBar, SearchContents } from '../../../features/search_page'

const upListItems = [
  {
    id: 0,
    name: 'Category',
    isPopup: true,
    subList: [
      {
        id: 0,
        isCheckBox: true,
        name: 'Angel',
      },
      {
        id: 1,
        isCheckBox: true,
        name: 'Angel Group',
      },
      {
        id: 2,
        isCheckBox: true,
        name: 'Crowdfunding',
      },
      {
        id: 3,
        isCheckBox: true,
        name: 'Incubator',
      },
      {
        id: 4,
        isCheckBox: true,
        name: 'Privaty Equity',
      },
      {
        id: 5,
        isCheckBox: true,
        name: 'VC',
      },
      {
        id: 6,
        isCheckBox: true,
        name: 'Corporate VC',
      },
    ],
  },
  {
    id: 1,
    name: 'Investment Stage',
    isPopup: true,
    subList: [
      {
        id: 0,
        isCheckBox: true,
        name: 'Pre-Seed',
      },
      {
        id: 1,
        isCheckBox: true,
        name: 'Seed',
      },
      {
        id: 2,
        isCheckBox: true,
        name: 'Series A',
      },
      {
        id: 3,
        isCheckBox: true,
        name: 'Series B',
      },
      {
        id: 4,
        isCheckBox: true,
        name: 'Series C',
      },
      {
        id: 5,
        isCheckBox: true,
        name: 'Series D and beyond',
      },
    ],
  },
  {
    id: 2,
    name: 'Investment Range',
    isSubList: true,
  },
  {
    id: 3,
    name: 'Managed Fund',
    isSubList: true,
  },
]

const bottomListItems = [
  {
    id: 0,
    name: 'Search by tag',
  },
  {
    id: 1,
    name: 'Founded',
  },
  {
    id: 2,
    name: 'Location',
  },
]

const Container = styled.div`
  display: flex;
  font-family: var(--font-family);
`

function Search() {
  const location = useLocation()

  return (
    <Container>
      <AmMakerSideBar />
      <SearchMenu upListItems={upListItems} bottomListItems={bottomListItems} />
      <SearchContents routes={location?.state?.name || 'investors'} />
      <Outlet />
    </Container>
  )
}

export default Search
