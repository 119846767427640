/* eslint-disable max-len */
/* eslint-disable jsx-quotes */
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { COLORS } from '../../assets'

function InforCard({ projectData }) {
  return (
    <Card
      sx={{
        width: {
          xs: 300, // for phone
          sm: 330, // for ipad mini
          md: 400, // for ipad air
          lg: 600,
        },
        marginBottom: '2rem',
      }}
    >
      <CardContent>
        <Grid sx={{ mb: '2%' }}>
          <Typography variant='h6' sx={{ color: COLORS.lightOragne }}>
            Project
          </Typography>
          <Typography>{projectData.name}</Typography>
        </Grid>
        <Grid sx={{ mb: '2%' }}>
          <Typography variant='h6' sx={{ color: COLORS.lightOragne }}>
            Description
          </Typography>
          <Typography>{projectData.description}</Typography>
        </Grid>
        <Grid sx={{ mb: '2%' }}>
          <Typography variant='h6' sx={{ color: COLORS.lightOragne }}>
            Tags
          </Typography>
          <Typography>{projectData.tags}</Typography>
        </Grid>
        <Grid sx={{ mb: '2%' }}>
          <Typography variant='h6' sx={{ color: COLORS.lightOragne }}>
            What we need
          </Typography>
          <Typography>{projectData.weNeed}</Typography>
        </Grid>
        <Grid sx={{ mb: '2%' }}>
          <Typography variant='h6' sx={{ color: COLORS.lightOragne }}>
            What we provide
          </Typography>
          <Typography>{projectData.weProvide}</Typography>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default InforCard
