/* eslint-disable object-curly-newline */
import HomeIcon from '@mui/icons-material/HomeOutlined'
import MyMeetingIcon from '@mui/icons-material/Groups'
import FavoriteIcon from '@mui/icons-material/Bookmarks'
import ProfileIcon from '@mui/icons-material/AccountBox'
import OngoingProjectIcon from '@mui/icons-material/AccountTree'
import InvestmentIcon from '@mui/icons-material/Paid'
import ToMakersServiceIcon from '@mui/icons-material/Business'
import PaymentIcon from '@mui/icons-material/Payment'
import LogoutIcon from '@mui/icons-material/Logout'

export default {
  HomeIcon,
  MyMeetingIcon,
  FavoriteIcon,
  ProfileIcon,
  OngoingProjectIcon,
  InvestmentIcon,
  ToMakersServiceIcon,
  PaymentIcon,
  LogoutIcon,
}
