/* eslint-disable nonblock-statement-body-position */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react'
import { ScrollView } from 'react-native'
import styled from 'styled-components'
import { useNavigate, Link } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

import ArticleIcon from '@mui/icons-material/Article'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

import { updateUser } from '../../services/redux/user-slice'
import { Button, PopupDialog, LogoBox } from '../../features/components'
import { COLORS } from '../../assets'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 10vh);
  color: #fff;
  font-family: var(--font-family);
  position: absolute;
  top: 50%;
  /* left: 50%; */
  transform: translate(-50%, -50%);

  // for iphone SE
  @media only screen and (min-width: 320px) {
    left: 50%;
  }

  // for iphone 12 PEO > 390
  @media only screen and (min-width: 390px) {
    left: 50%;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    left: 50%;
  }

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    left: 50%;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    left: 50%;
  }

  @media only screen and (min-width: 1024px) {
    left: 50%;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2xp solid;
  padding: 20px 50px;
  gap: 10px;
`

const TextInfoDiv = styled.div`
  margin-top: 6vh;
  align-content: flex-start;
`

const RedirContainer = styled.div`
  display: flex;
  flex-direction: row;

  // for iphone SE
  @media only screen and (min-width: 320px) {
    flex-direction: column;
  }

  // for iphone 12 PEO > 390
  @media only screen and (min-width: 390px) {
    flex-direction: column;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    flex-direction: column;
  }

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    flex-direction: column;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    flex-direction: column;
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Title = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  margin-right: 1rem;
`

const LikeData = styled.h1`
  font-size: 1.2rem;
  text-align: center;
`

const Input = styled.input`
  width: 100%;
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  border: 1px solid;
  /* height: 3rem; */
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const InfoItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  margin-bottom: 1.5rem;
`

const InfoItemLabel = styled.div`
  color: #fff;
  margin-right: 1vw;
`

const EmailInputDiv = styled.div`
  flex: 1;
`

const OtherInputDiv = styled.div`
  width: 20vw;
`

const StyledButton = styled(Button)`
  width: 10rem;
  font-size: 1rem;
  margin-right: 1.7rem;
`

const WarningSpan = styled.span`
  color: #ed7474;
  font-weight: bold;
  margin-bottom: 1rem;
`

const BottomButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
`

const mockData = {
  category: 'maker',
  makerType: 'designer',
  industry: ['construction & building', 'test'],
  businessModel: ['B2B', 'B2C'],
  professional: ['Architecture', 'Engineer'],
  targetCustomer: ['miner'],
  coreTechnology: ['sensing'],
  numOfPatents: '15',
  website: 'test.com',
  email: 'test@test.com',
}

function MakerProfile() {
  const { currentUser } = useSelector((state) => state.userAuth)
  // const [hasMakerProfile, setHasMakerProfile] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)
  const [isConfirmDialog, setIsConfirmDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [makerData, setMakerData] = useState(null)
  const [dialogTitle, setDialogTitle] = useState('Information')
  const [dialogMessage, setDialogMessage] = useState('')
  const [numOfInterests, setNumOfInterests] = useState(0)
  const [count, setCount] = useState(1)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const getMakerProfileByUerId = async () => {
    if (isLoading || !currentUser?.makerId) return
    try {
      setIsLoading(true)
      // throw new Error(400, 'test')
      const res = await axios.get(`api/maker/find/${currentUser._id}`)

      if (!res?.data) throw new Error(404, 'data does not exist')

      setMakerData(res?.data)
      setIsLoading(false)
    } catch (error) {
      const { response } = error
      const msg =
        response?.status === 401 || response?.status === 403
          ? 'Authentication failed, please login first.'
          : 'Server Error. Please try again later.'

      setDialogTitle('Error')
      setDialogMessage(msg)
      setShowDialog(true)
      setIsLoading(false)
    }
  }

  const getInterestCountByUerId = async () => {
    if (isLoading || !currentUser?.makerId) return
    try {
      setIsLoading(true)
      // throw new Error(400, 'test')
      const res = await axios.get(`api/interest/get-interestor-count/${currentUser._id}`)

      if (!res || res?.data === null || res?.data === undefined) {
        throw new Error(404, 'data does not exist')
      }

      setNumOfInterests(res?.data)
      setIsLoading(false)
    } catch (error) {
      const { response } = error
      setDialogTitle('Error')
      setDialogMessage('Server is busy. Please try again later.')
      setShowDialog(true)
      setIsLoading(false)
    }
  }

  const hasMakerProfile = () => !!currentUser?.makerId

  useEffect(() => {
    // getMakerProfileByUerId()
    if (hasMakerProfile) {
      // TODO: itis only for test
      // setMakerData(mockData)

      // TODO: in prod, get maker data from database getMakerProfileByUerId
      getMakerProfileByUerId()
      getInterestCountByUerId()
    }
  }, [])

  const deleteMakerProfileById = async () => {
    if (isDeleting) return

    setIsDeleting(true)
    try {
      await axios.delete(`/api/maker/${currentUser?._id}`)

      // update redux
      const changedUser = { ...currentUser, makerId: null }
      dispatch(updateUser(changedUser))

      setIsDeleting(false)
      navigate('/')
      throw new Error(400, 'test')
    } catch (error) {
      const { response } = error

      const msg =
        response?.status === 401 || response?.status === 403
          ? 'Authentication failed, please login first.'
          : 'Server Error. Please try again later.'

      setDialogTitle('Error')
      setDialogMessage(msg)
      setShowDialog(true)

      setIsDeleting(false)
    }
  }

  useEffect(() => {
    if (isConfirm) {
      deleteMakerProfileById()
    }
  }, [isConfirm])

  if (hasMakerProfile() && !makerData) {
    return (
      <Container>
        <LogoBox path='/' />
        <Title>My Maker Profile</Title>
        <Wrapper>
          <CircularProgress sx={{ color: COLORS.buttonLeaveOrange }} />
        </Wrapper>
      </Container>
    )
  }

  const capitaliseFstLetter = (word) => word.charAt(0).toUpperCase() + word.slice(1)

  const handleInfoItemClick = (key) => {
    setDialogTitle('Information')
    setDialogMessage(`${capitaliseFstLetter([key].toString())}: ${makerData[key].toString()}`)
    setShowDialog(true)
  }

  const handleUpdateProfileClick = () => {
    navigate('/persona')
  }

  const handleDeleteProfileClick = async (e) => {
    e.preventDefault()
    setIsConfirmDialog(true)
    setDialogMessage(
      'By deleting your maker profile, your maker profile will be permently deleted from our ' +
        'database. And your account at MAKERLinked will be kept in the database. If you want ' +
        "to delete your account as well. Please choose to delete your personal info at 'My " +
        "Personal Info ' page, Do you still want to proceed?",
    )
    setShowDialog(true)
  }

  const handleConfirm = async () => {
    setIsConfirm(true)
    setShowDialog(false)
  }

  const handleCancel = async () => {
    setIsConfirm(false)
    setShowDialog(false)
  }

  return (
    <Container>
      <LogoBox path='/' />
      {hasMakerProfile() && makerData ? (
        <>
          <TitleDiv>
            <Title>My Maker Profile</Title>
            <LikeData> ({numOfInterests} people would like to contact me )</LikeData>
          </TitleDiv>
          <ScrollView style={{ flex: 1 }}>
            <Wrapper>
              <InfoContainer>
                {Object.keys(makerData).map((key) => (
                  <InfoItemContainer>
                    <InfoItemLabel>{`${capitaliseFstLetter([key].toString())}: `}</InfoItemLabel>
                    <EmailInputDiv>
                      <Input placeholder={makerData[key] || 'N/A'} name={`${[key]}`} disabled />
                    </EmailInputDiv>
                    {/* TODO: Add a clickable icon, so that the user click and see item details  */}
                    <IconButton
                      color='secondary'
                      aria-label='details'
                      sx={{ marginLeft: '1.5rem' }}
                      onClick={() => handleInfoItemClick(key)}
                    >
                      <ArticleIcon />
                    </IconButton>
                  </InfoItemContainer>
                ))}
              </InfoContainer>
            </Wrapper>
          </ScrollView>
          {isDeleting ? (
            <CircularProgress sx={{ color: COLORS.buttonLeaveOrange, marginTop: '1.5rem' }} />
          ) : (
            <BottomButtonsDiv>
              <StyledButton
                enterColor={COLORS.buttonEnterOrange}
                leaveColor={COLORS.buttonLeaveOrange}
                path=''
                text='Update My Profile'
                handleClick={handleUpdateProfileClick}
              />
              <StyledButton
                enterColor={COLORS.buttonEnterOrange}
                leaveColor={COLORS.buttonLeaveOrange}
                path=''
                text='Delete My Profile'
                handleClick={handleDeleteProfileClick}
              />
            </BottomButtonsDiv>
          )}
          <PopupDialog
            dialogTitle={dialogTitle}
            handleClose={handleDialogClose}
            isDialogOpen={showDialog}
            isConfirmDialog={isConfirmDialog}
            actionsName='Close'
            maxWidth='45vw'
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
          >
            <Typography>{dialogMessage}</Typography>
            {/* <Typography>Test</Typography> */}
          </PopupDialog>
        </>
      ) : (
        <Wrapper>
          <TextInfoDiv>
            <Typography>You have not have Maker profile yet.</Typography>
            <RedirContainer>
              <Typography>Please click the following link to set your Maker profile</Typography>
              <Link
                to='/persona'
                style={{ color: COLORS.buttonLeaveOrange, marginLeft: '0.5rem', marginTop: 2 }}
              >
                Add My Maker Profile
              </Link>
            </RedirContainer>
          </TextInfoDiv>
        </Wrapper>
      )}
    </Container>
  )
}

export default MakerProfile
