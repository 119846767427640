/* eslint-disable no-unused-vars */
import React from 'react'
import styled from 'styled-components'
import { Outlet, useLocation } from 'react-router-dom'
import { MkLinkedAcctMenu, MkLinkedAppBar } from '../features/my_makerlinked'
import { Favorites } from './mklinked_acct'

const drawerWidth = 240
const collapseWidth = 70

const Container = styled.div`
  display: 'flex';
`

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 768px) {
    height: 0vh;
    width: 50vw;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    height: 0vh;
    width: 50vw;
  }

  // for desktop
  @media only screen and (min-width: 1024px) {
    height: 0vh;
    width: 60vw;
  }
`

const OptionDiv = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* width: 20vw; */
`

const MKLinkedAcct = function MKLinkedAcct() {
  const location = useLocation()

  return (
    <Container>
      <MkLinkedAcctMenu drawerWidth={(drawerWidth, collapseWidth)} />
      <ContentDiv>
        <MkLinkedAppBar
          drawerWidth={(drawerWidth, collapseWidth)}
          title={location?.state?.name ?? 'Home'}
        />
        {/* <SearchContents routes={location?.state?.name} /> */}
        {/* <OptionDiv>
          <MkLinkedHome />
        </OptionDiv> */}
      </ContentDiv>
      <Outlet />
    </Container>
  )
}

export default MKLinkedAcct
