/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'

import { BulletPointDoc } from '../components'
import { COLORS } from '../../assets'
import useRegisterContext from '../../hooks/useRegisterContext'

const targetCustomerList = [
  {
    id: 1,
    name: 'consumer',
    alias: 'Consumer',
  },
  {
    id: 2,
    name: 'miner',
    alias: 'Miner',
  },
  {
    id: 3,
    name: 'producer',
    alias: 'Producer',
  },
  {
    id: 4,
    name: 'processor',
    alias: 'Processor (Subcontractor)',
  },
  {
    id: 5,
    name: 'distributor',
    alias: 'Distributor',
  },
  {
    id: 6,
    name: 'intermediary business',
    alias: 'Intermediary Business',
  },
]

const coreTechList = [
  {
    id: 1,
    name: 'materials',
    alias: 'Materials',
  },
  {
    id: 2,
    name: 'machinery',
    alias: 'Machinery',
  },
  {
    id: 3,
    name: 'sensing',
    alias: 'Sensing',
  },
  {
    id: 4,
    name: 'communication',
    alias: 'Communication',
  },
  {
    id: 5,
    name: 'biologicals',
    alias: 'Biologicals',
  },
  {
    id: 6,
    name: 'information technology',
    alias: 'Information Technology',
  },
  {
    id: 7,
    name: 'artificial intelligence',
    alias: 'Artificial Intelligence',
  },
  {
    id: 8,
    name: 'electronics',
    alias: 'Electronics',
  },
  {
    id: 9,
    name: 'interfaces',
    alias: 'Interfaces',
  },
]

const prefessionals = [
  {
    id: 1,
    occupation: 'Architecture',
  },
  {
    id: 2,
    occupation: 'Graphic Designer',
  },
  {
    id: 3,
    occupation: 'UI Designer',
  },
  {
    id: 4,
    occupation: 'Interior Designer',
  },
  {
    id: 5,
    occupation: 'Industrial Designer',
  },
  {
    id: 6,
    occupation: 'Web Designer',
  },
  {
    id: 7,
    occupation: 'Software Designer',
  },
  {
    id: 8,
    occupation: 'Branding',
  },
]

const OptionList = styled.ul`
  /* gap: 10px; */
  margin-top: -0.3rem;
  margin-right: 1.2rem;
  margin-left: -1rem;
  list-style-type: none;
`

const OptionItem = styled.li`
  /* gap: 10px; */
  padding-top: 1rem;
`

const Input = styled.input`
  border: none;
  background-color: transparent;
  outline: none;
  color: #aaaaaa;
`

const Label = styled.span`
  font-size: 1rem;
  display: inline-block;
`

const QuestionsContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`

const NumOfPatentText = styled.div``

function DetailsThree() {
  const { data, setData } = useRegisterContext()
  const [coreTechs, setCoreTechs] = useState(data?.coreTechnology)
  const [numOfPatent, setNumofPatent] = useState(data?.numOfPatents)

  // const [data, setData] = useState({
  //   category: '',
  //   type: '',
  //   industry: [],
  //   businessModel: [],
  //   professional: [],
  //   targetCustomer: [],
  //   coreTechnology: [],
  //   numOfPatents: 0,
  //   website: '',
  //   email: '',
  // })

  const handleCoreTechClick = (e) => {
    const { value } = e.target

    if (coreTechs.includes(value)) {
      const newData = coreTechs.filter((cat) => cat !== value)
      setCoreTechs(newData)
      setData((prev) => ({ ...prev, coreTechnology: newData }))
    } else {
      setCoreTechs((prev) => [...prev, value])
      setData((prev) => ({ ...prev, coreTechnology: [...data.coreTechnology, value] }))
    }
  }

  const hideCoreTechQuestion = () =>
    data.category === 'makerRequester' && data.makerType === 'social organisation'

  const handlePatentInput = (e) => {
    const { value } = e.target
    setNumofPatent(value)
    setData((prev) => ({ ...prev, numOfPatents: value }))
  }

  const showPatentQuestion = () =>
    data.category === 'maker' && (data.makerType === 'engineer' || data.makerType === 'ip holder')

  return (
    <div>
      {hideCoreTechQuestion() ? (
        <></>
      ) : (
        <>
          <QuestionsContainer>
            <BulletPointDoc text='Core Technology (required)' color={COLORS.textHighlightRed} />
          </QuestionsContainer>
          <OptionList>
            {coreTechList.map((item) => (
              <OptionItem key={item.id}>
                <Label>
                  <Input
                    type='checkbox'
                    value={item.name}
                    checked={coreTechs.includes(item.name)}
                    onChange={() => {}}
                    onClick={handleCoreTechClick}
                  />
                  {item.alias}
                </Label>
              </OptionItem>
            ))}
          </OptionList>
        </>
      )}
      {showPatentQuestion() ? (
        <>
          <QuestionsContainer>
            <BulletPointDoc
              text='How many patents you got / jointly have with partners (required)'
              color={COLORS.textHighlightRed}
            />
          </QuestionsContainer>
          <TextField
            id='standard-basic'
            InputLabelProps={{ shrink: false }}
            label={numOfPatent === '' ? 'Fill a number' : numOfPatent}
            variant='standard'
            sx={{ marginLeft: '2rem', mt: '-1rem' }}
            onChange={handlePatentInput}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default DetailsThree
