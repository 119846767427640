/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-quotes */
import React, { useState } from 'react'
import styled from 'styled-components'

import Box from '@mui/material/Box'
import { Typography } from '@mui/material'

import { ProjectMgtInfoGrid } from '../../features/my_makerlinked/project_mgt'
import { PopupDialog } from '../../features/components'

const mockUserData = [
  {
    id: 'u1',
    username: 'HWS',
  },
  {
    id: 'u2',
    username: 'TOMAKERS',
  },
  {
    id: 'u3',
    username: 'Goldwind',
  },
]

const mockEventsData = [
  {
    id: '1a',
    title: 'First Online Meeting',
    attendees: ['u1', 'u2', 'u3'],
    status: 'completed',
  },
  {
    id: '2a',
    title: 'Company Visit - Second Meeting',
    attendees: ['u1', 'u2', 'u3'],
    status: 'completed',
  },
  {
    id: '3a',
    title: 'NDA Awaiting for Signature',
    attendees: ['u1', 'u3'],
    status: 'ongoing',
  },
  {
    id: '4a',
    title: 'Serivce Agreement for Future Cooperation',
    attendees: ['u1', 'u2'],
    status: 'ongoing',
  },
  {
    id: '5a',
    title: 'Technical Parameter Test',
    attendees: ['u1', 'u3'],
    status: 'future',
  },
  {
    id: '6a',
    title: 'Clarifies Roles and Responsibilities',
    attendees: ['u1', 'u3'],
    status: 'future',
  },
  {
    id: '7a',
    title: 'Setup User Testing to Get Feedback about the Current Project',
    attendees: ['u1', 'u2', 'u3'],
    status: 'future',
  },
]

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: calc(100vh - 5vh); */
  height: auto;
  width: 80vw;
  color: #fff;
  /* font-family: var(--font-family); */
  position: absolute;
  transform: translate(-50%, -50%);

  // for iphone SE
  @media only screen and (min-width: 320px) {
    flex-direction: column;
    top: 55%;
    left: 55%;
  }

  // for iphone 12 PEO > 390
  /* @media only screen and (min-width: 390px) {
    flex-direction: column;
    top: 50%;
    left: 55%;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    flex-direction: column;
    top: 50%;
    left: 55%;
  } */

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    flex-direction: row;
    /* top: 50%; */
    left: 55%;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    flex-direction: row;
    /* top: 50%; */

    left: 55%;
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    left: 59%;
  }
`

const eventInfo = (gridTitle, eventStatus, handleDialogOpen) => (
  <Box
    bgcolor='white'
    sx={{
      width: '25vw',
      height: '80vh',
      mr: '1rem',
      borderRadius: 2,
      overflow: 'hidden',
      overflowY: 'scroll',
    }}
  >
    <Typography variant='h6' color='black' sx={{ ml: '0.5rem', mt: '0.5rem' }}>
      {gridTitle}
    </Typography>
    {mockEventsData
      .filter((event) => event.status === eventStatus)
      .map((event) => (
        <ProjectMgtInfoGrid
          eventTitle={event.title}
          attendees={event.attendees.map(
            (attendee) => mockUserData.filter((user) => user.id === attendee)[0].username,
          )}
          hanldeGridClick={handleDialogOpen}
        />
      ))}
  </Box>
)

const test = () => <Typography sx={{ color: 'black' }}>Text</Typography>

function ProjMgtBoard() {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleDialogClose = () => {
    setIsDialogOpen(false)
  }

  const handleDialogOpen = () => {
    setIsDialogOpen(true)
  }

  return (
    <Container>
      {eventInfo('Completed', 'completed', handleDialogOpen)}
      {eventInfo('Ongoing', 'ongoing', handleDialogOpen)}
      {eventInfo('TO-DO List', 'future', handleDialogOpen)}
      <PopupDialog
        dialogTitle='Event Details'
        handleClose={handleDialogClose}
        isDialogOpen={isDialogOpen}
        actionsName='Close'
        maxWidth='60vw'
      >
        <Typography>Test</Typography>
      </PopupDialog>
    </Container>
  )
}

export default ProjMgtBoard
