/* eslint-disable jsx-quotes */
import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { COLORS } from '../../../assets'

function AttenderProfileCard({ attender, avatar, attenderId }) {
  return (
    <Card
      sx={{
        marginRight: '3%',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '18vw',
        backgroundColor: '#F2F2F2',
      }}
    >
      <Avatar
        sx={{
          // bgcolor: deepPurple[500],
          bgcolor:
            attenderId % 2 === 0
              ? COLORS.meetingProfileCardAvatarColor1[500]
              : COLORS.meetingProfileCardAvatarColor2[500],
          mr: '3%',
          height: '7.5vh',
          width: '4vw',
        }}
      >
        {avatar}
      </Avatar>
      <Grid sx={{ display: 'flex', flexDirection: 'column', mr: 'auto' }}>
        <Typography sx={{ fontSize: 14 }} color='black' gutterBottom>
          {attender.name}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
          {attender.title}
        </Typography>
      </Grid>
      <CheckCircleIcon sx={{ color: '#0099CB' }} />
    </Card>
  )
}

export default AttenderProfileCard
