/* eslint-disable jsx-quotes */
import React, { useState } from 'react'
import { ScrollView } from 'react-native'
import styled from 'styled-components'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { isEmail } from 'validator'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import { MakerLinkedLogo } from '../../static/images'
import { Button, PopupDialog } from '../../features/components'
import { COLORS } from '../../assets'
import { authFailure, authStart, authComplete } from '../../services/redux/user-slice'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 10vh);
  color: #fff;
  font-family: var(--font-family);
  position: absolute;
  top: 50%;
  /* left: 50%; */
  transform: translate(-50%, -50%);

  // for iphone SE
  @media only screen and (min-width: 320px) {
    left: 50%;
  }

  // for iphone 12 PEO > 390
  @media only screen and (min-width: 390px) {
    left: 50%;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    left: 50%;
  }

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    left: 50%;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    left: 50%;
  }

  @media only screen and (min-width: 1024px) {
    left: 50%;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 2xp solid; */
  padding: 1rem;
  gap: 1.3rem;
`

const Title = styled.h1`
  font-size: 1.5rem;
  text-align: center;
`

const WarningSpan = styled.span`
  color: #ed7474;
  font-weight: bold;
`

const Input = styled.input`
  width: 100%;
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  border: 1px solid;
  /* height: 3rem; */
`

const StyledButton = styled(Button)`
  width: 20rem;
  font-size: 1.2rem;
`

const SuccessInfoDiv = styled.div`
  width: auto;
  margin-top: 5vh;
`

function ResetPwdRequest() {
  const { loading } = useSelector((state) => state.userAuth)
  const [email, setEmail] = useState('')
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('Information')
  const [dialogMessage, setDialogMessage] = useState('')
  const [emailError, setEmailError] = useState('')

  const dispatch = useDispatch()

  const onEmailChange = (e) => {
    e.preventDefault()
    const { value } = e.target

    setEmailError('')
    setEmail(value)
  }

  const validateEmail = (e) => {
    e.preventDefault()
    const { value } = e.target

    if (!value) {
      setEmailError('Please input your email address')
    } else if (!isEmail(value)) {
      setEmailError('Please input an valid email address')
    }
  }

  const handleSendRequestClick = async (e) => {
    e.preventDefault()

    if (!email) return

    dispatch(authStart())
    try {
      // throw new Error(401, 'test')
      await axios.post('api/auth/request-reset-pwd', { email })
      setIsEmailSent(true)
      dispatch(authComplete())
    } catch (error) {
      setDialogTitle('Error')
      setDialogMessage('Server error. Please try again later.')
      setShowDialog(true)
      dispatch(authFailure())
    }
  }

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  return (
    <Container>
      <ScrollView style={{ flex: 1 }}>
        <Box
          component='img'
          sx={{
            marginTop: {
              xs: '1%',
              sm: '1%',
              md: '1%',
              lg: '1%',
            }, // need to change according to diff size
            height: {
              xs: '5vh',
              sm: '4vh',
              md: '5vh',
              lg: '8vh',
            },
            width: {
              xs: '38vw',
              sm: '27vw',
              md: '27vw',
              lg: '27vw',
            },
            alignSelf: 'center',
          }}
          alt='MAKERLINKED Logo'
          src={MakerLinkedLogo}
        />

        {isEmailSent ? (
          <SuccessInfoDiv>
            <Typography>
              An email for resetting password has been sent to the provided email address, please
              following the intructions in the email to reset your password.
            </Typography>
          </SuccessInfoDiv>
        ) : (
          <Wrapper>
            <Title>Please confirm the email address of your account</Title>
            <Input
              placeholder='email (required)'
              name='email'
              onChange={onEmailChange}
              onBlur={validateEmail}
            />
            {emailError && <WarningSpan>{emailError}</WarningSpan>}
            {loading ? (
              <CircularProgress sx={{ color: COLORS.buttonLeaveOrange }} />
            ) : (
              <StyledButton
                enterColor={COLORS.buttonEnterOrange}
                leaveColor={COLORS.buttonLeaveOrange}
                path=''
                text='Send Password Reset Email'
                handleClick={handleSendRequestClick}
              />
            )}
          </Wrapper>
        )}
        <PopupDialog
          dialogTitle={dialogTitle}
          handleClose={handleDialogClose}
          isDialogOpen={showDialog}
          actionsName='Close'
          maxWidth='30vw'
        >
          <Typography>{dialogMessage}</Typography>
        </PopupDialog>
      </ScrollView>
    </Container>
  )
}

export default ResetPwdRequest
