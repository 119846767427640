/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React from 'react'
import styled from 'styled-components'

import Typography from '@mui/material/Typography'
import { LogoBox } from '../../features/components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  font-family: var(--font-family);
  position: absolute;
  /* left: 50%; */
  top: 10%;

  // for iphone SE
  @media only screen and (min-width: 320px) {
    left: 10%;
    right: 10%;
  }

  // for iphone 12 PEO > 390
  @media only screen and (min-width: 390px) {
    left: 10%;
    right: 10%;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    left: 10%;
    right: 10%;
  }

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    left: 10%;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    left: 10%;
    right: 10%;
  }

  @media only screen and (min-width: 1024px) {
    left: 10%;
    right: 10%;
  }
`

const LogoContainer = styled.div`
  margin-top: 5vh;
  margin-bottom: 6vh;
`

const SubParaphs = styled.div`
  width: auto;
  margin-top: 1.5rem;
`

function Terms() {
  return (
    <Container>
      <LogoContainer>
        <LogoBox path='/' />
      </LogoContainer>
      <Typography variant='h4' sx={{ marginBottom: '5vh' }}>
        Terms of Service
      </Typography>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Introduction
        </Typography>
        <Typography>
          Welcome to our website (the &quot;Website&quot;), which is provided to you by ToMakers
          (referred to as &quot;we,&quot; &quot;us,&quot; or &quot;our&quot; in these Terms). The
          information and services available on the Website are subject to the following terms and
          conditions (the &quot;Terms&quot;). By using the Website, you confirm that you have read,
          understood, and agreed to be bound by these Terms without limitation or qualification. If
          you disagree with any part of these Terms, please immediately stop using the Website.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Privacy
        </Typography>
        <Typography>
          Please ensure that you have read our Privacy policy, which includes information about data
          protection and compliance with the General Data Protection Regulation (GDPR).
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Intellectual property
        </Typography>
        <Typography>
          All content on our Website, including (but not limited to) text, graphics, logos, images,
          video clips, and the compilation thereof (the &quot;Content&quot;), is our property. By
          using our Website, you acknowledge that the names &quot;ToMakers&quot; and
          &quot;MAKERLinked&quot;, and the ToMakers and MakerLinked branding shall remain our sole
          and exclusive property, and nothing in these Terms shall be construed to confer upon you
          any rights in respect of the name and/or the ToMakers and MAKERLinked branding. The
          trademarks, logos, and service marks displayed on the Website (collectively, the
          &quot;Trademarks&quot;) are our registered and unregistered marks. All Trademarks not
          owned by us that appear on the Website are the property of their respective owners. Except
          as set out in the limited license clause below or as required under applicable law,
          neither the Content, the Trademarks, nor any other portion of the Website may be used,
          reproduced, duplicated, copied, sold, resold, accessed, modified, or otherwise exploited,
          in full or in part, for any purpose without our prior written consent.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Limited license
        </Typography>
        <Typography>
          We will make our Website available to you on the terms and conditions set out in these
          Terms. We hereby grant you a personal, non-exclusive, non-transferable, revocable, limited
          license (without the right to sublicense) to access and use the Website for business
          purposes only, subject to the limitations set forth below.
        </Typography>
        <Typography sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
          You agree that the limited license does not include the right to:
        </Typography>
        <Typography sx={{ marginLeft: '2rem' }}>
          a. modify or download the Website or its contents (except caching or as necessary to view
          content);
        </Typography>
        <Typography sx={{ marginLeft: '2rem' }}>
          b. copy, reproduce, adapt, or create any derivative works based upon either the Website or
          its Content;
        </Typography>
        <Typography sx={{ marginLeft: '2rem' }}>
          c. collect any information for the benefit of another party;
        </Typography>
        <Typography sx={{ marginLeft: '2rem' }}>
          d. decompile, disassemble, reverse compile, reverse assemble, reverse translate, or
          otherwise reverse engineer any part of the Website;
        </Typography>
        <Typography sx={{ marginLeft: '2rem' }}>
          e. dcopy, store, host, transmit, send, use, publish, or distribute any material that
          consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke
          logger, rootkit, or other malicious or destructive computer software or code;
        </Typography>
        <Typography sx={{ marginLeft: '2rem' }}>
          f. use any meta-tags or any other &quot;hidden text&quot; utilizing our name or the
          Trademarks without our express written consent; or
        </Typography>
        <Typography sx={{ marginLeft: '2rem' }}>
          g. harvest or scrape any content from the Website or take any other action that may impose
          an unreasonable burden or load on our infrastructure.
        </Typography>
        <Typography sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
          You are granted a limited, revocable, and non-exclusive right to create a hyperlink to any
          public page of the Website for personal, academic or commercial use. A website that links
          to our Website:
        </Typography>
        <Typography sx={{ marginLeft: '2rem' }}>
          a. may link to, but not replicate, our Content;
        </Typography>
        <Typography sx={{ marginLeft: '2rem' }}>
          b. may not misrepresent its relationship with us, or suggest that we are endorsing the
          website or its services or products;
        </Typography>
        <Typography sx={{ marginLeft: '2rem' }}>
          c. may not contain content that could be construed as distasteful, obscene, offensive or
          controversial, and may contain only content appropriate for all ages;
        </Typography>
        <Typography sx={{ marginLeft: '2rem' }}>
          d. may not portray us or our products or services, in a false, misleading, derogatory, or
          otherwise offensive or objectionable manner, or associate us with undesirable products,
          services, or opinions; and
        </Typography>
        <Typography sx={{ marginLeft: '2rem' }}>
          e. may not use any Trademark without express written permission.
        </Typography>
        <Typography sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
          We reserve the right to monitor any pages containing such hyperlinks to check that you are
          complying with this license and we may, in our sole discretion, request that you remove
          any link to the Website, and upon receipt of such request, you shall immediately remove
          such link.
        </Typography>
        <Typography>
          Any unauthorized use by you of the Website, or breach of these Terms, terminates the
          limited license set forth in this clause without prejudice to any other remedy provided by
          applicable law.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Third party links
        </Typography>
        <Typography>
          Our Website includes, or may include, hyperlinks to other websites owned and operated by
          third parties. These links are not recommendations. We have no control over the content of
          third party websites and we accept no responsibility for them or for any loss or damage
          that may arise from your use of them.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Representations and Warranties; Limitation of Liability
        </Typography>
        <Typography>
          You understand and agree that we shall not be liable for any direct, indirect, incidental,
          special, consequential, or exemplary damages or losses, including, but not limited to, any
          loss of profit, loss of goodwill, loss of business reputation, loss of data, cost of
          procurement of substitute services or other intangible loss, in relation to, or resulting
          from the use of this Website or the use by anyone else of this Website.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Indemnification
        </Typography>
        <Typography sx={{ marginBottom: '1rem' }}>
          You agree to indemnify and hold harmless ToMakers and MAEKRLinked, its affiliates,
          officers, employees, representatives, and agents from any and all claims, actions,
          damages, liabilities, costs, and expenses (including, but not limited to, reasonable
          attorneys&lsquo; fees and all related costs and expenses) arising from or relating to:
        </Typography>
        <Typography sx={{ marginLeft: '2rem' }}>
          a. your use of the Website and its Content;
        </Typography>
        <Typography sx={{ marginLeft: '2rem' }}>b. any services you provide;</Typography>
        <Typography sx={{ marginLeft: '2rem' }}>c. Content provided by you; and</Typography>
        <Typography sx={{ marginLeft: '2rem' }}>
          d. violation of these Terms by you, or anyone using your computer or account.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Changes to terms
        </Typography>
        <Typography>
          We reserve the right, at any time, to amend, alter, modify, or change any part of these
          Terms without any prior notice. We will post updated versions of the Terms on our Website,
          which will apply from the date of publication on our Website. Your use of this Website
          constitutes your agreement to the most recent version of the Terms.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Changes to the website
        </Typography>
        <Typography>
          We reserve the right to modify or discontinue this Website (or any portion of this
          Website), temporarily or permanently, with or without notice to you, and we are not
          obligated to support or update this Website.
        </Typography>
        <Typography sx={{ marginTop: '1rem' }}>
          We shall not be liable to you or any third party if we exercise our right to modify or
          discontinue this Website (or any portion of this Website). Unless explicitly stated
          otherwise, any new features that augment or enhance the current Website shall be subject
          to these Terms.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Eligibility
        </Typography>
        <Typography>
          The Website is available only to legal entities and persons who are at least eighteen (18)
          years old and are otherwise capable of forming legally binding contracts under applicable
          law. To fully utilize the services on this Website, you need to register your details with
          us and create an account. You are responsible for ensuring the information provided to us
          is true, accurate, complete, and up-to-date. We reserve the right to refuse service or
          registration, terminate accounts, or remove or edit content for any reason, in our sole
          discretion without prior notice.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Account profile
        </Typography>
        <Typography>
          We reserve the right to validate your identity and account information at any time. You
          are responsible for ensuring and maintaining the secrecy and security of your account
          password and are responsible for any activities that occur on the Website under your
          account. You must notify us at info@outsized.com immediately if you suspect that your
          password has been lost or stolen.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Feedback
        </Typography>
        <Typography>
          We welcome feedback or enquiries regarding the services you use or would like to use. If
          you would like to send us feedback, please email us on info@outsized.com You hereby accept
          that any enquiries, feedback, suggestions, ideas or other information you provide us
          (collectively &quot;Feedback&quot;) will be treated as non-proprietary and
          non-confidential. Subject to the terms of our Privacy policy, by sending us any Feedback
          you hereby grant us a non-exclusive, royalty-free, perpetual, transferable, irrevocable,
          and fully sub-licensable right to use, reproduce, modify, adapt, publish, sell, assign,
          translate, create derivative works from, distribute, and display any Feedback in any form,
          media, or technology, whether now known or hereafter developed, alone or as part of other
          works. You also acknowledge that we may use your Feedback, and any ideas, concepts or
          know-how contained therein, for any purpose.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          General
        </Typography>
        <Typography>
          Nothing contained in these Terms shall be interpreted as establishing a joint venture or
          partnership between the parties. A failure to enforce or to require the performance, at
          any time, of any of the provisions of these Terms shall not be construed to be a waiver of
          such provision and shall not affect either the validity of these Terms, or any part
          thereof, or the right of any party to enforce the provisions of these Terms. If any
          provision of these Terms is found to be unenforceable or invalid under any applicable law,
          such unenforceability or invalidity shall not render these Terms unenforceable or invalid,
          and such provisions shall be deleted without affecting the remaining provisions of these
          Terms. You may not assign these Terms or any of your rights or obligations hereunder
          without our prior written consent. We may freely assign these Terms.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Governing Law and Jurisdiction
        </Typography>
        <Typography>
          These Terms shall be governed by and construed in accordance with the laws of the
          jurisdiction in the Kingdom of Belgium, without regard to its conflict of law provisions.
          You agree that any legal action or proceeding between you and us for any purpose
          concerning these Terms or the parties&lsquo; obligations hereunder shall be brought
          exclusively in a court of competent jurisdiction sitting in the jurisdiction in which
          Outsized Ltd is registered. Any cause of action or claim you may have with respect to
          these Terms must be commenced within one (1) year after the claim or cause of action
          arises or such claim or cause of action is barred.
        </Typography>
      </SubParaphs>
      <SubParaphs>
        <Typography variant='h5' sx={{ marginBottom: '1.5rem' }}>
          Contact Information
        </Typography>
        <Typography>
          If you have any questions about these Terms, the practices of this Website, or your
          dealings with this Website, please contact us at:
        </Typography>
        <Typography>info@to-makers.com</Typography>
        <Typography sx={{ marginBottom: '3rem' }}>
          Please note that the content of this page can change without prior notice. This Terms of
          Service was last updated on the date specified at the beginning of this document.
        </Typography>
      </SubParaphs>
    </Container>
  )
}

export default Terms
