import React from 'react'
import styled from 'styled-components'

import { ProfileCard, InforCard } from '../../../features/profile_card'
import { NeedMakerSideBar } from '../../../features/search_page'
import projectLists from '../../../static/data_for_test/projectList.json'

const Container = styled.div`
  font-family: var(--font-family);
`

const ContentDiv = styled.div`
  display: flex;
  /* align-items: center; */
  /* min-width: 50vh; */
  justify-content: center;
  height: calc(100vh - 5vh);
  /* min-height: 100vh; */
  color: #fff;
  position: absolute;
  transform: translate(-50%, -50%);

  // for iphone SE
  @media only screen and (min-width: 320px) {
    flex-direction: column;
    top: 'auto';
    left: 60%;
  }

  // for iphone 12 PEO > 390
  @media only screen and (min-width: 390px) {
    flex-direction: column;
    top: 'auto';
    left: 55%;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    flex-direction: column;
    top: 'auto';
    left: 55%;
  }

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    flex-direction: row;
    top: 53%;
    left: 55%;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    flex-direction: row;
    top: 53%;
    left: 55%;
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    top: 50%;
    left: 58%;
  }
`

// const size = {
//   mobile: "320px",
//   tablet: "768px",
//   laptop: "1024px",
//   desktop: "2560px",
// }
const ProfileCardDiv = styled.div`
  margin-right: 2rem;
  margin-bottom: 2rem;
`
const InforDiv = styled.div``

function Profile() {
  return (
    <Container>
      <NeedMakerSideBar />
      <ContentDiv>
        <ProfileCardDiv>
          <ProfileCard />
        </ProfileCardDiv>
        <InforDiv>
          {projectLists.map((project) => (
            <InforCard projectData={project} />
          ))}
          {/* <InforCard />
          <InforCard />
          <InforCard />
          <InforCard /> */}
        </InforDiv>
      </ContentDiv>
    </Container>
  )
}

export default Profile
