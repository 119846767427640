/* eslint-disable jsx-quotes */
import React from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'

import { MakerLinkedLogo } from '../../static/images'

function LogoBox({ path }) {
  const navigate = useNavigate()

  return (
    <Box
      component='img'
      sx={{
        cursor: 'pointer',
        marginTop: {
          xs: '1%',
          sm: '1%',
          md: '1%',
          lg: '1%',
        }, // need to change according to diff size
        height: {
          xs: '5vh',
          sm: '4vh',
          md: '5vh',
          lg: '8vh',
        },
        width: {
          xs: '38vw',
          sm: '27vw',
          md: '27vw',
          lg: '27vw',
        },
        alignSelf: 'center',
      }}
      alt='MAKERLINKED Logo'
      src={MakerLinkedLogo}
      onClick={() => navigate(path)}
    />
  )
}

export default LogoBox
