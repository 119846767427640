/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-quotes */
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import styled from 'styled-components'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

import { normalizeBigNumber } from '../../../utils/number-transformer'
import { PopupDialog } from '../../components'
import { COLORS } from '../../../assets'
import SearchDetails from '../dialog_contents/SearchDetails'

const needMakerMockRawDataV1 = [
  {
    name: 'aaa',
    userId: '65c5b1a7dab92f6305fb4aee',
    category: 'maker',
    makerType: 'startup',
    industry: [],
    businessModel: [],
    professional: ['test'],
    targetCustomer: [],
    coreTechnology: [],
    numOfPatents: '',
    website: 'test-website',
    email: 'test@xxx.com',
  },
  {
    name: 'eee',
    userId: '65d4be335fe92813ca03688d',
    category: 'maker',
    makerType: 'startup',
    industry: ['resources & environment', 'construction & building'],
    businessModel: ['B2B', 'B2C'],
    professional: [],
    targetCustomer: ['miner'],
    coreTechnology: ['sensing', 'machinery'],
    numOfPatents: '',
    website: 'test.com',
    email: 'test.com',
  },
  {
    name: 'eee',
    userId: '65d5daa9e813220dcf9dee98',
    category: 'maker',
    makerType: 'startup',
    industry: ['energy & transport'],
    businessModel: ['B2B', 'B2C'],
    professional: [],
    targetCustomer: ['miner', 'consumer'],
    coreTechnology: ['sensing', 'machinery'],
    numOfPatents: '',
    website: 'test',
    email: 'test',
  },
  {
    name: 'eee',
    userId: '65d5dab5e813220dcf9dee9a',
    category: 'maker',
    makerType: 'startup',
    industry: ['energy & transport', 'retail & logistics'],
    businessModel: ['B2C', 'C2C'],
    professional: [],
    targetCustomer: ['miner'],
    coreTechnology: ['communication'],
    numOfPatents: '',
    website: 'tttt',
    email: 'ttt',
  },
  {
    name: 'aaa',
    userId: '65d5db01e813220dcf9dee9c',
    category: 'maker',
    makerType: 'designer',
    industry: ['energy & transport', 'retail & logistics'],
    businessModel: [],
    professional: ['Architecture'],
    targetCustomer: ['distributor', 'processor'],
    coreTechnology: ['communication'],
    numOfPatents: '',
    website: 'drtre',
    email: 'ertert',
  },
]

function createMakerDataV1({
  userId,
  category,
  makerType,
  industry,
  businessModel,
  professiona,
  targetCustomer,
  coreTechnology,
  numOfPatent,
  website,
  email,
  name,
}) {
  return {
    name,
    description: 'to be filled',
    founded: 'to be filled',
    businessModel: businessModel.toString(),
    employees: '*',
    fundingStage: '*',
    tags: industry.toString(),
    userId: `useId#${userId}`,
  }
}

// TODO: maker DATA for test
const needMakerDataV1 = needMakerMockRawDataV1.map((data) => createMakerDataV1(data))

function createMakerReqDataV1({
  userId,
  category,
  makerType,
  industry,
  businessModel,
  professional,
  targetCustomer,
  coreTechnology,
  numOfPatent,
  website,
  email,
  name,
}) {
  return {
    name,
    category: 'to be filled',
    investRange: 'to be filled',
    investStage: 'to be filled',
    managedFund: 'to be filled',
    tags: industry.toString(),
    userId: `userId#${userId}`,
  }
}

function createAmMakerData(name, category, investRange, investStage, managedFund, tags) {
  return {
    name,
    category,
    investRange:
      investRange?.length === 0
        ? 'N/A'
        : `${normalizeBigNumber(investRange[0])} - ${normalizeBigNumber(investRange[1])}`,
    investStage,
    managedFund: normalizeBigNumber(managedFund),
    tags: tags.toString(),
  }
}

const amMakerData = [
  createAmMakerData('test', 'VC', [5000000, 1000000000], 'Series A', 1000000000000, ['aa', 'bb']),
  createAmMakerData('test', 'VC', [5000000, 1000000], 'Series B', 100000000000, 'test'),
  createAmMakerData('test', 'VC', [5000000, 100000000], 'Series C', 10000000000, 'test'),
]

const ProgressDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`

function SearchTable({ columns, category, setAllDataCount }) {
  const location = useLocation()
  const [page, setPage] = useState(0)
  const [makerData, setMakerData] = useState(null)
  const [rowsPerPage, setRowsPerPage] = useState(6)
  const [isLoading, setIsLoading] = useState(false)
  const [detailsData, setDetailsData] = useState([])

  const [showDialog, setShowDialog] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('Information')
  const [dialogMessage, setDialogMessage] = useState('')
  const [isErrorDialog, setIsErrorDialog] = useState(false)

  const MAX_TEXT_LENGTH_ALLOW = 160

  // TODO: only for test
  // const data = category === 'maker' ? amMakerData : needMakerDataV1

  const formatMakerData = (rawData) => {
    if (category === 'maker') {
      return rawData.map((data) => createMakerDataV1(data))
    }
    return rawData.map((data) => createMakerReqDataV1(data))
    // TODO: for test
    // return amMakerData
  }

  const getMakerProfilesByCategory = async () => {
    if (isLoading) return
    try {
      // throw new Error(404, 'data does not exist')
      setIsLoading(true)
      const res = await axios(`/api/maker/find/${category}/${rowsPerPage}/${page}}`)
      if (!res?.data) throw new Error(404, 'data does not exist')

      const formatedData = formatMakerData(res?.data)

      setMakerData(formatedData)
      setAllDataCount(formatedData?.length || '0')
      setIsLoading(false)
    } catch (error) {
      const { response } = error
      const msg =
        response?.status === 401 || response?.status === 403
          ? 'Authentication failed, please login first.'
          : 'Server Error. Please try again later.'

      setDialogTitle('Error')
      setDialogMessage(msg)
      setIsErrorDialog(true)
      setShowDialog(true)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    // setAllDataCount(10)
    getMakerProfilesByCategory()
  }, [])

  if (isLoading) {
    return (
      <Paper sx={{ width: '100%', overflow: 'hidden', marginLeft: '-1.6rem' }}>
        <TableContainer sx={{ maxHeight: 460 }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={makerData?.length || 10}
          rowsPerPage={rowsPerPage}
          page={page}
        />
        <ProgressDiv>
          <CircularProgress sx={{ color: COLORS.buttonLeaveOrange }} />
        </ProgressDiv>
      </Paper>
    )
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  const handleTableRowClick = (rowData, code) => {
    // console.log(`row clicked ${JSON.stringify(Object.values(rowData))}`)
    setDetailsData(Object.values(rowData))
    setShowDialog(true)
  }

  const handleDialogClose = () => {
    setIsErrorDialog(false)
    setShowDialog(false)
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', marginLeft: '-1.6rem' }}>
      <TableContainer sx={{ maxHeight: 460 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {makerData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow
                hover
                role='checkbox'
                tabIndex={-1}
                key={row.code}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleTableRowClick(row)}
              >
                {columns.map((column) => {
                  const value = row[column.id]

                  return (
                    <TableCell key={column.id} align={column.align}>
                      {/* {value} */}
                      {value?.length > MAX_TEXT_LENGTH_ALLOW
                        ? `${value.slice(0, MAX_TEXT_LENGTH_ALLOW)} ...`
                        : value}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <PopupDialog
          dialogTitle='Details'
          handleClose={handleDialogClose}
          isDialogOpen={showDialog}
          actionsName='Close'
          maxWidth='60vw'
        >
          {isErrorDialog ? (
            <Typography>{dialogMessage}</Typography>
          ) : (
            <SearchDetails details={detailsData} />
          )}
        </PopupDialog>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={makerData?.length || 10}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default SearchTable
