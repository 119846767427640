/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-quotes */
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import axios from 'axios'

import Typography from '@mui/material/Typography'

import { Button, PopupDialog } from '../../components'
import { CONSTANTS } from '../../../assets'
import { logout, authFailure } from '../../../services/redux/user-slice'

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 1.3rem;
  padding-top: 20rem;
`

const StyledButton = styled(Button)`
  min-width: 6rem;
  height: 1.2rem;
  padding: 5px 12px;
  font-size: 16px;
  justify-content: center;
`

const StyledSidebarFooter = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  color: white;
  /* background: linear-gradient(45deg, rgb(21 87 205) 0%, rgb(90 225 255) 100%); */
`

const AuthButtonsDiv = styled.div`
  margin-top: 16px;
`

function SidebarFooter() {
  const { currentUser } = useSelector((state) => state.userAuth)
  const [isLoginHovering, setIsLoginHovering] = useState(false)
  const [isLogoutHovering, setIsLogoutHovering] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('Information')
  const [dialogMessage, setDialogMessage] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const verifyAccessToken = async () => {
    try {
      await axios.get(`/api/users/find/${currentUser._id}`)
    } catch (error) {
      const { response } = error
      if (response?.status === 401) {
        dispatch(logout())
      }
    }
  }

  useEffect(() => {
    verifyAccessToken()
  }, [])

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const handleLogoutClick = async (e) => {
    e.preventDefault()
    try {
      await axios.post('/api/auth/logout', { userId: currentUser._id })
      dispatch(logout())
      navigate('/')
    } catch (error) {
      setDialogTitle('Error')
      setDialogMessage('Server Error. Please try again later.')
      setShowDialog(true)
      dispatch(authFailure())
    }
  }

  return (
    <Container>
      <StyledSidebarFooter>
        {!currentUser ? (
          <AuthButtonsDiv>
            <StyledButton
              enterColor='#185ea0'
              leaveColor='#1876D1'
              // path={`/${location.pathname.split('/')[CONSTANTS.MAKER_TYPE_URL_INDEX]}/login`}
              path='/login'
              text='Login'
            />
            <StyledButton
              enterColor='#185ea0'
              leaveColor='#1876D1'
              // path={`/${location.pathname.split('/')[CONSTANTS.MAKER_TYPE_URL_INDEX]}/signup`}
              path='/signup'
              text='Sign Up'
            />
          </AuthButtonsDiv>
        ) : (
          <AuthButtonsDiv>
            <StyledButton
              enterColor='#185ea0'
              leaveColor='#1876D1'
              path={`/${location.pathname.split('/')[CONSTANTS.MAKER_TYPE_URL_INDEX]}/profile`}
              text='My Home'
            />
            <StyledButton
              enterColor='#185ea0'
              leaveColor='#1876D1'
              path=''
              text='Logout'
              handleClick={handleLogoutClick}
            />
          </AuthButtonsDiv>
        )}
      </StyledSidebarFooter>
      <PopupDialog
        dialogTitle={dialogTitle}
        handleClose={handleDialogClose}
        isDialogOpen={showDialog}
        actionsName='Close'
        maxWidth='30vw'
      >
        <Typography>{dialogMessage}</Typography>
        {/* <Typography>Test</Typography> */}
      </PopupDialog>
    </Container>
  )
}

export default SidebarFooter
