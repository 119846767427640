/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'

import { BulletPointDoc } from '../components'
import { COLORS } from '../../assets'
import useRegisterContext from '../../hooks/useRegisterContext'

const Input = styled.input`
  border: none;
  background-color: transparent;
  outline: none;
  color: #aaaaaa;
`

const Label = styled.span`
  font-size: 1rem;
  display: inline-block;
`

const QuestionsContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`

const NumOfPatentText = styled.div``

function WebsiteAndEmail() {
  const { data, setData } = useRegisterContext()
  const [website, setWebsite] = useState(data?.website)
  const [email, setEmail] = useState(data?.email)
  const [name, setName] = useState(data?.name)
  // const [data, setData] = useState({
  //   category: '',
  //   type: '',
  //   industry: [],
  //   businessModel: [],
  //   professional: [],
  //   targetCustomer: [],
  //   coreTechnology: [],
  //   numOfPatents: 0,
  //   website: '',
  //   email: '',
  // })

  const handleWebsiteInput = (e) => {
    e.preventDefault()
    const { value } = e.target
    setWebsite(value)
    setData((prev) => ({ ...prev, website: value }))
  }

  const hanldeEmailInput = (e) => {
    e.preventDefault()
    const { value } = e.target
    setEmail(value)
    setData((prev) => ({ ...prev, email: value }))
  }

  const handleNameInput = (e) => {
    e.preventDefault()
    const { value } = e.target
    setName(value)
    setData((prev) => ({ ...prev, name: value }))
  }

  return (
    <div>
      <QuestionsContainer>
        <BulletPointDoc
          text='The official website of your company'
          color={COLORS.textHighlightRed}
        />
      </QuestionsContainer>
      <TextField
        id='standard-basic'
        InputLabelProps={{ shrink: false }}
        label={website === '' ? 'Your company website or personal website' : website}
        variant='standard'
        sx={{ marginLeft: '2rem', mt: '-1rem', width: '25vw' }}
        onChange={handleWebsiteInput}
      />
      <QuestionsContainer>
        <BulletPointDoc text='Your email address (work email)' color={COLORS.textHighlightRed} />
      </QuestionsContainer>
      <TextField
        id='standard-basic'
        InputLabelProps={{ shrink: false }}
        label={email === '' ? 'Your preferred email address' : email}
        variant='standard'
        sx={{ marginLeft: '2rem', mt: '-1rem', width: '20vw' }}
        onChange={hanldeEmailInput}
      />
      <QuestionsContainer>
        <BulletPointDoc
          text='Your name (A short descripti  on to identify yourself)'
          color={COLORS.textHighlightRed}
        />
      </QuestionsContainer>
      <TextField
        id='standard-basic'
        InputLabelProps={{ shrink: false }}
        label={name === '' ? 'Your preferred name' : name}
        variant='standard'
        sx={{ marginLeft: '2rem', mt: '-1rem', width: '20vw' }}
        onChange={handleNameInput}
      />
    </div>
  )
}

export default WebsiteAndEmail
