/* eslint-disable no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-quotes */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import { PopupDialog } from '../../features/components'
import { COLORS } from '../../assets'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 10vh);
  color: #fff;
  /* font-family: var(--font-family); */
  position: absolute;
  top: 50%;
  /* left: 50%; */
  transform: translate(-50%, -50%);

  // for iphone SE
  @media only screen and (min-width: 320px) {
    left: 55%;
  }

  // for iphone 12 PEO > 390
  @media only screen and (min-width: 390px) {
    left: 55%;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    left: 55%;
  }

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    left: 55%;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    left: 55%;
  }

  @media only screen and (min-width: 1024px) {
    left: 50%;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 2xp solid; */
  padding: 1rem;
  gap: 1.3rem;
`

const RedirContainer = styled.div`
  display: flex;
  flex-direction: row;

   // for iphone SE
   @media only screen and (min-width: 320px) {
    flex-direction: column;
  }

  // for iphone 12 PEO > 390
  @media only screen and (min-width: 390px) {
    flex-direction: column;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    flex-direction: column;
  }

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    flex-direction: column;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    flex-direction: column;
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

const ConfirmInfoDiv = styled.div`
  align-content: flex-start;
`

function ConfirmEmail() {
  const [queryParameters] = useSearchParams()
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false)
  const [isConfirmFail, setIsConfirmFail] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('Information')
  const [dialogMessage, setDialogMessage] = useState('')
  const navigate = useNavigate()

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const sleep = (timeInMilSeconds) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, timeInMilSeconds)
    })

  const verifyEmailToken = async () => {
    try {
      const confirmationCode = queryParameters.get('confirmationCode')
      const res = await axios.post('/api/auth/confirm-email', { confirmationCode })
      sleep(2000)
      if (res.status === 200) {
        setIsEmailConfirmed(true)
        setTimeout(() => navigate('/'), 5000)
      }
    } catch (error) {
      const { response } = error
      setIsConfirmFail(true)

      const msg =
        response?.status === 404 ? response?.data?.message : 'Server error. Please try again later.'

      setDialogTitle('Error')
      setDialogMessage(msg)
      setShowDialog(true)
    }
  }

  useEffect(() => {
    verifyEmailToken()
  }, [])

  return (
    <Container>
      {isEmailConfirmed ? (
        <Wrapper>
          <ConfirmInfoDiv>
            <Typography>
              You have successfully verified your email address, and you will be redirect to the
              home page in 5 seconds.
            </Typography>
            <RedirContainer>
              <Typography>
                Or you can click the following link and go to the Home page now
              </Typography>
              <Link
                to='/'
                style={{ color: COLORS.buttonLeaveOrange, marginLeft: '0.5rem', marginTop: 3 }}
              >
                Home
              </Link>
            </RedirContainer>
          </ConfirmInfoDiv>
        </Wrapper>
      ) : (
        <Wrapper style={{ visibility: isConfirmFail ? 'visible' : 'hidden' }}>
          <Typography>Your email verification failed. Please try again later</Typography>
        </Wrapper>
      )}
      <PopupDialog
        dialogTitle={dialogTitle}
        handleClose={handleDialogClose}
        isDialogOpen={showDialog}
        actionsName='Close'
        maxWidth='30vw'
      >
        <Typography>{dialogMessage}</Typography>
        {/* <Typography>Test</Typography> */}
      </PopupDialog>
    </Container>
  )
}

export default ConfirmEmail
