/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import useRegisterContext from '../../hooks/useRegisterContext'
import { COLORS } from '../../assets'

function LinearProgressWithLabel(props) {
  const { value: progress } = props

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
      <Box sx={{ width: '100%', mr: 1, ml: 2 }}>
        <LinearProgress
          variant='determinate'
          value={progress}
          sx={{ borderRadius: 5, height: '2vh' }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='h7' color='text.secondary'>
          {`${Math.round(progress)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

function StepProgressBar() {
  const { curPage, title } = useRegisterContext()

  const interval = 100 / Object.keys(title).length
  const progress = ((curPage + 1) * interval).toFixed(2)

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  )
}

export default StepProgressBar
