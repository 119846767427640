import React from 'react'
import { useLocation, Outlet } from 'react-router-dom'
import styled from 'styled-components'

// import { SearchContents } from '../containers'
import { SearchMenu, NeedMakerSideBar, SearchContents } from '../../../features/search_page'

const upListItems = [
  {
    id: 0,
    name: 'Sector',
    isPopup: true,
    subList: [
      {
        id: 0,
        isCheckBox: true,
        name: 'Construction & Building',
      },
      {
        id: 1,
        isCheckBox: true,
        name: 'Resource & Environment',
      },
      {
        id: 2,
        isCheckBox: true,
        name: 'Energy & Transcript',
      },
      {
        id: 3,
        isCheckBox: true,
        name: 'Retail & Logistics',
      },
      {
        id: 4,
        isCheckBox: true,
        name: 'Telecommunications',
      },
    ],
  },
  {
    id: 1,
    name: 'Target Customer',
    isPopup: true,
    subList: [
      {
        id: 0,
        isCheckBox: true,
        name: 'Communication Services',
      },
      {
        id: 1,
        isCheckBox: true,
        name: 'Government & City',
      },
      {
        id: 2,
        isCheckBox: true,
        name: 'Healthcare & Life Sciences',
      },
      {
        id: 3,
        isCheckBox: true,
        name: 'Travel & Tourism',
      },
    ],
  },
  {
    id: 2,
    name: 'Core Technology',
    isPopup: true,
    subList: [
      {
        is: 0,
        isCheckBox: true,
        name: 'Biologicals',
      },
      {
        id: 1,
        isCheckBox: true,
        name: 'Government & City',
      },
      {
        id: 2,
        isCheckBox: true,
        name: 'Healthcare & Life Sciences',
      },
      {
        id: 3,
        isCheckBox: true,
        name: 'Travel & Tourism',
      },
    ],
  },
  {
    id: 3,
    name: 'Funding Stage',
    isSubList: true,
    subList: [
      {
        id: 0,
        isCheckBox: true,
        name: 'Pre-Funding',
      },
      {
        id: 1,
        isCheckBox: true,
        name: 'Pre-Seed',
      },
      {
        id: 2,
        isCheckBox: true,
        name: 'Seed',
      },
      {
        id: 3,
        isCheckBox: true,
        name: 'A',
      },
      {
        id: 4,
        isCheckBox: true,
        name: 'B',
      },
      {
        id: 5,
        isCheckBox: true,
        name: 'C',
      },
      {
        id: 6,
        isCheckBox: true,
        name: 'D',
      },
      {
        id: 7,
        isCheckBox: true,
        name: 'E',
      },
      {
        id: 8,
        isCheckBox: true,
        name: 'F',
      },
      {
        id: 9,
        isCheckBox: true,
        name: 'G',
      },
      {
        id: 10,
        isCheckBox: true,
        name: 'Mature',
      },
      {
        id: 11,
        isCheckBox: true,
        name: 'Public',
      },
      {
        id: 12,
        isCheckBox: true,
        name: 'Accquired',
      },
    ],
  },
  {
    id: 4,
    name: 'Business Models',
    isSubList: true,
    subList: [
      {
        id: 0,
        isCheckBox: true,
        name: 'B2B',
      },
      {
        id: 1,
        isCheckBox: true,
        name: 'B2C',
      },
      {
        id: 2,
        isCheckBox: true,
        name: 'B2B2C',
      },
      {
        id: 3,
        isCheckBox: true,
        name: ' B2G',
      },
    ],
  },
  {
    id: 5,
    name: 'Product Stage',
    isSubList: true,
  },
  {
    id: 6,
    name: 'Employees',
    isSubList: true,
  },
]

const bottomListItems = [
  {
    id: 0,
    name: 'Search by tag',
  },
  {
    id: 1,
    name: 'Founded',
  },
  {
    id: 2,
    name: 'Total Raised',
  },
  {
    id: 3,
    name: 'Location',
  },
]

const Container = styled.div`
  display: flex;
  font-family: var(--font-family);
`

function Search() {
  const location = useLocation()

  return (
    <Container>
      <NeedMakerSideBar />
      <SearchMenu upListItems={upListItems} bottomListItems={bottomListItems} />
      <SearchContents routes={location?.state?.name || 'startups'} />
      <Outlet />
    </Container>
  )
}

export default Search
