/* eslint-disable object-curly-newline */
/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import { COLORS } from '../../assets'

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const Container = styled('div')({})

function PopupDialog({
  dialogTitle,
  handleClose,
  isDialogOpen,
  actionsName,
  children,
  maxWidth = '40vw',
  isConfirmDialog = false,
  handleConfirm = null,
  handleCancel = null,
}) {
  return (
    <Container>
      <StyledDialog
        onClose={handleClose}
        aria-labelledby='popup-dialog-component'
        open={isDialogOpen}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth, // Set your width here
            },
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id='popup-dialog-component'>
          {dialogTitle}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => COLORS.closeButtonGrey,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>{children}</DialogContent>
        {isConfirmDialog ? (
          <DialogActions>
            <Button autoFocus onClick={handleConfirm} sx={{ textTransform: 'none' }}>
              Confirm
            </Button>
            <Button autoFocus onClick={handleCancel} sx={{ textTransform: 'none' }}>
              Cancel
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button autoFocus onClick={handleClose} sx={{ textTransform: 'none' }}>
              {actionsName}
            </Button>
          </DialogActions>
        )}
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} sx={{ textTransform: 'none' }}>
            {actionsName}
          </Button>
          <Button autoFocus onClick={handleClose} sx={{ textTransform: 'none' }}>
            Cancel
          </Button>
        </DialogActions> */}
      </StyledDialog>
    </Container>
  )
}

export default PopupDialog
