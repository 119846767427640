/* eslint-disable no-unused-vars */
/* eslint-disable jsx-quotes */
import React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'

import { Card, CardMedia } from '@mui/material'

import { COLORS } from '../../../assets'
import { ProjectProgressImg } from '../../../static/images'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

function ProjectProgress({ handleClose, isProgressOpen, isProgressTab = false }) {
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={isProgressOpen && isProgressTab}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
          Project Progress
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => COLORS.closeButtonGrey,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Card>
            <CardMedia component='img' image={ProjectProgressImg} title='progress' />
          </Card>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} sx={{ textTransform: 'none' }}>
            Save Changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}

export default ProjectProgress
