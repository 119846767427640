/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable jsx-quotes */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Sidebar, Menu, MenuItem, menuClasses } from 'react-pro-sidebar'
import { useNavigate, useLocation } from 'react-router-dom'

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import BusinessIcon from '@mui/icons-material/Business'
import DrawIcon from '@mui/icons-material/Draw'
import EngineeringIcon from '@mui/icons-material/Engineering'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import InfoIcon from '@mui/icons-material/Info'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import SidebarFooter from '../../sidebar/SideBarFooter'

const Container = styled.div`
  /* height: 100%; */
  /* display: flex; */
  color: white;
`

const Divisor = styled.hr`
  color: #bbb;
  border-top: 2px solid;
  border-radius: 10px;
  width: 80%;
  margin-top: 0.2rem;
`

const MainForMobil = styled.main``

const NavBarDiv = styled.div`
  padding-left: 0.6rem;
  padding-top: 0.2rem;
  color: '#fff';
`

const MenuIconDiv = styled.div``

function SideBar() {
  const location = useLocation()
  const [windowWidth, setWindowWidth] = useState(0)
  const [isCollapse, setIsCollapse] = useState(false)
  const [toggled, setToggled] = useState(false)
  const [broken, setBroken] = useState(window.matchMedia('(max-width: 375px)').matches)
  const navigate = useNavigate()

  const getWindowSize = () => {
    setWindowWidth(window.innerWidth)
  }

  const handleMakerLinkedClick = () => {
    navigate('/')
  }

  const handleStartUpClick = () => {
    const curPath = location?.pathname
    if (curPath?.includes('profile')) {
      const prefix = curPath.replace('profile', 'search')
      navigate(`${prefix}/startups`, { state: { name: 'Startups' } })
      return
    }
    navigate('startups', { state: { name: 'Startups' } })
  }

  useEffect(() => {
    window.addEventListener('resize', getWindowSize)

    if (windowWidth < 992 && windowWidth > 375) {
      setIsCollapse(true)
    } else {
      setIsCollapse(false)
    }

    return () => {
      window.removeEventListener('resize', getWindowSize)
    }
  }, [window.innerWidth])

  return (
    <Container>
      <Sidebar
        toggled={toggled}
        collapsed={isCollapse}
        breakPoint='sm'
        onBreakPoint={setBroken}
        backgroundColor='rgb(75, 76, 77)'
        onBackdropClick={() => setToggled(false)}
        style={{
          height: '101vh',
          marginTop: '-8px',
          marginLeft: '-8px',
          width: '300px !default',
        }}
        rootStyles={{
          background:
            'linear-gradient(180deg, rgba(166,240,255,1) 0%, rgba(220,250,255,1) 49%, rgba(230,252,255,1) 100%)',
        }}
      >
        <Menu
          rootStyles={{
            [`.${menuClasses.icon}`]: {
              color: '#f7760c',
            },
          }}
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              if (level === 0) {
                return {
                  color: disabled ? '#606166' : '#fffff',
                  backgroundColor: active ? '#fff' : undefined,
                  '&:hover': {
                    backgroundColor: '#335B8C !important',
                    color: 'white !important',
                    borderRadius: '8px !important',
                    fontWeight: 'bold !important',
                  },
                }
              }
            },
          }}
        >
          {/* <div>
            <button className='sb-button' onClick={() => setIsCollapse(!isCollapse)}>
              Collapse
            </button>
          </div> */}
          <MenuItem
            icon={<HomeOutlinedIcon />}
            rootStyles={{
              [`.${menuClasses.button}`]: {
                '&:hover': {
                  backgroundColor: '#202123',
                },
              },
            }}
            onClick={handleMakerLinkedClick}
          >
            {' '}
            <h3 style={{ fontSize: '1.3rem' }}>MAKERLinked</h3>
          </MenuItem>
          <MenuItem
            icon={<BusinessIcon />}
            suffix={<ArrowForwardIosIcon sx={{ fontSize: 15 }} />}
            onClick={handleStartUpClick}
          >
            <h4>StartUp</h4>
          </MenuItem>
          <MenuItem icon={<DrawIcon />} suffix={<ArrowForwardIosIcon sx={{ fontSize: 15 }} />}>
            <h4>Designer</h4>
          </MenuItem>
          <MenuItem
            icon={<EngineeringIcon />}
            suffix={<ArrowForwardIosIcon sx={{ fontSize: 15 }} />}
          >
            <h4>Engineer</h4>
          </MenuItem>
          <MenuItem icon={<UploadFileIcon />}>
            <h4>Upload A Challenge</h4>
          </MenuItem>
          <Divisor />
          <MenuItem icon={<InfoIcon />}>
            <h4>About</h4>
          </MenuItem>
        </Menu>
        <SidebarFooter />
      </Sidebar>
      <MainForMobil>
        <NavBarDiv>
          <MenuIconDiv>
            {broken && <MenuIcon sx={{ fontSize: 35 }} onClick={() => setToggled(!toggled)} />}
          </MenuIconDiv>
          {/* TODO: add search bar */}
        </NavBarDiv>
      </MainForMobil>
    </Container>
  )
}

export default SideBar
