/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'

import { Card, CardMedia } from '@mui/material'

import { COLORS } from '../../../assets'
import { MeetingSummary1, MeetingSummary2 } from '../../../static/images'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

function MeetingSummary({ handleClose, isSummaryOpen }) {
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={isSummaryOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
          Meeting Summary
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => COLORS.closeButtonGrey,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Card>
            <CardMedia component='img' image={MeetingSummary1} title='summary1' />
          </Card>
          <Card>
            <CardMedia component='img' image={MeetingSummary2} title='summary2' />
          </Card>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} sx={{ textTransform: 'none' }}>
            Save Changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}

export default MeetingSummary
