/* eslint-disable no-unused-vars */
/* eslint-disable jsx-quotes */
/* eslint-disable object-curly-newline */
import React, { useState } from 'react'
import styled from 'styled-components'

/**
 * when use, may need to set width, font-size
 */
const CustomButton = styled.a`
  padding: 0.5rem 1rem;
  border-radius: 5px;
  line-height: 25px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  color: 'white';
  /* background-color: #fff; */
  /* background-color: isHovering?#FCC63D : #848484  ; */
  /* background: ${(props) => (props.disabled ? '#c1bdbd' : '#F7750A')}; */
  text-decoration: none;
  outline: none;
  text-align: center;
  /* margin-left: 5.5rem; */
`
// for styled
function Button({ className, enterColor, leaveColor, text, path, handleClick }) {
  const [isButtonHovering, setIsButtonHovering] = useState(false)

  const handleButtonEnter = () => {
    setIsButtonHovering(true)
  }

  const handleButtonLeave = () => {
    setIsButtonHovering(false)
  }

  return (
    <CustomButton
      href={path}
      className={className}
      style={{
        backgroundColor: isButtonHovering ? enterColor : leaveColor,
      }}
      onMouseEnter={handleButtonEnter}
      onMouseLeave={handleButtonLeave}
      onClick={handleClick}
    >
      {text}
    </CustomButton>
  )
}

export default Button
