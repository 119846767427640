/* eslint-disable import/prefer-default-export */
import { CONSTANTS } from '../assets'

const normalizeBigNumber = (value) => {
  if (value > CONSTANTS.ONE_TRILLION) {
    return `${(value / CONSTANTS.ONE_TRILLION).toFixed(1)} T`
  }
  if (value > CONSTANTS.ONE_BILLION) {
    return `${(value / CONSTANTS.ONE_BILLION).toFixed(1)} Bn`
  }
  if (value > CONSTANTS.ONE_MILLION) {
    return `${(value / CONSTANTS.ONE_MILLION).toFixed(1)} M`
  }
  if (value > CONSTANTS.ONE_KILO) {
    return `${(value / CONSTANTS.ONE_KILO).toFixed(1)} K`
  }
  return value
}

export { normalizeBigNumber }
