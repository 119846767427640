/* eslint-disable no-unused-vars */
/* eslint-disable jsx-quotes */
import React, { useState } from 'react'
import styled from 'styled-components'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import MeetingDetailsCard from './MeetingDetailsCard'

const attender = {
  name: 'XXX',
  title: 'CEO',
}

const meetingData = {
  attender,
  meetingTime: { start: '16:30', end: '17:30' }, // start time - end time
  meetingDate: 'Tuesday, October 22',
  meetingChannel: 'Online Video Meeting',
  meetingSummary: true,
}

const meetingData1 = {
  attender,
  meetingTime: { start: '16:30', end: '17:30' }, // start time - end time
  meetingDate: 'Tuesday, October 25',
  meetingChannel: 'Online Video Meeting',
  meetingSummary: false,
}

const Container = styled.div``

function AllMeetings() {
  return (
    <Container>
      {/* title card */}
      <Card
        sx={{
          backgroundColor: '##768D9A',
          height: '6vh',
          alignItems: 'center',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '3vh',
          }}
        >
          <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
            All times are displayed in your time zone (Europe/Brussels, currently: 17:00),
          </Typography>
          <Button sx={{ textTransform: 'none' }} size='small' variant='text'>
            Change
          </Button>
        </CardContent>
      </Card>
      {/* meeting infor card */}
      <MeetingDetailsCard meetingData={meetingData} />
      <MeetingDetailsCard meetingData={meetingData1} />
    </Container>
  )
}

export default AllMeetings
