/* eslint-disable no-unused-vars */
/* eslint-disable jsx-quotes */
import React, { useState } from 'react'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import Collapse from '@mui/material/Collapse'
import Checkbox from '@mui/material/Checkbox'
import Hidden from '@mui/material/Hidden'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import hasChildren from '../../../utils/has-children'

function MultiLevel({ listItem }) {
  const { subList } = listItem
  const [isOpen, setIsOpen] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const handleExpandItemClick = () => {
    setIsOpen((prev) => !prev)
  }

  const handleCheckBoxClick = () => {
    setIsChecked((prev) => !prev)
  }

  return (
    <>
      <ListItem
        key={listItem.name}
        disablePadding
        sx={{
          // selected and (selected + hover) states
          // '&& .Mui-selected, && .Mui-selected:hover': {
          //   bgcolor: '#1876D1',
          //   '&, & .MuiListItemIcon-root': {
          //     color: 'white',
          //   },
          // },
          // hover states
          '& .MuiListItemButton-root:hover': {
            bgcolor: '#429aed',
            '&, & .MuiListItemIcon-root': {
              color: 'white',
            },
          },
        }}
      >
        <ListItemButton onClick={() => handleExpandItemClick()}>
          {listItem.isCheckBox ? (
            <Checkbox checked={isChecked} size='small' onClick={() => handleCheckBoxClick()} />
          ) : (
            <> </>
          )}
          <ListItemText
            primary={listItem.name}
            primaryTypographyProps={{ fontSize: { sm: '0.8rem', md: '1rem' } }}
          />
          {isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </ListItemButton>
      </ListItem>
      <Collapse key={listItem.id} in={isOpen} timeout='auto' unmountOnExit>
        <List key={listItem.id} component='div' disablePadding>
          {subList.map((subListItem) => (
            <MenuItem key={subListItem.id} listItem={subListItem} />
          ))}
        </List>
      </Collapse>
    </>
  )
}

function SingleLevel({ listItem }) {
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckBoxClick = () => {
    setIsChecked((prev) => !prev)
  }
  return (
    <ListItem
      key={listItem.name}
      sx={{
        // selected and (selected + hover) states
        // '&& .Mui-selected, && .Mui-selected:hover': {
        //   bgcolor: '#1876D1',
        //   '&, & .MuiListItemIcon-root': {
        //     color: 'white',
        //   },
        // },
        // hover states
        '& .MuiListItemButton-root:hover': {
          bgcolor: '#429aed',
          '&, & .MuiListItemIcon-root': {
            color: 'white',
          },
        },
      }}
      disablePadding
    >
      <ListItemButton>
        {listItem.isCheckBox ? (
          <Checkbox checked={isChecked} size='small' onClick={() => handleCheckBoxClick()} />
        ) : (
          <Hidden />
        )}
        <ListItemText
          primary={listItem.name}
          primaryTypographyProps={{ fontSize: { sm: '0.8rem', md: '1rem' } }}
        />
      </ListItemButton>
    </ListItem>
  )
}

function MenuItem({ listItem }) {
  const Component = hasChildren(listItem) ? MultiLevel : SingleLevel
  return <Component listItem={listItem} />
}

function MenuItems({ listItems }) {
  return listItems.map((listItem) => <MenuItem key={listItem.id} listItem={listItem} />)
}

export default MenuItems
