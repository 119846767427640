/* eslint-disable no-unused-vars */
/* eslint-disable jsx-quotes */
import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import EventIcon from '@mui/icons-material/Event'
import Avatar from '@mui/material/Avatar'

import { COLORS } from '../../../assets'

function ProjectMgtInfoGrid({ eventTitle, attendees, hanldeGridClick }) {
  const attendeesTransformer = (data) => data.join(' & ')

  return (
    <Box
      bgcolor={COLORS.projMgtGridBgGrey}
      onClick={hanldeGridClick}
      sx={{
        width: '23vw',
        height: 'auto',
        mr: '1rem',
        borderRadius: 2,
        ml: '0.4rem',
        mt: '0.5rem',
        mb: '0.5rem',
        '&:hover': {
          backgroundColor: COLORS.projMgtGridHoverGrey,
          cursor: 'pointer',
        },
        color: COLORS.darkBlue,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'cneter',
          padding: '1rem',
        }}
      >
        <EventIcon sx={{ fontSize: 25 }} />
        <Typography sx={{ paddingLeft: '1rem', fontSize: 19, fontWeight: 'bold' }}>
          {eventTitle}
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ fontSize: 18, paddingLeft: '1rem', paddingBottom: '1rem' }}>
          {attendeesTransformer(attendees)}
        </Typography>
      </Box>
      <Box
        sx={{
          paddingLeft: '1rem',
          display: 'flex',
          flexDirection: 'row',
          paddingBottom: '0.6rem',
        }}
      >
        <Avatar
          sx={{
            // bgcolor: deepPurple[500],
            bgcolor: COLORS.lightOragne,
            mr: '-3%',
            height: '3.9vh',
            width: '2vw',
            fontSize: '0.8rem',
          }}
        >
          MK
        </Avatar>
        <Avatar
          sx={{
            // bgcolor: deepPurple[500],
            bgcolor: COLORS.lightBlue,
            mr: '-3%',
            height: '3.9vh',
            width: '2vw',
            fontSize: '0.8rem',
          }}
        >
          MK
        </Avatar>
      </Box>
    </Box>
  )
}

export default ProjectMgtInfoGrid
