/* eslint-disable no-underscore-dangle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-quotes */
/* eslint-disable object-curly-newline */
import React, { useState, useRef, useEffect } from 'react'
import { ScrollView } from 'react-native'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { authFailure, updateUser, logout } from '../../services/redux/user-slice'
import { Button, PopupDialog, LogoBox } from '../../features/components'
import { COLORS } from '../../assets'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 10vh);
  color: #fff;
  font-family: var(--font-family);
  position: absolute;
  top: 50%;
  /* left: 50%; */
  transform: translate(-50%, -50%);

  // for iphone SE
  @media only screen and (min-width: 320px) {
    left: 50%;
  }

  // for iphone 12 PEO > 390
  @media only screen and (min-width: 390px) {
    left: 50%;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    left: 50%;
  }

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    left: 50%;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    left: 50%;
  }

  @media only screen and (min-width: 1024px) {
    left: 50%;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2xp solid;
  padding: 20px 50px;
  gap: 10px;
`

const Title = styled.h1`
  font-size: 1.5rem;
  text-align: center;
`

const Input = styled.input`
  width: 100%;
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  border: 1px solid;
  /* height: 3rem; */
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const InfoItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  margin-bottom: 1.5rem;
`

const InfoItemLabel = styled.div`
  color: #fff;
  margin-right: 1vw;
`

const EmailInputDiv = styled.div`
  flex: 1;
`

const OtherInputDiv = styled.div`
  width: 20vw;
`

const StyledButton = styled(Button)`
  width: 10rem;
  font-size: 1rem;
  margin-right: 1.7rem;
`

const WarningSpan = styled.span`
  color: #ed7474;
  font-weight: bold;
  margin-bottom: 1rem;
`

const BottomButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

function PersonalInfo() {
  const { currentUser } = useSelector((state) => state.userAuth)
  const [isUsernameEditDisable, setIsUsernameEditDisable] = useState(true)
  const [infoItemInput, setInfoItemInput] = useState({
    username: '',
  })
  const [infoItemError, setInfoItemError] = useState({
    username: '',
  })
  const [isUpdating, setIsUpdating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isConfirmDialog, setIsConfirmDialog] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('Information')
  const [dialogMessage, setDialogMessage] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const usernameInput = useRef(null)

  const verifyAccessToken = async () => {
    try {
      await axios.get(`/api/users/find/${currentUser._id}`)
    } catch (error) {
      const { response } = error
      if (response?.status === 401) {
        dispatch(logout())
        navigate('/login')
      }
    }
  }

  useEffect(() => {
    verifyAccessToken()
  }, [])

  useEffect(() => {
    if (!isUsernameEditDisable) {
      usernameInput.current.focus()
    }
  }, [isUsernameEditDisable])

  const deleteUserInfoById = async () => {
    if (isDeleting) return

    try {
      await axios.delete(`/api/users/${currentUser._id}`)
    } catch (error) {
      const { response } = error

      const msg =
        response?.status === 401 || response?.status === 403
          ? 'Authentication failed, please login first.'
          : 'Server Error. Please try again later.'

      setDialogTitle('Error')
      setDialogMessage(msg)
      setShowDialog(true)

      setIsDeleting(false)
    }
  }

  const logoutUser = async () => {
    try {
      await axios.post('/api/auth/logout', { userId: currentUser._id })
      dispatch(logout())
    } catch (error) {
      setDialogTitle('Error')
      setDialogMessage('Server is busy. Please try again later.')
      setShowDialog(true)
      setIsDeleting(false)
    }
  }

  const deletePersonalInfo = async () => {
    setIsDeleting(true)

    await deleteUserInfoById()
    await logoutUser()

    setIsDeleting(false)
    navigate('/login')
  }

  useEffect(() => {
    if (isConfirm) {
      deletePersonalInfo()
    }
  }, [isConfirm])

  const handleUsernameEditClick = () => {
    setIsUsernameEditDisable(false)
  }

  const checkDuplicate = async (name, value) => {
    if (name !== 'username') return false
    try {
      const res = await axios.post('/api/auth/hasDup', { [name]: value })
      return res?.data?.hasDup
    } catch (error) {
      setDialogTitle('Error')
      setDialogMessage('Server is busy. Please try again later.')
      setShowDialog(true)
      dispatch(authFailure())
    }

    return false
  }

  const validateInfoItem = async (e) => {
    setIsUsernameEditDisable(true)

    const { name, value } = e.target
    const hasDup = name === 'username' && value !== '' ? await checkDuplicate(name, value) : false

    setInfoItemError((prev) => {
      const statObj = { ...prev, [name]: '' }

      switch (name) {
        case 'username':
          if (value.length > 18) {
            statObj[name] = 'The username should have no more than 18 characters'
          } else if (hasDup) {
            statObj[name] = 'The username has already been used by other users'
          }
          break
        default:
          break
      }
      return statObj
    })
  }

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const onInfoItemChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target

    // reset signup error
    setInfoItemError((prev) => {
      const statObj = { ...prev, [name]: '' }
      return statObj
    })

    setInfoItemInput((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handlePasswordClick = () => {
    navigate('/login')
  }

  const handleSaveInfoClick = async (e) => {
    e.preventDefault()
    // TODO: first update database, then update redux store
    if (isUpdating) return
    try {
      setIsUpdating(true)
      await axios.put(`/api/users/${currentUser._id}`, {
        username: infoItemInput.username,
      })

      const newUserInfo = { ...currentUser, username: infoItemInput.username }
      dispatch(updateUser(newUserInfo))
      setIsUpdating(false)
    } catch (error) {
      setIsUpdating(false)
      setDialogTitle('Error')
      setDialogMessage('Server Error. Please try again later.')
      setShowDialog(true)
    }
  }

  const handleDeleteInfoClick = async (e) => {
    e.preventDefault()
    setIsConfirmDialog(true)
    setDialogMessage(
      'By deleting your personal info, your personal info, together with your maker ' +
        'profile will be permently deleted from our database, and further your account ' +
        'will be permanently deactived and deleted as well. Do you still want to ' +
        'proceed?',
    )
    setShowDialog(true)
  }

  const handleConfirm = async () => {
    setIsConfirm(true)
    setShowDialog(false)
  }

  const handleCancel = async () => {
    setIsConfirm(false)
    setShowDialog(false)
  }

  return (
    <Container>
      <LogoBox path='/' />
      <ScrollView style={{ flex: 1 }}>
        <Wrapper className='signin__container__wrapper'>
          <Title>My Personal Info</Title>
          {/* username, email, password (click) --> login page with email loaded from redux */}

          <InfoContainer>
            <InfoItemContainer>
              <InfoItemLabel>Email:</InfoItemLabel>
              <EmailInputDiv>
                <Input
                  placeholder={currentUser?.email || 'email (required)'}
                  name='email'
                  disabled
                />
              </EmailInputDiv>
              <IconButton
                color='secondary'
                aria-label='Edit'
                sx={{ marginLeft: '1.5rem', visibility: 'hidden' }}
                onClick={handleUsernameEditClick}
              >
                <EditIcon />
              </IconButton>
            </InfoItemContainer>
            <InfoItemContainer>
              <InfoItemLabel>Username:</InfoItemLabel>
              <OtherInputDiv>
                <Input
                  ref={usernameInput}
                  placeholder={currentUser?.username || 'username (not required)'}
                  name='username'
                  disabled={isUsernameEditDisable}
                  onChange={onInfoItemChange}
                  onBlur={async (e) => validateInfoItem(e)}
                />
              </OtherInputDiv>
              <IconButton
                color='secondary'
                aria-label='Edit'
                sx={{ marginLeft: '1.5rem' }}
                onClick={handleUsernameEditClick}
              >
                <EditIcon />
              </IconButton>
            </InfoItemContainer>
            {infoItemError.username && (
              <WarningSpan className='err'>{infoItemError.username}</WarningSpan>
            )}
            <InfoItemContainer>
              <InfoItemLabel>Password:</InfoItemLabel>
              <OtherInputDiv>
                <Input placeholder='******' name='password' type='password' disabled />
              </OtherInputDiv>
              <IconButton
                color='secondary'
                aria-label='Edit'
                sx={{ marginLeft: '1.5rem' }}
                onClick={handlePasswordClick}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </InfoItemContainer>
          </InfoContainer>
          {isUpdating || isDeleting ? (
            <CircularProgress sx={{ color: COLORS.buttonLeaveOrange }} />
          ) : (
            <BottomButtonsDiv>
              <StyledButton
                enterColor={COLORS.buttonEnterOrange}
                leaveColor={COLORS.buttonLeaveOrange}
                path=''
                text='Save My Changes'
                handleClick={handleSaveInfoClick}
              />
              <StyledButton
                enterColor={COLORS.buttonEnterOrange}
                leaveColor={COLORS.buttonLeaveOrange}
                path=''
                text='Delete My Info'
                handleClick={handleDeleteInfoClick}
              />
            </BottomButtonsDiv>
          )}
        </Wrapper>
        <PopupDialog
          dialogTitle={dialogTitle}
          handleClose={handleDialogClose}
          isDialogOpen={showDialog}
          isConfirmDialog={isConfirmDialog}
          actionsName='Close'
          maxWidth='45vw'
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
        >
          <Typography>{dialogMessage}</Typography>
          {/* <Typography>Test</Typography> */}
        </PopupDialog>
      </ScrollView>
    </Container>
  )
}

export default PersonalInfo
