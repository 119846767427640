/* eslint-disable no-underscore-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-quotes */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'

import { MakerLinkedLogo } from '../static/images'
import { PopupDialog } from '../features/components'
import { COLORS } from '../assets'
import { RegisterSelections, StepProgressBar } from '../features/register_grids'
import useRegisterContext from '../hooks/useRegisterContext'
import { updateUser } from '../services/redux/user-slice'

const idCats = [
  {
    id: 1,
    category: 'Investor',
  },
  {
    id: 2,
    category: 'Industrial Park',
  },
  {
    id: 3,
    category: 'Enterprise',
  },
  {
    id: 4,
    category: 'Social Organisation',
  },
  {
    id: 5,
    category: 'None of the above, I have needs/challenges and want to find solutions',
  },
]

const industryCats = [
  {
    id: 1,
    category: 'Construction & Building',
  },
  {
    id: 2,
    category: 'Resouces & Environment',
  },
  {
    id: 3,
    category: 'Energy & Transport',
  },
  {
    id: 4,
    category: 'Retail & Logistics',
  },
  {
    id: 5,
    category: 'Telecommunications',
  },
]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 56px);
  min-height: 100vh;
  color: ${COLORS.textBlack};
  font-family: var(--font-family);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`

const PersonaContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px;
  background-color: ${COLORS.projMgtGridBgGrey};
  height: 'auto';
  border-radius: 1rem;
`

const HeaderContainer = styled.div``

const LogoContainer = styled.div`
  margin-top: 5rem;
  align-self: center;
  padding-right: 3vw;
  width: auto;
`

const FooterContainer = styled.div`
  align-self: flex-end;
`

const ProgressContainer = styled.div`
  align-self: center;
  margin-bottom: 1rem;
`

function Persona() {
  const { currentUser } = useSelector((state) => state.userAuth)
  const { title, curPage, setCurPage, disablePrev, data, disableNext, canSubmit } =
    useRegisterContext()
  const [isConfirm, setIsConfirm] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('Information')
  const [dialogMessage, setDialogMessage] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const handleNextClick = () => setCurPage((prev) => prev + 1)

  const handlePrevClick = () => setCurPage((prev) => prev - 1)

  const handleSubmitClick = async (e) => {
    e.preventDefault()
    if (isSubmitting) return

    setIsSubmitting(true)
    try {
      const res = await axios.post('/api/maker/create', { userId: currentUser._id, ...data })

      dispatch(updateUser({ ...currentUser, makerId: res?.data?.makerId }))
      setIsSubmitting(false)
      navigate('/')
    } catch (error) {
      setIsSubmitting(false)
      setDialogTitle('Error')
      setDialogMessage('Server Error. Please try again later.')
      setShowDialog(true)
    }
  }

  return (
    <Container>
      <PersonaContainer>
        <LogoContainer>
          <Box
            component='img'
            sx={{
              marginTop: { xs: '1vh', sm: '1vh', md: '-5vh' }, // need to change according to diff size
              marginLeft: { xs: '4vw', sm: '4vw', md: '3.5vw' }, // this is to let the image fill the card in width and height
              marginBottom: { xs: '3vh', sm: '3vh', md: '3vh' },
              height: { xs: '6vh', sm: '4vh', md: '6vh', lg: '8vh' },
              width: { xs: '55vw', sm: '28vw', md: '27vw', lg: '27vw' },
              alignSelf: 'center',
              cursor: 'pointer',
            }}
            alt='MAKERLINKED Logo'
            src={MakerLinkedLogo}
            onClick={() => navigate('/')}
          />
        </LogoContainer>
        <StepProgressBar />
        <HeaderContainer>
          <Typography sx={{ fontSize: '1.2rem', paddingLeft: '1rem', fontWeight: 600 }}>
            {title[curPage]}
          </Typography>
        </HeaderContainer>
        <RegisterSelections />
        {/* <MakerInquiry /> */}

        {isSubmitting ? (
          <ProgressContainer>
            <CircularProgress sx={{ color: COLORS.lightBlue }} />
          </ProgressContainer>
        ) : (
          <FooterContainer>
            <Button
              variant='outlined'
              sx={{ textTransform: 'none', mr: '1rem', mb: '1rem' }}
              onClick={handlePrevClick}
              disabled={disablePrev}
            >
              Prev
            </Button>
            {disableNext && curPage === Object.keys(title).length - 1 ? (
              <Button
                variant='outlined'
                sx={{ textTransform: 'none', mr: '1rem', mb: '1rem' }}
                onClick={handleSubmitClick}
                disabled={!canSubmit}
              >
                Submit
              </Button>
            ) : (
              <Button
                variant='outlined'
                sx={{ textTransform: 'none', mr: '1rem', mb: '1rem' }}
                onClick={handleNextClick}
                disabled={disableNext}
              >
                Next
              </Button>
            )}
          </FooterContainer>
        )}
      </PersonaContainer>
      <PopupDialog
        dialogTitle={dialogTitle}
        handleClose={handleDialogClose}
        isDialogOpen={showDialog}
        actionsName='Close'
        maxWidth='45vw'
      >
        <Typography>{dialogMessage}</Typography>
        {/* <Typography>Test</Typography> */}
      </PopupDialog>
    </Container>
    // <Container>
    //   <PersonaContainer>
    //     <div
    //       style={{
    //         paddingTop: 100,
    //         alignSelf: 'center',
    //         paddingRight: '3vw',
    //         width: 'auto',
    //       }}
    //     >
    //       <Box
    //         component='img'
    //         sx={{
    //           marginTop: { xs: '1%', sm: '1vh', md: '-5vh' }, // need to change according to diff size
    //           marginLeft: { xs: '25%', sm: '16vw', md: '4vw' }, // this is to let the image fill the card in width and height
    //           marginBottom: { xs: '3vh', sm: '3vh', md: '3vh' },
    //           height: { xs: '6vh', sm: '4vh', md: '9vh' },
    //           width: { xs: '55vw', sm: '28vw', md: '27vw' },
    //         }}
    //         alt='MAKERLINKED Logo'
    //         src={MakerLinkedLogo}
    //       />
    //     </div>
    //     <Header>I am</Header>
    //     <OptionList>
    //       {idCats.map((idCat) => (
    //         <OptionItem key={idCat.id}>
    //           <Label>
    //             <Input
    //               type='radio'
    //               value={idCat.category}
    //               checked={selIdCat === idCat.category}
    //               onChange={handleIdCatChange}
    //             />
    //             {idCat.category}
    //           </Label>
    //         </OptionItem>
    //       ))}
    //     </OptionList>
    //     <Header>My industry</Header>
    //     <OptionList>
    //       {industryCats.map((industryCat) => (
    //         <OptionItem key={industryCat.id}>
    //           <Label>
    //             <Input
    //               type='radio'
    //               value={industryCat.category}
    //               checked={selIndustryCats.includes(industryCat.category)}
    //               onChange={() => {}}
    //               onClick={(e) => handleIndustryCatClick(e)}
    //             />
    //             {industryCat.category}
    //           </Label>
    //         </OptionItem>
    //       ))}
    //     </OptionList>
    //     <StyledButton
    //       enterColor={COLORS.buttonEnterBlue}
    //       leaveColor={COLORS.buttonLeaveBlue}
    //       path='profile'
    //       text='Complete'
    //     />
    //   </PersonaContainer>
    //   <PopupDialog
    //     dialogTitle='Information'
    //     handleClose={handleDialogClose}
    //     isDialogOpen={showDialog}
    //     actionsName='Close'
    //     maxWidth='40vw'
    //   >
    //     <Typography>You can only select up to 3 industrial areas in the list.</Typography>
    //   </PopupDialog>
    // </Container>
  )
}

export default Persona
