import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircleIcon from '@mui/icons-material/Circle'

function BulletPointDoc({ text, color = 'black' }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <CircleIcon
        sx={{
          mr: '0.5rem',
          fontSize: '0.6rem',
          pt: '0.4rem',
          color: { color },
        }}
      />
      <Typography sx={{ fontSize: '1.1rem' }}>{text}</Typography>
    </Box>
  )
}

export default BulletPointDoc
