/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import Typography from '@mui/material/Typography'

import { BulletPointDoc, PopupDialog } from '../components'
import { COLORS } from '../../assets'
import useRegisterContext from '../../hooks/useRegisterContext'

const industryList = [
  {
    id: 1,
    name: 'construction & building',
    alias: 'Construction & Building',
  },
  {
    id: 2,
    name: 'resources & environment',
    alias: 'Resources & Environment',
  },
  {
    id: 3,
    name: 'energy & transport',
    alias: 'Energy & Transport',
  },
  {
    id: 4,
    name: 'retail & logistics',
    alias: 'Retail & Logistics',
  },
  {
    id: 5,
    name: 'telecommunications',
    alias: 'Telecommunications',
  },
]

const businessModelList = [
  {
    id: 1,
    name: 'B2B',
    extra: '',
  },
  {
    id: 2,
    name: 'B2C',
    extra: '',
  },
  {
    id: 3,
    name: 'C2C',
    extra: '',
  },
  {
    id: 4,
    name: 'B2B2C',
    extra: 'Enterprise Only',
  },
  {
    id: 5,
    name: 'B2G',
    extra: 'Social Organisation Only',
  },
]

const OptionList = styled.ul`
  /* gap: 10px; */
  margin-top: -0.3rem;
  margin-right: 1.2rem;
  margin-left: -1rem;
  list-style-type: none;
`

const OptionItem = styled.li`
  /* gap: 10px; */
  padding-top: 1rem;
`

const Input = styled.input`
  border: none;
  background-color: transparent;
  outline: none;
  color: #aaaaaa;
`

const Label = styled.span`
  font-size: 1rem;
  display: inline-block;
`

const QuestionsContainer = styled.div`
  margin-left: 1rem;
`

function DetailsOne({ industryLimit }) {
  const { data, setData, handleChange } = useRegisterContext()

  const [businessModel, setBusinessModel] = useState(data?.businessModel)
  const [industries, setIndustries] = useState(data?.industry)
  const [showDialog, setShowDialog] = useState(false)

  // const [data, setData] = useState({
  //   category: '',
  //   type: '',
  //   industry: [],
  //   businessModel: [],
  //   professional: [],
  //   targetCustomer: [],
  //   coreTechnology: [],
  //   numOfPatents: 0,
  //   website: '',
  //   email: '',
  // })

  const handleBusinessModelClick = (e) => {
    const { value } = e.target

    if (businessModel.includes(value)) {
      const newData = businessModel.filter((cat) => cat !== value)
      setBusinessModel(newData)
      setData((prev) => ({ ...prev, businessModel: newData }))
      // handleChange(e.target.name, newData)
    } else {
      setBusinessModel((prev) => [...prev, value])
      setData((prev) => ({ ...prev, businessModel: [...data.businessModel, value] }))
      // handleChange(e.target.name, newData)
    }
  }

  const handleIndustryClick = (e) => {
    const { value } = e.target

    if (industries.includes(value)) {
      const newData = industries.filter((cat) => cat !== value)
      setIndustries(newData)
      setData((prev) => ({ ...prev, industry: newData }))
    } else if (industries.length === industryLimit) {
      setShowDialog(true)
    } else {
      setIndustries((prev) => [...prev, value])
      setData((prev) => ({ ...prev, industry: [...data.industry, value] }))
    }
  }

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const showBusinessModelQuestion = () =>
    (data.category === 'makerRequester' &&
      (data.makerType === 'enterprise' || data.makerType === 'social organisation')) ||
    (data.category === 'maker' && data.makerType === 'startup')

  return (
    <div>
      <QuestionsContainer>
        <BulletPointDoc text='Your Industry (required)' color={COLORS.textHighlightRed} />
      </QuestionsContainer>
      <OptionList>
        {industryList.map((item) => (
          <OptionItem key={item.id}>
            <Label>
              <Input
                type='checkbox'
                value={item.name}
                checked={industries.includes(item.name)}
                onChange={() => {}}
                onClick={handleIndustryClick}
              />
              {item.alias}
            </Label>
          </OptionItem>
        ))}
      </OptionList>
      {showBusinessModelQuestion() ? (
        <>
          <QuestionsContainer>
            <BulletPointDoc text='Your Business Model (required)' color={COLORS.textHighlightRed} />
          </QuestionsContainer>
          <OptionList>
            {businessModelList.map((model) => (
              <OptionItem key={model.id}>
                <Label>
                  <Input
                    type='checkbox'
                    value={model.name}
                    checked={businessModel.includes(model.name)}
                    onChange={() => {}}
                    onClick={handleBusinessModelClick}
                  />
                  {`${model.name}`}
                  {model.extra !== '' ? ` (${model.extra})` : ''}
                </Label>
              </OptionItem>
            ))}
          </OptionList>
        </>
      ) : (
        <></>
      )}

      <PopupDialog
        dialogTitle='Information'
        handleClose={handleDialogClose}
        isDialogOpen={showDialog}
        actionsName='Close'
        maxWidth='40vw'
      >
        <Typography>You can only select up to 3 industrial areas in the list.</Typography>
      </PopupDialog>
    </div>
  )
}

export default DetailsOne
