/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import styled from 'styled-components'

import { BulletPointDoc } from '../components'
import { COLORS } from '../../assets'
import useRegisterContext from '../../hooks/useRegisterContext'

const targetCustomerList = [
  {
    id: 1,
    name: 'consumer',
    alias: 'Consumer',
  },
  {
    id: 2,
    name: 'miner',
    alias: 'Miner',
  },
  {
    id: 3,
    name: 'producer',
    alias: 'Producer',
  },
  {
    id: 4,
    name: 'processor',
    alias: 'Processor (Subcontractor)',
  },
  {
    id: 5,
    name: 'distributor',
    alias: 'Distributor',
  },
  {
    id: 6,
    name: 'intermediary business',
    alias: 'Intermediary Business',
  },
]

const prefessionals = [
  {
    id: 1,
    occupation: 'Architecture',
  },
  {
    id: 2,
    occupation: 'Graphic Designer',
  },
  {
    id: 3,
    occupation: 'UI Designer',
  },
  {
    id: 4,
    occupation: 'Interior Designer',
  },
  {
    id: 5,
    occupation: 'Industrial Designer',
  },
  {
    id: 6,
    occupation: 'Web Designer',
  },
  {
    id: 7,
    occupation: 'Software Designer',
  },
  {
    id: 8,
    occupation: 'Branding',
  },
]

const OptionList = styled.ul`
  /* gap: 10px; */
  margin-top: -0.3rem;
  margin-right: 1.2rem;
  margin-left: -1rem;
  list-style-type: none;
`

const OptionItem = styled.li`
  /* gap: 10px; */
  padding-top: 1rem;
`

const Input = styled.input`
  border: none;
  background-color: transparent;
  outline: none;
  color: #aaaaaa;
`

const Label = styled.span`
  font-size: 1rem;
  display: inline-block;
`

const QuestionsContainer = styled.div`
  margin-left: 1rem;
`

function DetailsTwo() {
  const { data, setData } = useRegisterContext()

  const [targetCustomers, setTargetCustomers] = useState(data?.targetCustomer)
  const [professional, setProfessional] = useState(data?.professional)
  const [showDialog, setShowDialog] = useState(false)

  // const [data, setData] = useState({
  //   category: '',
  //   type: '',
  //   industry: [],
  //   businessModel: [],
  //   professional: [],
  //   targetCustomer: [],
  //   coreTechnology: [],
  //   numOfPatents: 0,
  //   website: '',
  //   email: '',
  // })

  const handleProfessionalChange = (e) => {
    const { value } = e.target

    if (professional.includes(value)) {
      const newProfessionalData = professional.filter((cat) => cat !== value)
      setProfessional(newProfessionalData)
      setData((prev) => ({ ...prev, professional: newProfessionalData }))
    } else {
      setProfessional((prev) => [...prev, value])
      setData((prev) => ({ ...prev, professional: [...data.professional, value] }))
    }
  }

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const handleTargetCustomerClick = (e) => {
    const { value } = e.target

    if (targetCustomers.includes(value)) {
      const newCustomerData = targetCustomers.filter((cat) => cat !== value)
      setTargetCustomers(newCustomerData)
      setData((prev) => ({ ...prev, targetCustomer: newCustomerData }))
    } else {
      setTargetCustomers((prev) => [...prev, value])
      setData((prev) => ({ ...prev, targetCustomer: [...data.targetCustomer, value] }))
    }
  }

  const showProfessionalQuestion = () =>
    (data.category === 'makerRequester' && data.makerType === 'others') ||
    (data.category === 'maker' && (data.makerType === 'designer' || data.makerType === 'architect'))

  return (
    <div>
      <QuestionsContainer>
        <BulletPointDoc text='Your Target Customer (required)' color={COLORS.textHighlightRed} />
      </QuestionsContainer>
      <OptionList>
        {targetCustomerList.map((item) => (
          <OptionItem key={item.id}>
            <Label>
              <Input
                type='checkbox'
                value={item.name}
                checked={targetCustomers.includes(item.name)}
                onChange={() => {}}
                onClick={handleTargetCustomerClick}
              />
              {item.alias}
            </Label>
          </OptionItem>
        ))}
      </OptionList>
      {showProfessionalQuestion() ? (
        <>
          <QuestionsContainer>
            <BulletPointDoc text='Your Professional (required)' color={COLORS.textHighlightRed} />
          </QuestionsContainer>
          <OptionList>
            {prefessionals.map((item) => (
              <OptionItem key={item.id}>
                <Label>
                  <Input
                    type='checkbox'
                    value={item.occupation}
                    checked={professional.includes(item.occupation)}
                    onChange={handleProfessionalChange}
                  />
                  {item.occupation}
                </Label>
              </OptionItem>
            ))}
          </OptionList>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default DetailsTwo
