/* eslint-disable max-len */
/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React from 'react'
import styled from 'styled-components'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'

import Button from '@mui/material/Button'

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 5vh);
  color: #fff;
  /* font-family: var(--font-family); */
  position: absolute;
  transform: translate(-50%, -50%);

  // for iphone SE
  @media only screen and (min-width: 320px) {
    flex-direction: column;
    top: 55%;
    left: 55%;
  }

  // for iphone 12 PEO > 390
  /* @media only screen and (min-width: 390px) {
    flex-direction: column;
    top: 50%;
    left: 55%;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    flex-direction: column;
    top: 50%;
    left: 55%;
  } */

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    flex-direction: row;
    /* top: 50%; */
    left: 55%;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    flex-direction: row;
    /* top: 50%; */
    left: 55%;
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    top: 50%;
    left: 60%;
  }
`

const InnerContainer = styled.div`
  justify-content: center;
  padding-top: 20vh;

  @media only screen and (min-width: 320px) {
    flex-direction: column;
    padding-top: 5vh;
  }

  @media only screen and (min-width: 728px) {
    flex-direction: row;
    padding-top: 20vh;
  }
`

const LSubContainer = styled.div`
  margin-right: 5%;
  @media only screen and (min-width: 375) {
    margin-right: 10%;
  }

  @media only screen and (min-width: 768) {
    margin-right: 10%;
  }

  @media only screen and (min-width: 1024) {
    margin-right: 10%;
  }
`

const RSubContainer = styled.div``

function InvestAssist() {
  const naviagte = useNavigate()
  const location = useLocation()
  const path = location?.pathname ?? ''

  const handleChineseInnoClick = () => {
    naviagte('exchange-flow')
  }

  return (
    <Container>
      <InnerContainer
        style={{
          display: path?.includes('exchange-flow') ? 'none' : 'flex',
        }}
      >
        <LSubContainer>
          <Card
            sx={{
              width: {
                xs: '60vw', // for phone
                sm: '35vw', // for ipad mini
                md: '20vw', // for ipad air
                lg: '30vw',
              },
              height: {
                sm: '20vh', // ipad air and mini
                lg: '30vh',
              },
              marginBottom: '8%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CardContent>
              <Button
                variant='outlined'
                style={{ textTransform: 'none' }}
                onClick={() => handleChineseInnoClick()}
              >
                Chinese Innovation Map
              </Button>
              <Grid>tags</Grid>
              <Grid>tags</Grid>
            </CardContent>
          </Card>
          <Card
            sx={{
              width: {
                xs: '60vw', // for phone
                sm: '35vw', // for ipad mini
                md: '20vw', // for ipad air
                lg: '30vw',
              },
              height: {
                sm: '20vh', // ipad air and mini
                lg: '30vh',
              },
              marginBottom: '8%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CardContent>
              <Button variant='outlined' style={{ textTransform: 'none' }}>
                Contract Helper (Multi-Country)
              </Button>
              <Grid>tags</Grid>
              <Grid>tags</Grid>
            </CardContent>
          </Card>
        </LSubContainer>
        <RSubContainer>
          <Card
            sx={{
              width: {
                xs: '60vw', // for phone
                sm: '35vw', // for ipad mini
                md: '20vw', // for ipad air
                lg: '30vw',
              },
              height: {
                sm: '20vh', // ipad air and mini
                lg: '30vh',
              },
              marginBottom: '8%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CardContent>
              <Button variant='outlined' style={{ textTransform: 'none' }}>
                Legal Consulting & Market Access
              </Button>
              <Grid>tags</Grid>
              <Grid>tags</Grid>
            </CardContent>
          </Card>
          <Card
            sx={{
              width: {
                xs: '60vw', // for phone
                sm: '35vw', // for ipad mini
                md: '20vw', // for ipad air
                lg: '30vw',
              },
              height: {
                sm: '20vh', // ipad air and mini
                lg: '30vh',
              },
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CardContent>
              <Button variant='outlined' style={{ textTransform: 'none' }}>
                Event
              </Button>
              <Grid>tags</Grid>
              <Grid>tags</Grid>
            </CardContent>
          </Card>
        </RSubContainer>
      </InnerContainer>

      <Outlet />
    </Container>
  )
}

export default InvestAssist
