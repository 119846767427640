/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

import MenuItems from './MenuItems'

const listItems = [
  {
    id: 0,
    name: 'Sector',
    isPopup: true,
    subList: [
      {
        id: 0,
        isCheckBox: true,
        name: 'Construction & Building',
      },
      {
        id: 1,
        isCheckBox: true,
        name: 'Resource & Environment',
      },
      {
        id: 2,
        isCheckBox: true,
        name: 'Energy & Transcript',
      },
      {
        id: 3,
        isCheckBox: true,
        name: 'Retail & Logistics',
      },
      {
        id: 4,
        isCheckBox: true,
        name: 'Telecommunications',
      },
    ],
  },
  {
    id: 1,
    name: 'Target Customer',
    isPopup: true,
    subList: [
      {
        id: 0,
        isCheckBox: true,
        name: 'Communication Services',
      },
      {
        id: 1,
        isCheckBox: true,
        name: 'Government & City',
      },
      {
        id: 2,
        isCheckBox: true,
        name: 'Healthcare & Life Sciences',
      },
      {
        id: 3,
        isCheckBox: true,
        name: 'Travel & Tourism',
      },
    ],
  },
  {
    id: 2,
    name: 'Core Technology',
    isPopup: true,
    subList: [
      {
        is: 0,
        isCheckBox: true,
        name: 'Biologicals',
      },
      {
        id: 1,
        isCheckBox: true,
        name: 'Government & City',
      },
      {
        id: 2,
        isCheckBox: true,
        name: 'Healthcare & Life Sciences',
      },
      {
        id: 3,
        isCheckBox: true,
        name: 'Travel & Tourism',
      },
    ],
  },
  {
    id: 3,
    name: 'Funding Stage',
    isSubList: true,
    subList: [
      {
        id: 0,
        isCheckBox: true,
        name: 'Pre-Funding',
      },
      {
        id: 1,
        isCheckBox: true,
        name: 'Pre-Seed',
      },
      {
        id: 2,
        isCheckBox: true,
        name: 'Seed',
      },
      {
        id: 3,
        isCheckBox: true,
        name: 'A',
      },
      {
        id: 4,
        isCheckBox: true,
        name: 'B',
      },
      {
        id: 5,
        isCheckBox: true,
        name: 'C',
      },
      {
        id: 6,
        isCheckBox: true,
        name: 'D',
      },
      {
        id: 7,
        isCheckBox: true,
        name: 'E',
      },
      {
        id: 8,
        isCheckBox: true,
        name: 'F',
      },
      {
        id: 9,
        isCheckBox: true,
        name: 'G',
      },
      {
        id: 10,
        isCheckBox: true,
        name: 'Mature',
      },
      {
        id: 11,
        isCheckBox: true,
        name: 'Public',
      },
      {
        id: 12,
        isCheckBox: true,
        name: 'Accquired',
      },
    ],
  },
  {
    id: 4,
    name: 'Business Models',
    isSubList: true,
    subList: [
      {
        id: 0,
        isCheckBox: true,
        name: 'B2B',
      },
      {
        id: 1,
        isCheckBox: true,
        name: 'B2C',
      },
      {
        id: 2,
        isCheckBox: true,
        name: 'B2B2C',
      },
      {
        id: 3,
        isCheckBox: true,
        name: ' B2G',
      },
    ],
  },
  {
    id: 5,
    name: 'Product Stage',
    isSubList: true,
  },
  {
    id: 6,
    name: 'Employees',
    isSubList: true,
  },
]

const listItems2 = [
  {
    id: 0,
    name: 'Search by tag',
  },
  {
    id: 1,
    name: 'Founded',
  },
  {
    id: 2,
    name: 'Total Raised',
  },
  {
    id: 3,
    name: 'Location',
  },
]

const drawerWidth = 240

// function SearchMenu(upListItems, bottomListItems) {
function SearchMenu(props) {
  const { upListItems, bottomListItems } = props
  // 8 menu items in "startup" page has sublist
  // const [isOpen, setIsOpen] = useState([false, false, false, false, false, false, false, false])

  // const handleExpListItemClick = (id) => {
  //   setIsOpen((prev) => prev.map((item, idx) => (idx === id ? !item : item)))
  // }

  const [menuOpen, setMenuOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <div>
      <Box sx={{ display: 'flex' }} borderRight={10} borderColor='red'>
        {/* <CssBaseline  /> */}
        {/* <AppBar
          position='absolute'
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            marginRight: '-235px',
          }}
        >
          <Toolbar>
            <Typography variant='h6' noWrap component='div'>
              Permanent drawer
            </Typography>
          </Toolbar>
        </AppBar> */}
        <Drawer
          sx={{
            marginLeft: '-1rem',
            // this is the width for the search content start width
            width: { sm: drawerWidth - 80, md: drawerWidth },
            // while win size is xs or sm
            display: { xs: 'none', sm: 'block', md: 'block' },
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              // this is the width for menu
              width: { sm: drawerWidth - 80, md: drawerWidth },
              boxSizing: 'border-box',
              // make the drawer anchor to specific pos, not to slide with scroll bar
              position: 'absolute', // imp
              // transition: 'none !important',
            },
          }}
          open={menuOpen}
          onClose={handleDrawerToggle}
          variant='permanent'
          anchor=''
        >
          <Toolbar sx={{ display: 'flex' }}>
            <Typography
              variant='h4'
              noWrap
              component='div'
              sx={{ fontWeight: 'bold', fontSize: { sm: '1rem', md: '1.5rem' } }}
            >
              Filter
            </Typography>
            <Link
              href='/'
              underline='none'
              sx={{
                marginLeft: { sm: '2rem', md: '4.1rem' },
                fontSize: { sm: '0.6rem', md: '1rem' },
              }}
            >
              clear filter
            </Link>
          </Toolbar>
          <Divider />
          <List>
            {/* {listItems.map((listItem, index) => (
              <ListItem key={listItem.name} disablePadding>
                <ListItemButton onClick={() => handleExpListItemClick(listItem.id)}>
                  <ListItemText primary={listItem.name} />
                  {isOpen[listItem.id] ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </ListItemButton>
              </ListItem>
            ))} */}
            <MenuItems listItems={upListItems} />
          </List>

          <Divider />
          <List>
            <MenuItems listItems={bottomListItems} />
          </List>
        </Drawer>
      </Box>
    </div>
  )
}

export default SearchMenu
