/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable jsx-quotes */
import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import { visuallyHidden } from '@mui/utils'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import AssessmentIcon from '@mui/icons-material/Assessment'

import { COLORS } from '../../../assets'
import ProjectProgress from './ProjectProgress'

const createData = (id, projectName, info, reqComp, dueDate, totalSales, projMgr, status) => ({
  id,
  projectName,
  info,
  reqComp,
  dueDate,
  totalSales,
  projMgr,
  status,
})

const projStatus = ['Due_diligence', 'Estimated', 'Completed', 'Estimating']

const rowsData = [
  createData(
    '1',
    'Wind turbine construction optimazation',
    '-',
    'XXX',
    'TBD',
    'N/A',
    'XXX',
    'Estimated',
  ),
  createData(
    '2',
    'The external curtain wall and steel structure to work together for BIPV',
    '-',
    'XXX',
    'TBD',
    'N/A',
    'XXX',
    'Estimating',
  ),
]
const headCells = [
  {
    id: 'projectName',
    numeric: false,
    disablePadding: true,
    label: 'Project',
  },
  {
    id: 'info',
    numeric: false,
    disablePadding: false,
    label: 'Info',
  },
  {
    id: 'reqComp',
    numeric: false,
    disablePadding: false,
    label: 'Request Company',
  },
  {
    id: 'dueDate',
    numeric: false,
    disablePadding: false,
    label: 'Due Date',
  },
  {
    id: 'totalSales',
    numeric: true,
    disablePadding: false,
    label: 'Total Sales',
  },
  {
    id: 'projMgr',
    numeric: false,
    disablePadding: false,
    label: 'Project Manager',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'grey',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? '1%' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

function ProjectsTable({ isInProgress }) {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [isProgressOpen, setIsProgressOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const handleProgressClose = () => {
    setIsProgressOpen(false)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRowClick = (id) => {
    // TODO: in real env, should pass in the project id of the list, then open the according details of the project
    // setIsProgressOpen(true)
    // ['', 'am-maker', 'makerlinked-acct', 'ongoing-projects']
    const urlPathInfoArr = location.pathname.split('/')
    navigate(`/${urlPathInfoArr[1]}/${urlPathInfoArr[2]}/project-board/${id}`)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsData.length) : 0

  const visibleRows = React.useMemo(
    () =>
      stableSort(rowsData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  )
  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          <TableBody>
            {visibleRows.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`
              return (
                <TableRow
                  hover
                  role='checkbox'
                  tabIndex={-1}
                  key={row.projectName}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleRowClick(row.id)}
                >
                  <TableCell
                    component='th'
                    id={labelId}
                    scope='row'
                    padding='1%'
                    sx={{ width: '8vw' }}
                  >
                    {row.projectName}
                  </TableCell>
                  {/* name, info, reqComp, project, dueDate, totalSales, estimator, status */}
                  <TableCell align='left' sx={{ width: '1vw' }}>
                    <AssessmentIcon />
                  </TableCell>
                  <TableCell align='left'>{row.reqComp}</TableCell>
                  <TableCell align='left'>{row.dueDate}</TableCell>
                  <TableCell align='center'>{row.totalSales}</TableCell>
                  <TableCell align='left'>{row.projMgr}</TableCell>
                  <TableCell align='left'>
                    <Autocomplete
                      value={row.status}
                      disablePortal
                      disableClearable
                      id='combo-box-demo'
                      options={projStatus}
                      size='small'
                      sx={{
                        '& .MuiInputBase-root': {
                          borderRadius: '15px !important',
                          height: '5vh',
                          backgroundColor: COLORS.yellowForDark,
                          width: '11vw',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // must use ...params.inputProp, otherwise it will report error of lost focus
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={rowsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* ProjectProgress({ handleClose, isProgressOpen  */}
      <ProjectProgress
        handleClose={handleProgressClose}
        isProgressOpen={isProgressOpen}
        isProgressTab={isInProgress}
      />
    </Paper>
  )
}

export default ProjectsTable
