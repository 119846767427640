/* eslint-disable import/prefer-default-export */
import { deepPurple, deepOrange, grey } from '@mui/material/colors'

const COLORS = {
  meetingProfileCardAvatarColor1: deepPurple,
  meetingProfileCardAvatarColor2: deepOrange,

  whiteHoverBgColor: '#429aed',
  closeButtonGrey: grey[500],

  yellowForDark: '#ffd400',
  lightOragne: '#F7750A',

  lightBlue: '#378fe8',
  darkBlue: '#185ea0',

  inactiveGrey: '#808080',
  stepCompleteGreen: '#16c784',

  textHighlightRed: '#FF0000',

  projMgtGridBgGrey: '#C0C0C0',
  projMgtGridHoverGrey: '#999999',

  textBlack: '#181818',

  buttonEnterOrange: '#FE481F',
  buttonLeaveOrange: '#F7750A',
  buttonEnterBlue: '#185ea0',
  buttonLeaveBlue: '#1876D1',
}

export { COLORS }
