/* eslint-disable jsx-quotes */
/* eslint-disable object-curly-newline */
import React from 'react'
import styled from 'styled-components'

import { BulletPointDoc } from '../components'
import { COLORS } from '../../assets'

const QuestionsContainer = styled.div`
  margin-left: 1rem;
`

const OptionList = styled.ul`
  /* gap: 10px; */
  margin-top: -0.3rem;
  margin-right: 1.2rem;
  margin-left: -1rem;
  list-style-type: none;
`

const OptionItem = styled.li`
  /* gap: 10px; */
  padding-top: 1rem;
`

const Input = styled.input`
  border: none;
  background-color: transparent;
  outline: none;
  color: #aaaaaa;
`

const Label = styled.span`
  font-size: 1rem;
  display: inline-block;
`

function SingleOptionList(props) {
  const { dataList, title, name, checker, setCheckerChange, handleCxtDataChange } = props

  const handleSelectChange = (e) => {
    const { name: key, value } = e.target
    setCheckerChange(value)
    handleCxtDataChange(key, value)
  }

  return (
    <>
      <QuestionsContainer>
        <BulletPointDoc text={title} color={COLORS.textHighlightRed} />
      </QuestionsContainer>
      <OptionList>
        {dataList.map((item) => (
          <OptionItem key={item.id}>
            <Label>
              <Input
                type='radio'
                name={name}
                value={item[name]}
                checked={checker === item[name]}
                onChange={handleSelectChange}
              />
              {item.alias}
            </Label>
          </OptionItem>
        ))}
      </OptionList>
    </>
  )
}

export default SingleOptionList
