/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'

function createData(
  name,
  description,
  founded,
  businessModel,
  employees,
  fundingStage,
  totalRaised,
  tags,
) {
  return {
    name,
    description,
    founded,
    businessModel,
    employees,
    fundingStage,
    totalRaised,
    tags,
  }
}

const data = [
  createData(
    'Belgium',
    'Audio, Video, and Computer Signal Processing Solutions',
    1996,
    'B2B',
    '500+',
    'Public',
    '$144.3M',
    'telecom, signal-processing',
  ),
  createData(
    'China',
    'Audio, Video, and Computer Signal Processing Solutions',
    1996,
    'B2B',
    '500+',
    'Public',
    '$144.3M',
    'telecom, signal-processing',
  ),
  createData(
    'Belgium',
    'Audio, Video, and Computer Signal Processing Solutions',
    1996,
    'B2B',
    '500+',
    'Public',
    '$144.3M',
    'telecom, signal-processing',
  ),
  createData(
    'China',
    'Audio, Video, and Computer Signal Processing Solutions',
    1996,
    'B2B',
    '500+',
    'Public',
    '$144.3M',
    'telecom, signal-processing',
  ),
  createData(
    'Belgium',
    'Audio, Video, and Computer Signal Processing Solutions',
    1996,
    'B2B',
    '500+',
    'Public',
    '$144.3M',
    'telecom, signal-processing',
  ),
  createData(
    'China',
    'Audio, Video, and Computer Signal Processing Solutions',
    1996,
    'B2B',
    '500+',
    'Public',
    '$144.3M',
    'telecom, signal-processing',
  ),
  createData(
    'Belgium',
    'Audio, Video, and Computer Signal Processing Solutions',
    1996,
    'B2B',
    '500+',
    'Public',
    '$144.3M',
    'telecom, signal-processing',
  ),
  createData(
    'China',
    'Audio, Video, and Computer Signal Processing Solutions',
    1996,
    'B2B',
    '500+',
    'Public',
    '$144.3M',
    'telecom, signal-processing',
  ),
]

// name,
// description,
// founded,
// businessModel,
// employees,
// fundingStage,
// totalRaised,
// tags,

function SearchCards() {
  return (
    <div>
      {data.map((element) => (
        <Card
          sx={{
            width: { xs: 310, sm: 450 },
            marginLeft: { xs: '-3rem', sm: '1rem' },
            marginBottom: '2rem',
            display: { xs: 'flex', sm: 'flex', md: 'none' },
            flexDirection: 'column',
          }}
        >
          <CardContent>
            <Grid>{element.name}</Grid>
            <Grid>{element.description}</Grid>
            <Grid>
              Founded:&nbsp;
              {element.founded}
            </Grid>
            <Grid>
              Business Model:&nbsp;
              {element.businessModel}
            </Grid>
            <Grid>
              Employees:&nbsp;
              {element.employees}
            </Grid>
          </CardContent>
        </Card>
      ))}
    </div>
  )
}

export default SearchCards
