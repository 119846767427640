/* eslint-disable no-unused-vars */
/* eslint-disable jsx-quotes */
import React, { useState } from 'react'
import styled from 'styled-components'
import { styled as style } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { Grid } from '@mui/material'
import { AllMeetings } from '../../features/my_makerlinked/my_meetings'

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 20vh);
  color: #fff;
  /* font-family: var(--font-family); */
  position: absolute;
  transform: translate(-50%, -50%);
  /* NOTE: use flex dir column will cause tabs moves due to diff content, do not know why */
  /* flex-direction: column; */

  // for iphone SE
  @media only screen and (min-width: 320px) {
    /* flex-direction: column; */
    top: 25%;
    left: 55%;
  }

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    /* flex-direction: column; */
    top: 53%;
    left: 58%;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    /* flex-direction: column; */
    top: 53%;
    left: 58%;
  }

  @media only screen and (min-width: 1024px) {
    /* flex-direction: column; */
    top: 53%;
    left: 58%;
  }
`

const TitleAndSearchDiv = style('div')(({ theme }) => ({
  // marginTop: '-0.5rem',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '14vw',
  alignItems: 'center',
  marginRight: '2rem',
  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const Title = style('h1')({
  // marginRight: '26rem',
  fontSize: '36px',
})

const TabsDiv = style('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

function MyMeetings() {
  const [value, setValue] = useState('one')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Container>
      <TitleAndSearchDiv>
        <Title>1:1 Meetings</Title>
        <Grid>
          <Button sx={{ textTransform: 'none', width: '10vw' }} variant='contained'>
            Camera test
          </Button>
          <Button sx={{ textTransform: 'none', width: '10vw', mt: '5%' }} variant='contained'>
            Microphone test
          </Button>
        </Grid>
      </TitleAndSearchDiv>
      {/* <SearchCards /> */}
      <TabsDiv>
        <Box
          sx={{
            p: 3,
            width: '60vw',
            borderColor: 'divider',
            color: 'white',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            textColor='inherit'
            indicatorColor='secondary'
            aria-label='secondary tabs'
            tabItemContainerStyle={{ position: 'fixed', bottom: '0', width: '100%' }}
          >
            <Tab sx={{ fontSize: '1rem', textTransform: 'none' }} value='one' label='All' />
            <Tab sx={{ fontSize: '1rem', textTransform: 'none' }} value='two' label='Accepted' />
            <Tab sx={{ fontSize: '1rem', textTransform: 'none' }} value='three' label='Pending' />
            <Tab
              sx={{ fontSize: '1rem', textTransform: 'none', mr: 'auto' }}
              value='four'
              label='Canceled'
            />
            <Tab
              sx={{ fontSize: '1rem', textTransform: 'none' }}
              value='five'
              label='Availability'
            />
          </Tabs>
          {value === 'one' && (
            <Box sx={{ p: 3 }}>
              <AllMeetings />
            </Box>
          )}

          {value === 'two' && (
            <Box sx={{ p: 3 }}>
              <Typography variant='h5'>Tab 2 Content</Typography>
              <Typography variant='p'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
              </Typography>
            </Box>
          )}

          {value === 'three' && (
            <Box sx={{ p: 3 }}>
              <Typography variant='h5'>Tab 2 Content</Typography>
              <Typography variant='p'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
              </Typography>
            </Box>
          )}
        </Box>
      </TabsDiv>
    </Container>
  )
}

export default MyMeetings
