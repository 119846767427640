/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { ScrollView } from 'react-native'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'
import PasswordChecklist from 'react-password-checklist'
import { useSelector, useDispatch } from 'react-redux'
import { isEmail } from 'validator'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import { MakerLinkedLogo } from '../../static/images'
import { Button, PopupDialog } from '../../features/components'
import { COLORS, CONSTANTS } from '../../assets'
import { authFailure, authStart, authComplete } from '../../services/redux/user-slice'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 10vh);
  color: #fff;
  font-family: var(--font-family);
  position: absolute;
  top: 50%;
  /* left: 50%; */
  transform: translate(-50%, -50%);

  // for iphone SE
  @media only screen and (min-width: 320px) {
    left: 55%;
  }

  // for iphone 12 PEO > 390
  @media only screen and (min-width: 390px) {
    left: 55%;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    left: 55%;
  }

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    left: 55%;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    left: 55%;
  }

  @media only screen and (min-width: 1024px) {
    left: 50%;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 2xp solid; */
  padding: 1rem;
  gap: 1.3rem;
`

const Title = styled.h1`
  font-size: 1.5rem;
  text-align: center;
`

const WarningSpan = styled.span`
  color: #ed7474;
  font-weight: bold;
`

const Input = styled.input`
  width: 100%;
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  border: 1px solid;
  /* height: 3rem; */
`

const StyledButton = styled(Button)`
  width: 20rem;
  font-size: 1.2rem;
`

const SuccessInfoDiv = styled.div`
  width: auto;
  margin-top: 5vh;
`

const RedirContainer = styled.div`
  display: flex;
  flex-direction: row;

  // for iphone SE
  @media only screen and (min-width: 320px) {
    flex-direction: column;
  }

  // for iphone 12 PEO > 390
  @media only screen and (min-width: 390px) {
    flex-direction: column;
  }

  // for Galaxy S20 Ultra > 412
  @media only screen and (min-width: 412px) {
    flex-direction: column;
  }

  // for tablet, like mini
  @media only screen and (min-width: 728px) {
    flex-direction: column;
  }

  // for ipad air 820
  @media only screen and (min-width: 820px) {
    flex-direction: column;
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

const Label = styled.label`
  color: #fff;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 1.5em;
`

function ResetPwd() {
  const { loading } = useSelector((state) => state.userAuth)
  const [showDialog, setShowDialog] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [isPwdRest, setIsPwdRest] = useState(false)
  const [isPwdValid, setIsPwdValid] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('Information')
  const [dialogMessage, setDialogMessage] = useState('')
  const [passwordInput, setPasswordInput] = useState({
    password: '',
    confirmPassword: '',
  })

  const [queryParameters] = useSearchParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const validatePassword = () => {
    if (isPwdValid === false) {
      setDialogTitle('Error')
      setDialogMessage('Your password is not valid, please check again')
      setShowDialog(true)
      return false
    }

    return true
  }

  const handleResetPwdClick = async (e) => {
    e.preventDefault()
    if (!passwordInput.password || !passwordInput.confirmPassword || !validatePassword()) {
      return
    }
    dispatch(authStart())
    try {
      // throw new Error(401, 'test')
      await axios.post('/api/auth/reset-pwd', {
        userId: queryParameters.get('id'),
        token: queryParameters.get('token'),
        password: passwordInput.password,
      })
      // await axios.post('api/auth/request-reset-pwd', { email })
      // setIsEmailSent(true)
      dispatch(authComplete())
      setIsPwdRest(true)
      setTimeout(() => navigate('/'), 5000)
    } catch (error) {
      const { response } = error
      const msg =
        response?.status === 401
          ? 'Your password reset link has expired, please apply again'
          : 'Server error. Please try again later.'
      setDialogTitle('Error')
      setDialogMessage(msg)
      setShowDialog(true)
      dispatch(authFailure())
    }
  }

  const onPasswordChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target

    setPasswordInput((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <Container>
      <ScrollView style={{ flex: 1 }}>
        <Box
          component='img'
          sx={{
            marginTop: {
              xs: '1%',
              sm: '1%',
              md: '1%',
              lg: '1%',
            }, // need to change according to diff size
            height: {
              xs: '5vh',
              sm: '4vh',
              md: '5vh',
              lg: '8vh',
            },
            width: {
              xs: '38vw',
              sm: '27vw',
              md: '27vw',
              lg: '27vw',
            },
            alignSelf: 'center',
          }}
          alt='MAKERLINKED Logo'
          src={MakerLinkedLogo}
        />
        {isPwdRest ? (
          <SuccessInfoDiv>
            <Typography>You have successfully reset your password at MAKERLinked.</Typography>
            <Typography>
              You will be redirect to the home page in 5 seconds, or you can manully click the
              following link and go
            </Typography>
            <RedirContainer>
              <Typography> to the home page now</Typography>
              <Link
                to='/'
                style={{ color: COLORS.buttonLeaveOrange, marginLeft: '0.5rem', marginTop: 2 }}
              >
                Home
              </Link>
            </RedirContainer>
          </SuccessInfoDiv>
        ) : (
          <Wrapper>
            <Title>Reset Your Password</Title>
            <Input
              type={showPassword ? 'text' : 'password'}
              name='password'
              placeholder='password (required)'
              onChange={onPasswordChange}
            />
            <Input
              type={showPassword ? 'text' : 'password'}
              name='confirmPassword'
              placeholder='please enter password again'
              onChange={onPasswordChange}
            />
            <PasswordChecklist
              style={{ fontSize: '12px', fontFamily: 'var(--font-family)' }}
              rules={['minLength', 'number', 'specialChar', 'capital', 'match']}
              minLength={8}
              maxLength={18}
              value={passwordInput.password}
              valueAgain={passwordInput.confirmPassword}
              messages={{
                minLength: 'Password should have 8 to 18 characters',
                number: 'Password should have at least a number',
                capital: 'Password should have at least a capital letter',
                specialChar: 'Password should have at least a special character',
                match: 'passwords must match',
              }}
              onChange={(isValid) => {
                setIsPwdValid(isValid)
              }}
            />
            <Label>
              <Input
                type='checkbox'
                value='show-pwd'
                checked={showPassword}
                onChange={toggleShowPassword}
              />
              Show Password
            </Label>
            {loading ? (
              <CircularProgress sx={{ color: COLORS.buttonLeaveOrange }} />
            ) : (
              <StyledButton
                enterColor={COLORS.buttonEnterOrange}
                leaveColor={COLORS.buttonLeaveOrange}
                path=''
                text='Rest Password'
                handleClick={handleResetPwdClick}
              />
            )}
          </Wrapper>
        )}
        <PopupDialog
          dialogTitle={dialogTitle}
          handleClose={handleDialogClose}
          isDialogOpen={showDialog}
          actionsName='Close'
          maxWidth='30vw'
        >
          <Typography>{dialogMessage}</Typography>
          {/* <Typography>Test</Typography> */}
        </PopupDialog>
      </ScrollView>
    </Container>
  )
}

export default ResetPwd
